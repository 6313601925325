import { CalendarDatePickerConfig, PeriodResolution } from '../types'
import { Maybe } from '@fintastic/shared/util/types'
import { RESOLUTIONS_ORDERED_MAP } from './const'
import { last, sortBy } from 'lodash'

export const getMaxResolutionFromCalendar = (
  calendarConfig: CalendarDatePickerConfig,
): Maybe<PeriodResolution> => {
  const maxBaseDimensionResolution = last(
    sortBy(
      calendarConfig,
      ({ resolution }) => RESOLUTIONS_ORDERED_MAP[resolution],
    ),
  )

  if (maxBaseDimensionResolution?.dimension_id) {
    return maxBaseDimensionResolution.resolution
  }

  return null
}

/**
 * Finds if A is higher or equal to B
 */
export const isResolutionHigherOrEqual = (
  a: PeriodResolution,
  b: PeriodResolution,
): boolean => {
  const aWeight = RESOLUTIONS_ORDERED_MAP[a]
  const bWeight = RESOLUTIONS_ORDERED_MAP[b]

  return aWeight >= bWeight
}

/**
 * At the moment API does not support aggregated editing for weekly metrics
 */
export const resolutionSupportsAggregatedEditing = (
  resolution: PeriodResolution,
): boolean => isResolutionHigherOrEqual(resolution, 'month')
