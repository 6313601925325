import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { WidgetSelectList } from './WidgetSelectList'
import { WidgetSelectMetric } from './WidgetSelectMetric'
import { WidgetSelectReports } from './WidgetSelectReports'
import { WidgetSelectRenderer } from './WidgetSelectRenderer'

export type ListOrMetricOrReportSelectProps = {
  disabled?: boolean
  versionId?: Maybe<string>
  mode?: 'list' | 'metric' | 'report'
  value: Maybe<string>
  onSelect: (listId: Maybe<string>, listName: Maybe<string>) => void
  onLoadingUpdate?: (loading: boolean) => void
}

const isVersionAwareMode = (mode?: string) =>
  mode === 'list' || mode === 'metric'

export const WidgetSelect: React.FC<ListOrMetricOrReportSelectProps> = (
  props,
) => {
  if (isVersionAwareMode(props.mode) && !props.versionId) {
    return (
      <WidgetSelectRenderer
        {...props}
        entityList={[]}
        isLoading={true}
        mode={props.mode}
      />
    )
  }

  if (props.mode === 'list') {
    return <WidgetSelectList {...props} />
  }

  if (props.mode === 'metric') {
    return <WidgetSelectMetric {...props} />
  }

  return <WidgetSelectReports {...props} />
}
