import React from 'react'
import { MetricDataValueType } from '@fintastic/web/util/metrics-and-lists'
import { RollUpFunction } from '@fintastic/shared/util/types'
import { ValueDataFormatNavButton } from '../../../shared/nav-buttons/value-data-format'
import { memoryRoutesPaths } from '../../memory-routes-paths'
import { AggregationFunctionNavButton } from '../../../shared/nav-buttons/aggregation-function'
import { NavButtonsContainer } from '../../../shared/NavButtonsContainer'

export type SubsectionsNavigationProps = {
  columnId: string
  onGoToRoute: (routePath: string) => void
  columnDataType: MetricDataValueType
  columnCategoryAggregation: RollUpFunction
}

export const SubsectionsNavigation: React.FC<SubsectionsNavigationProps> = ({
  columnId,
  onGoToRoute,
  columnDataType,
  columnCategoryAggregation,
}) => (
  <NavButtonsContainer>
    <ValueDataFormatNavButton
      onClick={() =>
        onGoToRoute(memoryRoutesPaths.columnValueDataFormat(columnId))
      }
      dataType={columnDataType}
    />
    <AggregationFunctionNavButton
      onClick={() =>
        onGoToRoute(memoryRoutesPaths.columnAggregationFunction(columnId))
      }
      categoryAggregation={columnCategoryAggregation}
    />
  </NavButtonsContainer>
)
