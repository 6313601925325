import { Maybe } from '@fintastic/shared/util/types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  editedInputMetricsRegistry,
  removeListColumnsCache,
  useLoadListColumns,
  resetListColumnsCache,
} from '@fintastic/web/data-access/metrics-and-lists'
import { useQueryClient } from 'react-query'
import keyBy from 'lodash/keyBy'
import { isEqual } from 'lodash'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useFilterContextApiPayload } from '@fintastic/web/util/filters'

export function useListColumns(
  versionId: Maybe<string>,
  listId: Maybe<string>,
  metricIds: Maybe<string[]>,
  periodSelection: Maybe<PeriodSelection>,
  enabled: boolean,
) {
  const [, setReRenderTrigger] = useState(0)
  const reRenderTriggeredManually = useRef(0)

  const filters = useFilterContextApiPayload()
  const { data, isError, isLoading, error } = useLoadListColumns(
    versionId,
    listId,
    metricIds,
    periodSelection || undefined,
    filters,
    enabled,
  )

  // @todo it's a patch for non-changing loading status; figure out the bug and fix in the future
  useEffect(() => {
    if (isLoading && reRenderTriggeredManually.current < 10) {
      reRenderTriggeredManually.current += 1
      const tid = setInterval(() => {
        setReRenderTrigger((c) => c + 1)
      }, 500)
      return () => clearInterval(tid)
    }
    return
  }, [isLoading])

  const queryClient = useQueryClient()

  const removeListCache = useCallback(() => {
    if (!(versionId && listId && metricIds)) {
      return
    }
    editedInputMetricsRegistry.remove({
      versionId,
      metricIds,
    })

    removeListColumnsCache(queryClient, {
      versionId,
      listId,
      columnId: metricIds,
    })
  }, [listId, metricIds, queryClient, versionId])

  const removeListCacheRef = useRef<Maybe<() => void>>(removeListCache)
  // workaround to prevent double request: Remove only loaded cache
  removeListCacheRef.current = isLoading ? null : removeListCache

  const reloadListCache = useCallback(async () => {
    if (!(versionId && listId && metricIds)) {
      return
    }

    editedInputMetricsRegistry.remove({
      versionId,
      metricIds,
    })

    await resetListColumnsCache(queryClient, {
      versionId,
      listId,
      columnId: metricIds,
    })
  }, [listId, metricIds, queryClient, versionId])

  // Remove list data from cache on unmount
  useEffect(
    () => () => {
      if (removeListCacheRef.current) {
        removeListCacheRef.current()
      }
    },
    [],
  )

  const columnsOrig = useMemo(() => keyBy(data, 'id'), [data])
  const columns = useReferenceMemo(columnsOrig, isEqual)

  return {
    columns,
    allColumnsLoaded: !isLoading,
    anyColumnHasError: isError,
    removeListCache,
    reloadListCache,
    error,
  } as const
}
