import React from 'react'
import {
  StyledArrowIcon,
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
} from './NavButton.styled'
import { ArrowIcon } from './ArrowIcon'
import { Tooltip } from '@mui/material'

export type NavButtonProps = {
  onClick: () => void
  primaryLabel: string
  secondaryLabel?: string
  icon: React.ReactNode
}

export const NavButton: React.FC<NavButtonProps> = ({
  onClick,
  secondaryLabel,
  primaryLabel,
  icon,
}) => (
  <StyledListItem onClick={onClick}>
    <StyledListItemIcon>{icon}</StyledListItemIcon>
    <Tooltip
      title={secondaryLabel}
      disableInteractive
      disableHoverListener={(secondaryLabel?.length || 0) < 28}
    >
      <StyledListItemText primary={primaryLabel} secondary={secondaryLabel} />
    </Tooltip>
    <StyledArrowIcon>
      <ArrowIcon fontSize="small" />
    </StyledArrowIcon>
  </StyledListItem>
)
