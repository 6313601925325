import { useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const useIsAnyDeeplinkAppliedForRoute = (
  watchCurrentDeeplink?: boolean,
): boolean => {
  const { search, pathname } = useLocation()
  const searchRef = useRef<string>(search)

  searchRef.current = search

  const refDeeplinkApplied = useMemo(
    () => !!(pathname && searchContainAnyDeeplink(searchRef.current)),
    [pathname],
  )

  const watchDeeplinkApplied = useMemo(
    () => !!(pathname && searchContainAnyDeeplink(search)),
    [pathname, search],
  )

  return watchCurrentDeeplink ? watchDeeplinkApplied : refDeeplinkApplied
}

const searchContainAnyDeeplink = (search: string): boolean => search.length > 0
