import { AddLinesStateData } from '@fintastic/web/feature/list-add-lines'
import { useCallback } from 'react'
import { saveNewRows } from '../api'
import { ColumnsIdStructure } from '../columns'
import { AxiosError } from 'axios'
import {
  CantSaveDataPermissionError,
  CantSaveDataUnknownError,
  RecoverableSelectedPermissionsDeleted,
} from '../consts'
import { convertStateToServerFormat } from './data-store-utils'

export const useSaveNewRows = (versionId: string, listId: string) => {
  const handleSave = useCallback(
    async (data: AddLinesStateData, structure: ColumnsIdStructure) => {
      const dataToSend = convertStateToServerFormat(structure, data.values)
      if (!dataToSend) {
        return false
      }
      
      try {
        await saveNewRows(versionId, listId, dataToSend)
      } catch (err) {
        console.error(err)

        if ((err as AxiosError).isAxiosError) {
          if ((err as AxiosError).response?.status === 422) {
            throw new CantSaveDataPermissionError(
              'Changes to your permissions have prevented you from saving the lines',
            )
          }

          if ((err as AxiosError).response?.status === 403) {
            throw new RecoverableSelectedPermissionsDeleted(
              'You selected dimension value(s) that have been deleted',
            )
          }
        }
        // @todo: in case of any other error?
        throw new CantSaveDataUnknownError('Some fatal error')
      }
      return true
    },
    [listId, versionId],
  )

  return { saveNewRows: handleSave }
}
