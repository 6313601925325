import { axios } from '@fintastic/web/data-access/service-axios'
import type { Metric } from '@fintastic/web/util/metrics-and-lists'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { mapPeriodSelectionToParams } from './helpers/map-period-selection-to-params'
import Qs from 'qs'
import { endpoints } from './metrics-endpoints'

export type ChartAggDimension = {
  dimension_uid: string
  aggregate: boolean
}

export function getMetricChart(
  versionId: string,
  metricId: string,
  periodSelection?: PeriodSelection,
  dimensions?: ChartAggDimension[],
  includeData = true,
) {
  return axios.post<Metric>(
    endpoints.metricChart(versionId, metricId),
    {
      dimensions: dimensions,
    },
    {
      params: {
        ...mapPeriodSelectionToParams(periodSelection),
        with_data: includeData,
      },
      paramsSerializer: (p: any) => Qs.stringify(p, { arrayFormat: 'repeat' }),
    },
  )
}

export async function loadChartData(
  versionId: string,
  metricId: string,
  periodSelection?: PeriodSelection,
  dimensions?: ChartAggDimension[],
): Promise<Metric> {
  const { data } = await getMetricChart(
    versionId,
    metricId,
    periodSelection,
    dimensions,
  )

  return data
}
