import React, { useCallback } from 'react'
import {
  NumberFilterInput,
  NumberFilterInputProps,
} from '@fintastic/shared/ui/filters'
import {
  FilterByNumber,
  FilterRange,
  filterUtils,
  FilterValue,
  useFilterContext,
} from '@fintastic/web/util/filters'
import type { Maybe } from '@fintastic/shared/util/types'

export const NumericFilterGroup: React.FC<{
  filter: FilterByNumber
  timeDimensionValueId: Maybe<string>
  disableClear?: boolean
}> = ({ filter, timeDimensionValueId, disableClear }) => {
  const filterContext = useFilterContext()

  const handleValueChanged = useCallback<NumberFilterInputProps['onChange']>(
    (nextValue) => {
      filterContext.setFilterValue(filter.id, timeDimensionValueId, nextValue)
    },
    [filter.id, filterContext, timeDimensionValueId],
  )

  return (
    <NumberFilterInput
      onChange={handleValueChanged}
      filterValue={
        filterUtils.getValueFromTheModel<FilterValue<number | FilterRange>>(
          filterContext.values,
          filter.id,
          timeDimensionValueId,
        ) ?? {}
      }
      displayConfig={filter.displayConfig}
      dataType={filter.dataType}
      disableClear={disableClear}
      filter={filter}
    />
  )
}

NumericFilterGroup.displayName = 'NumericFilterGroup'
