import React, { PropsWithChildren } from 'react'
import { StyledWidgetTab } from './WidgetTabs.styled'

export type WidgetTabProp = {
  id: string
  icon: React.ReactNode
  title?: string
  disabled?: boolean
  highlight?: boolean
} & PropsWithChildren

export const WidgetTab: React.FC<WidgetTabProp> = ({ id, icon, title }) => (
  <StyledWidgetTab key={id}></StyledWidgetTab>
)
