import { useAuthAccessToken } from '@fintastic/web/feature/auth'
import React, { useEffect } from 'react'
import {
  environment,
  isProduction,
} from '@fintastic/web/data-access/environment'
import { useRunAppInitialization } from './hooks/useRunAppInitialization'
import { AppLoader, ErrorAlert } from '@fintastic/shared/ui/components'
import { useLogrocketIdentify } from '@fintastic/web/util/logrocket'
import { useTenantOverride } from '@fintastic/web/data-access/service-auth0'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { AppRouter } from './AppRouter'

export const AppInitializer = (): JSX.Element => {
  const { tenantKey, tenantName } = useTenantOverride()

  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    user,
    token,
  } = useAuthAccessToken()

  const appInitStatuses = useRunAppInitialization({
    isAuthenticated: isAuthenticated && !!token,
  })

  const isAppInitStarted = !appInitStatuses.isInitial
  const isAppInitFinished = appInitStatuses.isFinished

  useEffect(() => {
    if (environment?.REACT_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: environment.REACT_APP_SENTRY_DSN,
        environment: environment?.REACT_APP_SENTRY_ENVIRONMENT || 'development',
        integrations: [new BrowserTracing()],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        enabled: isProduction,
      })
    }
  }, [])

  useEffect(() => {
    Sentry.setTag('tenant-id', tenantKey || undefined)
    Sentry.setTag('tenant-alias', tenantName || undefined)
  }, [tenantKey, tenantName])

  useEffect(() => {
    Sentry.setUser(
      user
        ? {
            id: user.sub,
            email: user.email,
            username: user.nickname,
          }
        : null,
    )
  }, [user])

  useLogrocketIdentify()

  useEffect(() => {
    if (appInitStatuses.error) {
      console.error(appInitStatuses.error)
    }
  }, [appInitStatuses.error])

  if (isAuthLoading || !isAuthenticated || !isAppInitFinished) {
    return (
      <AppLoader
        isAuthLoading={isAuthLoading}
        isAuthenticated={isAuthenticated}
        isAppInitStarted={isAppInitStarted}
        isAppInitFinished={isAppInitFinished}
      />
    )
  }

  if (appInitStatuses.error) {
    return (
      <ErrorAlert
        message="Could not initialize the application. Please try again later."
        severity="warning"
      />
    )
  }

  return <AppRouter />
}
