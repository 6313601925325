import { styled } from '@mui/material'
import { layoutConfig } from '@fintastic/shared/ui/app-layout-framework'

export const StyledDrawer = styled('div', {
  shouldForwardProp: (p) => p !== 'open',
})<{ open: boolean }>`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  background: ${({ theme }) => theme.palette.common.white};

  opacity: ${({ open }) => (open ? 1 : 0)};

  transform-origin: bottom left;
  transform: scaleY(${({ open }) => (open ? 1 : 0)});

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  filter: ${({ open }) =>
    open ? 'drop-shadow(0 -4px 8px rgba(0, 0, 0, 0.15))' : 'none'};

  // box-shadow: ${({ open }) =>
    open ? '0 -6px 12px rgba(0, 0, 0, .15)' : 'none'};

  min-height: ${({ open }) =>
    open ? layoutConfig.bottomdrawer.minHeight : 0}px;

  max-height: ${({ open }) =>
    open ? layoutConfig.bottomdrawer.maxHeight : 0}px;

  &.show {
    animation: showDrawer
      ${({ theme }) => theme.transitions.duration.standard}ms ease-in-out;
  }

  @keyframes showDrawer {
    0% {
      opacity: 0;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform: scaleY(1);
    }
  }
`

export const StyledDrawerContent = styled('div')`
  width: 100%;
`
