import { CalendarDatePickerConfig, PeriodSelection } from '../types'
import { isRangeBasedSelection } from './selection-guards'
import { isArray } from 'lodash'
import { Maybe } from '@fintastic/shared/util/types'
import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import {
  getMaxResolutionFromCalendar,
  isResolutionHigherOrEqual,
} from './resolutions'

export const validatePeriodSelection = (
  periodSelection: Maybe<PeriodSelection>,
  calendarConfig: CalendarDatePickerConfig,
  baseTimeDimensionId?: Maybe<TimeDimensionId>,
): periodSelection is PeriodSelection => {
  if (!periodSelection) {
    return false
  }

  const selectedDimension = calendarConfig.find(
    (i) => i.dimension_id === periodSelection.dimensionId,
  )

  const selectedAggegrationDimension = calendarConfig.find(
    (i) => i.dimension_id === periodSelection.aggregationDimensionId,
  )

  if (!selectedDimension || !selectedAggegrationDimension) {
    return false
  }

  if (
    baseTimeDimensionId &&
    periodSelection.aggregationDimensionId &&
    periodSelection.dimensionId
  ) {
    const baseResolution = getMaxResolutionFromCalendar(calendarConfig)

    if (baseResolution) {
      const aggregationResolution = selectedAggegrationDimension.resolution
      const dimensionResolution = selectedDimension.resolution

      if (!aggregationResolution || !dimensionResolution) {
        return false
      }

      const aggregationResolutionLowerThanBase = !isResolutionHigherOrEqual(
        aggregationResolution,
        baseResolution,
      )

      const dimensionResolutionLowerThanBase = !isResolutionHigherOrEqual(
        dimensionResolution,
        baseResolution,
      )

      if (
        aggregationResolutionLowerThanBase ||
        dimensionResolutionLowerThanBase
      ) {
        return false
      }
    }
  }

  const timeDimensionValues = selectedDimension.values.map(
    (i) => i.dimension_value_id,
  )

  if (isRangeBasedSelection(periodSelection)) {
    if (!isArray(periodSelection.range) || periodSelection.range.length !== 2) {
      return false
    }

    return periodSelection.range.every((id) => timeDimensionValues.includes(id))
  }

  if (
    !isArray(periodSelection.periods) ||
    periodSelection.periods.length === 0
  ) {
    return false
  }

  return periodSelection.periods.every((id) => timeDimensionValues.includes(id))
}
