import React, { useMemo } from 'react'
import { AggregationSettingsProps } from './types'
import {
  getDisallowedRollUps,
  MetricAsOption,
  MetricConfigurableDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { DataContext, DataContextValue } from './data-context'
import {
  StyledDivider,
  StyledRoot,
  StyledSettings,
} from './AggregationFunctionSettings.styled'
import { AggregationSettings } from './AggregationSettings'
import { Maybe, RollUpFunction } from '@fintastic/shared/util/types'
import { FlagsContext, FlagsContextValue } from './flags-context'

export type AggregationFunctionSettingsProps = {
  dataType: Maybe<MetricConfigurableDataValueType>
  timeAggregation: AggregationSettingsProps
  categoryAggregation: AggregationSettingsProps
  metricsList: MetricAsOption[]
  readonly?: boolean
  metricHasTimeDimension?: boolean
}

export const AggregationFunctionSettings: React.FC<
  AggregationFunctionSettingsProps
> = ({
  dataType,
  timeAggregation,
  categoryAggregation,
  metricsList,
  readonly,
  metricHasTimeDimension = false,
}) => {
  const dataContextValue = useMemo<DataContextValue>(
    () => ({
      metrics: metricsList,
    }),
    [metricsList],
  )

  const flagsContext = useMemo<FlagsContextValue>(
    () => ({
      readonly: !!readonly,
    }),
    [readonly],
  )

  const disabledTimeDimensionFunctions = useMemo<RollUpFunction[]>(
    () => (dataType ? getDisallowedRollUps(dataType, 'Time') : []),
    [dataType],
  )

  const disabledCategoryDimensionFunctions = useMemo<RollUpFunction[]>(
    () => (dataType ? getDisallowedRollUps(dataType, 'Category') : []),
    [dataType],
  )

  return (
    <DataContext.Provider value={dataContextValue}>
      <FlagsContext.Provider value={flagsContext}>
        <StyledRoot>
          <StyledSettings>
            <AggregationSettings
              {...categoryAggregation}
              title="Aggregation function"
              disabledFunctions={disabledCategoryDimensionFunctions}
            />
          </StyledSettings>
          {metricHasTimeDimension && (
            <>
              <StyledDivider />
              <StyledSettings>
                <AggregationSettings
                  {...timeAggregation}
                  title="Time aggregation"
                  disabledFunctions={disabledTimeDimensionFunctions}
                />
              </StyledSettings>
            </>
          )}
        </StyledRoot>
      </FlagsContext.Provider>
    </DataContext.Provider>
  )
}
