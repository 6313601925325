import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { VersionTable } from '../../types'
import { SetGridSizeProps } from '@fintastic/shared/ui/grid-framework'
import { MetricGridConnector } from '@fintastic/web/feature/metrics-and-lists'
import { ListConnector } from '@fintastic/web/feature/lists'
import { StyledDetailGridContainer } from '../../Version.styled'
import { Box, LinearProgress } from '@mui/material'
import { Maybe } from '@fintastic/shared/util/types'
import { ICellRendererParams } from 'ag-grid-community'
import { VersionListFormulaEditorWrapper } from '../formula/VersionListFormulaEditorWrapper'
import { useMasterDetailContext } from './master-detail-context'
import { getDetailTableId } from './getDetaiTablelId'
import { useFormulaContainerHeightObserver } from './useFormulaContainerHeightObserver'
import { usePeriodSelectionComponent } from '@fintastic/web/feature/period-selector'
import { Version } from '@fintastic/web/util/versions'
import { PeriodSelectorContext } from '@fintastic/web/util/period-selector'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import {
  BaseTimeDimensionStaticWidgetProvider,
  useBaseTimeDimensionCurrentValue,
  useBaseTimeDimensionStaticWidgetAdapter,
} from '@fintastic/web/data-access/base-time-dimension'
import { makeTableShortPersistingKey } from './table-persiting-key'
import {
  ListEditor,
  useListEditorApi,
} from '@fintastic/web/feature/list-editor'
import { useReportEditorApi } from '@fintastic/web/feature/report-editor'
import { ReportEditor } from '@fintastic/web/feature/report-editor'
import { GenericReportVersionPageDummy } from '@fintastic/web/feature/generic-report'

export type TableDataDetailRendererParams = {
  version: Version
}
export const TableDataDetailRenderer = React.forwardRef<
  unknown,
  ICellRendererParams<VersionTable> & TableDataDetailRendererParams
>(({ data, node, api, version }, ref) => {
  const tableId = data?.id || ''
  const tableType = data?.type || 'list'
  const title = data?.label || ''
  const creationDummy = !!data?.creationDummy

  useImperativeHandle(ref, () => ({
    refresh() {
      // We never need to remount master detail
      // in case of parent table row data update
      return true
    },
  }))

  const {
    getTab,
    toggleFormulaMasterDetail,
    versionId,
    versionLocked,
    setGridSizeCallback,
    onMetricOrListCreated,
    onCancelMetricOrListEditing,
    settingsEditingAllowed,
    requestEntityDeletion,
  } = useMasterDetailContext()
  const tab = getTab(tableId)

  const detailRowId = useMemo(() => getDetailTableId(tableId), [tableId])

  const diffs = useMemo(() => [], [])
  const versionIds = useMemo(() => [versionId], [versionId])

  const formulasContainerRef = useRef<Maybe<HTMLElement>>(null)
  const handleCloseFormula = useCallback(() => {
    toggleFormulaMasterDetail(tableId, node.parent)
  }, [node.parent, tableId, toggleFormulaMasterDetail])

  const setGridSize = useCallback(
    (sizes: SetGridSizeProps) => {
      setGridSizeCallback(tableId, sizes)
    },
    [setGridSizeCallback, tableId],
  )

  useEffect(() => {
    if (!detailRowId) {
      return
    }

    api.addDetailGridInfo(detailRowId, { id: detailRowId, api })

    return () => {
      api.removeDetailGridInfo(detailRowId)
    }
  }, [api, detailRowId])

  const deriveHeightFromFormulasContainer = useCallback(() => {
    if (!formulasContainerRef.current) {
      return
    }
    setGridSize({
      rows: 1,
      rowHeight:
        formulasContainerRef.current.getBoundingClientRect().height - 32, // remove paddings
      headerHeight: 0,
    })
  }, [setGridSize])

  useEffect(() => {
    deriveHeightFromFormulasContainer()
  }, [deriveHeightFromFormulasContainer, tab])

  useFormulaContainerHeightObserver(
    formulasContainerRef,
    deriveHeightFromFormulasContainer,
  )

  const settingsPanelProps = useMemo(
    () => ({
      enabled: true,
      editingAllowed: settingsEditingAllowed,
      onCreated: onMetricOrListCreated,
      onCancel: onCancelMetricOrListEditing,
    }),
    [
      settingsEditingAllowed,
      onMetricOrListCreated,
      onCancelMetricOrListEditing,
    ],
  )

  const listEditorApi = useListEditorApi()
  const reportEditorApi = useReportEditorApi()

  const selectedVersions = useMemo(() => [version], [version])
  const parentBaseDimensionId = useBaseTimeDimensionCurrentValue()
  const baseDimensionContextValue = useBaseTimeDimensionStaticWidgetAdapter(
    parentBaseDimensionId,
  )

  const persistingKey = useMemo(
    () => makeTableShortPersistingKey(versionId, tableId),
    [tableId, versionId],
  )

  const periodSelection = usePeriodSelectionComponent({
    selectedVersions,
    persistingKey,
    buttonDesign: 'compact',
    baseTimeDimensionId: baseDimensionContextValue.baseTimeDimensionId,
  })

  if (periodSelection.isError) {
    return (
      <ErrorAlert
        title="Loading error"
        message="Version entities or calendar could not be loaded."
      />
    )
  }

  if (periodSelection.isLoading) {
    return <LinearProgress />
  }

  return (
    <BaseTimeDimensionStaticWidgetProvider value={baseDimensionContextValue}>
      <PeriodSelectorContext.Provider value={periodSelection.contextValue}>
        <StyledDetailGridContainer
          sx={
            tab === 'formula' && node.parent?.data
              ? {
                  padding: 0,
                }
              : {}
          }
        >
          {tab === 'formula' &&
            node.parent?.data &&
            node.parent?.data.type !== 'report' && (
              <Box ref={formulasContainerRef}>
                <VersionListFormulaEditorWrapper
                  versionId={versionId}
                  versionLocked={versionLocked}
                  table={node.parent.data}
                  onRequestClose={handleCloseFormula}
                />
              </Box>
            )}

          {tab === 'grid' && (!tableType || tableType === 'list') && (
            <>
              {creationDummy ? (
                <>
                  {listEditorApi?.active && (
                    <ListEditor setGridSizeCallback={setGridSize} />
                  )}
                </>
              ) : (
                <>
                  {listEditorApi?.active &&
                  listEditorApi?.list.id === tableId ? (
                    <ListEditor setGridSizeCallback={setGridSize} />
                  ) : (
                    <ListConnector
                      listId={tableId}
                      selectableVersions={versionIds}
                      setGridSizeCallback={setGridSize}
                      title={title}
                      settingsPanel={settingsPanelProps}
                      isVersionPage={true}
                      isCreationDummy={creationDummy}
                      periodSelectorComponent={
                        periodSelection.periodSelectorComponent
                      }
                      requestEntityDeletion={requestEntityDeletion}
                    />
                  )}
                </>
              )}
            </>
          )}

          {tab === 'grid' && tableType === 'metric' && (
            <MetricGridConnector
              metricId={tableId}
              versions={versionIds}
              diffs={diffs}
              title={title}
              setGridSizeCallback={setGridSize}
              enableGrouping
              enableGridReset
              isLiveActuals={version.is_live_actuals}
              settingsPanel={settingsPanelProps}
              isVersionPage={true}
              isCreationDummy={creationDummy}
              periodSelectorComponent={periodSelection.periodSelectorComponent}
            />
          )}

          {tab === 'grid' && tableType === 'report' && reportEditorApi && (
            <>
              {creationDummy ? (
                <>
                  {reportEditorApi.active && (
                    <ReportEditor setGridSizeCallback={setGridSize} />
                  )}
                </>
              ) : (
                <>
                  {reportEditorApi.active &&
                  reportEditorApi.reportDefinition.id === tableId ? (
                    <ReportEditor setGridSizeCallback={setGridSize} />
                  ) : (
                    // @todo implement real integration
                    <GenericReportVersionPageDummy
                      reportId={tableId}
                      versionId={version.uuid}
                      title={title}
                      settingsEditingAllowed={settingsEditingAllowed}
                      setGridSizeCallback={setGridSize}
                    />
                  )}
                </>
              )}
            </>
          )}
        </StyledDetailGridContainer>
      </PeriodSelectorContext.Provider>
    </BaseTimeDimensionStaticWidgetProvider>
  )
})
