import { Alert, Box, styled } from '@mui/material'

export const StyledMetricChartRoot = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-flow: column nowrap;
  position: relative;
`

export const StyledMetricChartFormulas = styled(Box)`
  display: flex;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-flow: column nowrap;
  position: relative;

  animation-name: showFormulas;
  animation-duration: 0.5s;
  animation-direction: normal;
  animation-play-state: running;

  @keyframes showFormulas {
    0% {
      opacity: 0;
      max-height: 0;
      transform: translateY(-6px);
    }
    100% {
      opacity: 1;
      max-height: 2000px;
      transform: translateY(0);
    }
  }
`

export const StyledChartContent = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;
  flex-grow: 2;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
`

export const StyledChartError = styled(Box)`
  align-self: center;
  justify-self: center;
  width: 100%;
  position: relative;
  flex-grow: 2;
  flex-shrink: 1;
  display: flex;
  flex-flow: column wrap;
`

export const StyledChartErrorAlert = styled(Alert)`
  align-self: center;
  justify-self: center;
  width: auto;
`
export const StyledChartPanels = styled(Box)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-left: auto;
`

export const StyledChartDataPanel = styled(Box)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 2;
  width: 100%;
`
