import React from 'react'
import { WidgetSelectRenderer } from './WidgetSelectRenderer'
import { ListOrMetricOrReportSelectProps } from './WidgetSelect'
import { useLoadListOfReports } from '@fintastic/web/data-access/metrics-and-lists'

export const WidgetSelectReports: React.FC<ListOrMetricOrReportSelectProps> = (
  props,
) => {
  const { data: entityList, isLoading } = useLoadListOfReports()

  return (
    <WidgetSelectRenderer
      {...props}
      entityList={entityList || []}
      isLoading={isLoading}
      mode={'report'}
    />
  )
}
