import { useAuthAccessToken } from '@fintastic/web/feature/auth'
import React, { useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { isEqual, sortBy } from 'lodash'
import { useLocalStorage } from 'usehooks-ts'
import { MetricChartDimensions } from '../types'

export const checkLocalDimensionsValid = (
  referenceDimensions?: Maybe<MetricChartDimensions>,
  localDimensions?: Maybe<MetricChartDimensions>,
) => {
  if (!referenceDimensions || !localDimensions) {
    return false
  }

  if (!Array.isArray(referenceDimensions) || !Array.isArray(localDimensions)) {
    return false
  }

  const referenceDimensionsIds = (referenceDimensions || []).map(
    (dim) => dim.id,
  )
  const localDimensionsIds = (localDimensions || []).map((dim) => dim.id)

  return isEqual(sortBy(referenceDimensionsIds), sortBy(localDimensionsIds))
}

export const useLocalChartDimensions = (
  widgetId: string,
  referenceDimensions: MetricChartDimensions,
): readonly [
  MetricChartDimensions,
  React.Dispatch<React.SetStateAction<MetricChartDimensions>>,
] => {
  const { user } = useAuthAccessToken()

  const [cachedDimensions, setCachedDimensions] =
    useLocalStorage<MetricChartDimensions>(
      `chartMetricDimensions_${widgetId}_${user?.email}`,
      referenceDimensions ? [...referenceDimensions] : [],
    )

  const validatedCachedDimensions = useMemo(
    () =>
      checkLocalDimensionsValid(referenceDimensions, cachedDimensions)
        ? cachedDimensions
        : referenceDimensions,
    [referenceDimensions, cachedDimensions],
  )

  return [validatedCachedDimensions, setCachedDimensions] as const
}
