import { ChangeWidgetDialogParams } from '@fintastic/web/feature/boards'
import {
  GENERIC_REPORT_WIDGET_TYPE,
  LIST_GRID_WIDGET_TYPE,
  METRIC_CHART_WIDGET_TYPE,
  METRIC_GRID_WIDGET_TYPE,
} from '../../../../widgets'
import { useTextFieldState } from '@fintastic/shared/ui/form-framework'
import { useCallback, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  ChartSettings,
  defaultChartTheme,
  defaultChartType,
} from '@fintastic/web/feature/charts'
import { isEmpty, isEqual } from 'lodash'

// @todo: replace to MobX store
export const useWidgetSettings = (initialParams?: ChangeWidgetDialogParams) => {
  const widgetType = initialParams?.type || LIST_GRID_WIDGET_TYPE

  const isOneOfMetricWidgets =
    widgetType === METRIC_CHART_WIDGET_TYPE ||
    widgetType === METRIC_GRID_WIDGET_TYPE

  const isMetricGridWidget = widgetType === METRIC_GRID_WIDGET_TYPE

  const isMetricChartWidget = widgetType === METRIC_CHART_WIDGET_TYPE

  const isListWidget = widgetType === LIST_GRID_WIDGET_TYPE

  const isReportWidget = widgetType === GENERIC_REPORT_WIDGET_TYPE

  const initialDisplayLabel = initialParams?.displayLabel || ''

  const initialVersionId = initialParams?.versionId || null

  const initialMetricId = isOneOfMetricWidgets
    ? initialParams?.id || null
    : null

  const initialListId = isListWidget ? initialParams?.id || null : null

  const initialReportId = isReportWidget ? initialParams?.id || null : null

  const initialChartSettings =
    isMetricChartWidget && initialParams?.chartSettings
      ? initialParams?.chartSettings
      : null

  const displayNameFieldState = useTextFieldState(initialDisplayLabel)

  const [versionId, setVersionId] = useState<Maybe<string>>(initialVersionId)

  const [reportId, setReportId] = useState<Maybe<string>>(initialReportId)

  const [listId, setListId] = useState<Maybe<string>>(initialListId)

  const [metricId, setMetricId] = useState<Maybe<string>>(initialMetricId)

  const [chartSettings, setChartSettings] =
    useState<Maybe<ChartSettings>>(initialChartSettings)

  const [changed, setChanged] = useState(false)

  const isNew = !initialParams?.widgetId

  const hasName = Boolean(displayNameFieldState.trimmedValue)
  const hasVersion = Boolean(versionId)
  const hasMetric = Boolean(metricId)
  const hasList = Boolean(listId)

  const isEveryFieldFilled = useMemo(() => {
    if (isReportWidget) {
      return Boolean(reportId) && hasName
    }

    if (isMetricChartWidget) {
      return (
        hasVersion &&
        hasName &&
        hasMetric &&
        Boolean(chartSettings?.type) &&
        !isEmpty(chartSettings?.dimensions)
      )
    }
    return hasVersion && (hasMetric || hasList) && hasName
  }, [
    chartSettings?.dimensions,
    chartSettings?.type,
    hasList,
    hasMetric,
    hasName,
    hasVersion,
    isMetricChartWidget,
    isReportWidget,
    reportId,
  ])

  const isSubmitPossible = useMemo(
    () =>
      isEveryFieldFilled && (isNew || changed || displayNameFieldState.touched),
    [changed, displayNameFieldState.touched, isEveryFieldFilled, isNew],
  )

  const entityId = isReportWidget ? reportId : isListWidget ? listId : metricId

  const isVersionDependentWidget = isOneOfMetricWidgets || isListWidget

  const handleChartSettingsChange = useCallback(
    (settings: Partial<ChartSettings>) => {
      const newSettings = {
        type: settings.type || chartSettings?.type || defaultChartType,
        theme: settings.theme || chartSettings?.theme || defaultChartTheme,
        dimensions: settings.dimensions || chartSettings?.dimensions || [],
      }

      if (!isEqual(newSettings, chartSettings)) {
        setChartSettings(() => newSettings)

        setChanged(() => true)
      }
    },
    [chartSettings, setChanged, setChartSettings],
  )

  const handleSelectVersion = useCallback(
    (versionId: Maybe<string>) => {
      setVersionId(versionId)
      setChanged(() => true)
    },
    [setChanged, setVersionId],
  )

  const handleSelectReport = useCallback(
    (reportId: Maybe<string>, reportName: Maybe<string>) => {
      setReportId(reportId)
      if (
        !displayNameFieldState.touched ||
        !displayNameFieldState.trimmedValue
      ) {
        displayNameFieldState.assignValue(reportName || '')
      }
      setChanged(() => true)
    },
    [setReportId, displayNameFieldState, setChanged],
  )

  const handleSelectList = useCallback(
    (listId: Maybe<string>, listName: Maybe<string>) => {
      setListId(listId)
      if (
        !displayNameFieldState.touched ||
        !displayNameFieldState.trimmedValue
      ) {
        displayNameFieldState.assignValue(listName || '')
      }
      setChanged(() => true)
    },
    [setListId, displayNameFieldState, setChanged],
  )

  const handleSelectMetric = useCallback(
    (metricId: Maybe<string>, metricName: Maybe<string>) => {
      setMetricId(metricId)
      if (
        !displayNameFieldState.touched ||
        !displayNameFieldState.trimmedValue
      ) {
        displayNameFieldState.assignValue(metricName || '')
      }
      setChanged(() => true)
    },
    [setMetricId, displayNameFieldState, setChanged],
  )

  return useMemo(
    () => ({
      versionId,
      setVersionId,
      handleSelectVersion,

      reportId,
      setReportId,
      handleSelectReport,

      metricId,
      setMetricId,
      handleSelectMetric,

      listId,
      setListId,
      handleSelectList,

      chartSettings,
      setChartSettings,
      handleChartSettingsChange,

      entityId,

      changed,
      setChanged,

      displayNameFieldState,
      widgetType,

      isEveryFieldFilled,

      isMetricChartWidget,
      isOneOfMetricWidgets,
      isMetricGridWidget,
      isListWidget,
      isReportWidget,
      isVersionDependentWidget,

      isNew,
      isSubmitPossible,

      hasMetric,
      hasVersion,
    }),
    [
      versionId,
      handleSelectVersion,
      reportId,
      handleSelectReport,
      metricId,
      handleSelectMetric,
      listId,
      handleSelectList,
      chartSettings,
      handleChartSettingsChange,
      entityId,
      changed,
      displayNameFieldState,
      widgetType,
      isEveryFieldFilled,
      isMetricChartWidget,
      isOneOfMetricWidgets,
      isMetricGridWidget,
      isListWidget,
      isReportWidget,
      isVersionDependentWidget,
      isNew,
      isSubmitPossible,
      hasMetric,
      hasVersion,
    ],
  )
}
