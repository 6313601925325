import {
  VersionUserLock,
  VersionUserLockOpsVariants,
  VersionUserLockParsed,
} from './types'

export function parseVersionUserLockOps(
  source: VersionUserLock['ops'],
): VersionUserLockOpsVariants[] {
  if (!source) {
    return []
  }

  if (source === 'calc') {
    return ['calc']
  }

  if (source === 'edit_mode') {
    return ['edit_mode']
  }

  const splitSource = source.split(',')
  const out: VersionUserLockOpsVariants[] = []

  if (splitSource.includes('calc')) {
    out.push('calc')
  }

  if (splitSource.includes('edit_mode')) {
    out.push('edit_mode')
  }

  return out
}

export function parseVersionUserLock(
  versionId: string,
  source: VersionUserLock,
  userInfoEmail: string,
): Omit<VersionUserLockParsed, 'editIsBlocked'> {
  const lockOps = parseVersionUserLockOps(source?.ops)

  const lockOpsMap = Object.fromEntries<true>(
    lockOps.map((op) => [op, true]),
  ) as Record<VersionUserLockOpsVariants, true>

  let lockedBy: VersionUserLockParsed['lockedBy'] = undefined

  if (lockOps.length) {
    if (source?.user === '') {
      lockedBy = 'system'
    } else if (source?.user === userInfoEmail) {
      lockedBy = 'me'
    } else {
      lockedBy = 'someone_else'
    }
  }

  return {
    versionId,
    lockReasons: lockOps.length ? lockOpsMap : null,
    originalLock: source,
    lockedBy,
    lockOps,
  }
}

export const versionIsLockedMessage = (
  lock: VersionUserLockParsed,
  action = 'Editing',
): string => {
  if (!lock.lockReasons) {
    return ''
  }

  if (lock.lockReasons?.calc && lock.lockedBy === 'system') {
    return `${action} is temporarily suspended due to Live Actuals data sync. Try again in few minutes`
  }

  return `${action} is temporarily suspended by ${lock.originalLock?.user}`
}

export const doesUserLockPreventEdit = (
  lock: Omit<VersionUserLockParsed, 'editIsBlocked'>,
  versionId: string,
  liveActualsVersionId: string,
): boolean => {
  if (lock.lockReasons?.edit_mode) {
    return true
  }

  if (versionId === liveActualsVersionId) {
    return !!lock.lockReasons?.calc && lock.lockedBy === 'system'
  }

  return false
}
