import React from 'react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { Box } from '@mui/material'
import { ErrorAlert } from '@fintastic/shared/ui/components'

export type SaveErrorFatalModalProps = {
  open: boolean
  onContinue: () => void
}

export const SaveErrorFatalModal: React.FC<SaveErrorFatalModalProps> = ({
  open,
  onContinue,
}) => (
  <ConfirmationPopup
    open={open}
    onRequestClose={onContinue}
    onConfirm={onContinue}
    onDecline={onContinue}
    title={'Unable to save lines'}
    autoClose={false}
  >
    <Box width="100%">
      <ErrorAlert
        title={
          'Changes to your permissions have prevented you from saving the lines'
        }
        message=""
        severity="warning"
      />
      <Box mt={3} width="100%" display="flex" justifyContent="center">
        <ConfirmationPopup.ConfirmButton
          sx={{ ml: 1 }}
          variant="contained"
          color="primary"
        >
          OK
        </ConfirmationPopup.ConfirmButton>
      </Box>
    </Box>
  </ConfirmationPopup>
)
