import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { HistoryLogLevel } from '@fintastic/web/util/history'

type HistoryLogGlobalState = {
  isOpen: boolean
} & (
  | {
      level: null
    }
  | {
      level: Extract<HistoryLogLevel, 'version'>
      versionId: string
    }
  | {
      level: Extract<HistoryLogLevel, 'entity'>
      versionId: string
      entityId: string
      entityLabel: string
    }
)

export type HistoryLogGlobalApi = {
  state: HistoryLogGlobalState
  close: () => void
  openOnVersionLevel: (versionId: string) => void
  openOnEntityLevel: (payload: {
    versionId: string
    entityId: string
    entityLabel: string
  }) => void
}

const HitoryLogGlobalApiContext =
  createContext<Maybe<HistoryLogGlobalApi>>(null)

export const useHistoryLogGlobalApi = () =>
  useContext(HitoryLogGlobalApiContext)

export const HistoryLogGlobalApiProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useState<HistoryLogGlobalState>({
    level: null,
    isOpen: false,
  })

  const close = useCallback(
    () => setState((s) => ({ ...s, isOpen: false })),
    [],
  )

  const openOnVersionLevel = useCallback(
    (versionId: string) =>
      setState({
        isOpen: true,
        level: 'version',
        versionId,
      }),
    [],
  )

  const openOnEntityLevel = useCallback(
    (payload: { versionId: string; entityId: string; entityLabel: string }) =>
      setState({
        isOpen: true,
        level: 'entity',
        ...payload,
      }),
    [],
  )

  const api = useMemo<HistoryLogGlobalApi>(
    () => ({
      state,
      close,
      openOnVersionLevel,
      openOnEntityLevel,
    }),
    [close, openOnEntityLevel, openOnVersionLevel, state],
  )

  return (
    <HitoryLogGlobalApiContext.Provider value={api}>
      {children}
    </HitoryLogGlobalApiContext.Provider>
  )
}
