import React, { memo, useCallback, useMemo, useState } from 'react'
import {
  StyledChartLocalButtons,
  StyledChartLocalSettings,
} from './ChartLocalSettingsPanel.styled'
import { Button, LinearProgress } from '@mui/material'
import { MetricChartForm } from '../ChartSettings/MetricChartForm'
import {
  ChartDisplaySettings,
  ChartSettings,
  MetricChartDimensions,
} from '../../types'
import { useLocalChartDimensions } from '../../hooks/useLocalChartDimensions'
import { useLocalChartDisplaySettings } from '../../hooks/useLocalChartDisplaySettings'
import { defaultChartTheme, defaultChartType } from '../../consts'
import {
  ChartThemeSelect,
  ChartTypeSelect,
} from '@fintastic/web/feature/charts'

export type ChartLocalSettingsProps = {
  widgetId: string
  loading?: boolean
  metricId: string
  versions: string[]
  initialSettings: ChartSettings
}

export const ChartLocalSettingsPanel: React.FC<ChartLocalSettingsProps> = memo(
  ({ widgetId, loading, metricId, versions, initialSettings }) => {
    const [localDimensions, setLocalDimensions] = useLocalChartDimensions(
      widgetId,
      initialSettings.dimensions || [],
    )

    const [dimensions, setDimensions] = useState<MetricChartDimensions>([
      ...localDimensions,
    ])

    const [changed, setChanged] = useState(false)

    const [localDisplaySettings, setLocalDisplaySettings] =
      useLocalChartDisplaySettings(widgetId, {
        type: initialSettings.type,
        theme: initialSettings.theme,
      })

    const handleApply = useCallback(() => {
      setLocalDimensions(() => dimensions)
      setChanged(() => false)
    }, [dimensions, setLocalDimensions])

    const handleCancel = useCallback(() => {
      setDimensions(() => localDimensions)
      setChanged(() => false)
    }, [localDimensions])

    const handleReset = useCallback(() => {
      setLocalDisplaySettings(() => ({
        theme: initialSettings.theme,
        type: initialSettings.type,
      }))
      setLocalDimensions(initialSettings.dimensions || [])
      setDimensions(() => initialSettings.dimensions || [])

      setChanged(() => false)
    }, [
      initialSettings.dimensions,
      initialSettings.theme,
      initialSettings.type,
      setLocalDimensions,
      setLocalDisplaySettings,
    ])

    const handleChartDimensionsChange = useCallback(
      (settings: Partial<ChartSettings>, initial?: boolean) => {
        setDimensions(() => settings?.dimensions || [])

        if (!initial) {
          setChanged(() => true)
        }
      },
      [],
    )

    const handleDisplaySettingsChange = useCallback(
      (settings: Partial<ChartSettings>) => {
        setLocalDisplaySettings((v: ChartDisplaySettings) => ({
          type: settings.type || v?.type || defaultChartType,
          theme: settings.theme || v?.theme || defaultChartTheme,
        }))
      },
      [setLocalDisplaySettings],
    )

    const referenceSettingsChanged = useMemo(
      () =>
        JSON.stringify(initialSettings.dimensions) !==
        JSON.stringify(localDimensions),
      [initialSettings, localDimensions],
    )

    return (
      <StyledChartLocalSettings>
        {loading && (
          <LinearProgress
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 2,
            }}
          />
        )}
        <ChartTypeSelect
          disabled={loading}
          chartType={localDisplaySettings.type || defaultChartType}
          onChangeChartSettings={handleDisplaySettingsChange}
        />

        <ChartThemeSelect
          disabled={loading}
          chartTheme={localDisplaySettings.theme || defaultChartTheme}
          onChangeChartSettings={handleDisplaySettingsChange}
        />

        <MetricChartForm
          metricId={metricId}
          loading={loading}
          initialSettings={initialSettings || null}
          chartSettings={{ dimensions: dimensions }}
          onChangeChartSettings={handleChartDimensionsChange}
          version={versions[0]}
        />

        <StyledChartLocalButtons>
          <Button
            disabled={!referenceSettingsChanged || loading}
            color="primary"
            variant="text"
            sx={{ textTransform: 'uppercase' }}
            onClick={handleReset}
          >
            Reset
          </Button>

          <Button
            disabled={!changed || loading}
            color="primary"
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button
            disabled={!changed || loading}
            sx={{ ml: 1 }}
            color="primary"
            variant="contained"
            onClick={handleApply}
          >
            Apply
          </Button>
        </StyledChartLocalButtons>
      </StyledChartLocalSettings>
    )
  },
)
