import { Box, styled } from '@mui/material'

export const StyledLoadingDimensions = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  place-content: center;
  margin: -16px auto 0;
  width: 90%;
`

export const StyledLoadingDimensionRows = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  place-content: center;
  width: 80%;
  margin: 0 auto;
`

export const StyledErrorLoadingDimensions = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  place-content: center;
`
