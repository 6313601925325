import React, { useCallback } from 'react'
import { widgetTypeToName } from '../../widgets/board-design-type-utils'
import { ModalWithParamsState } from '@fintastic/shared/util/modal'
import { ChangeWidgetDialogParams } from '../../../../types/board-design-types'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { BoardChangeWidgetForm } from '../ChangeWidgetForm/BoardChangeWidgetForm'
import { Box } from '@mui/material'
import { TEXT_WIDGET_TYPE } from '../../../widgets'

type ChangeWidgetProps = {
  state: ModalWithParamsState<ChangeWidgetDialogParams>
  onConfirm: (params: ChangeWidgetDialogParams) => void
}

export const ChangeWidgetDialog: React.FC<ChangeWidgetProps> = ({
  state,
  onConfirm,
}) => {
  const handleConfirm = useCallback(
    (params: ChangeWidgetDialogParams) => {
      state.close()
      onConfirm(params)
    },
    [onConfirm, state],
  )

  if (!state.isOpen) {
    return null
  }

  let title = `Add ${widgetTypeToName(
    state.params?.type || TEXT_WIDGET_TYPE,
  )} widget`

  if (state.params.widgetId) {
    title = `${widgetTypeToName(
      state.params?.type || TEXT_WIDGET_TYPE,
    )} settings`
  }

  return (
    <Modal
      open={true}
      onRequestClose={state.close}
      title={title}
      data-testid="board-change-widget-root"
    >
      <Box mt={1}>
        <BoardChangeWidgetForm
          params={state.params}
          closeParentModal={state.close}
          onConfirm={handleConfirm}
        />
      </Box>
    </Modal>
  )
}
