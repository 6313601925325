import React, { useEffect, useMemo } from 'react'
import {
  PageLayout,
  PageLayoutContentError,
  PageLayoutContentLoader,
} from '@fintastic/shared/ui/app-layout-framework'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../routes'
import {
  useLoadVersion,
  useLoadVersionEntities,
  useVersionUserLockQuery,
} from '@fintastic/web/data-access/versions'
import { VersionTable } from '../components/version-management/types'
import {
  useLoadListOfMetrics,
  useLoadListOfLists,
} from '@fintastic/web/data-access/metrics-and-lists'
import { LinearProgress } from '@mui/material'
import { VersionPageContainer } from '../containers/VersionPageContainer'
import { CommonVersionPageContainer } from '../containers/shared/CommonVersionPageContainer'
import { useLoadListOfReports } from '@fintastic/web/data-access/generic-report'

type UrlParams = { versionId: string }

export const VersionPage: React.FC = () => {
  const { versionId } = useParams<UrlParams>()

  const showHiddenLists = false
  const showHiddenMetrics = false

  const navigate = useNavigate()
  useEffect(() => {
    if (!versionId) {
      navigate(routes.versionsList)
    }
  }, [navigate, versionId])

  const versionQuery = useLoadVersion(versionId)
  const versionUserLock = useVersionUserLockQuery(versionId || null)
  const listsQuery = useLoadListOfLists(versionId || '', showHiddenLists)
  const metricsQuery = useLoadListOfMetrics(versionId || '', showHiddenMetrics)
  const entities = useLoadVersionEntities(versionId)
  const reportsQuery = useLoadListOfReports(versionId || '')

  const isFetching =
    versionQuery.isFetching ||
    versionUserLock.isFetching ||
    listsQuery.isFetching ||
    metricsQuery.isFetching ||
    entities.isFetching ||
    reportsQuery.isFetching
  const isError =
    versionQuery.isError ||
    listsQuery.isError ||
    metricsQuery.isError ||
    reportsQuery.isError
  const isSuccess =
    versionQuery.isSuccess &&
    listsQuery.isSuccess &&
    metricsQuery.isSuccess &&
    entities.isSuccess &&
    reportsQuery.isSuccess

  const versionTables = useMemo<VersionTable[]>(() => {
    if (!isSuccess) {
      return []
    }
    const listsWithType =
      listsQuery?.data?.map((list) => ({
        ...list,
        type: 'list' as const,
        _rowId: `${list.id}:existing`,
      })) || []
    const metricsWithType =
      metricsQuery?.data?.map((metric) => ({
        ...metric,
        type: 'metric' as const,
        _rowId: `${metric.id}:existing`,
      })) || []

    return [
      ...listsWithType,
      ...metricsWithType,
      ...(reportsQuery.data || []).map((report) => ({
        type: 'report' as const,
        id: report.id,
        label: report.name,
        _rowId: `${report.id}:existing`,
      })),
    ]
  }, [isSuccess, listsQuery?.data, metricsQuery?.data, reportsQuery.data])

  if (isSuccess && versionQuery.data) {
    return (
      <CommonVersionPageContainer versionId={versionQuery.data.uuid}>
        <VersionPageContainer
          version={versionQuery.data}
          versionTables={versionTables}
          versionUserLock={versionUserLock.lock}
        />
      </CommonVersionPageContainer>
    )
  }

  return (
    <PageLayout>
      {!isSuccess && <PageLayoutContentLoader />}
      {isSuccess && isFetching && (
        <LinearProgress
          sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
        />
      )}
      {isError && (
        <PageLayoutContentError
          title="Can't load the version"
          message="Something went wrong."
        />
      )}
    </PageLayout>
  )
}
