import { useModalState } from '@fintastic/shared/util/modal'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Version } from '@fintastic/web/util/versions'
import dayjs from 'dayjs'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'
import { useImportActualsToVersion } from '@fintastic/web/data-access/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'
import {
  ImportActualsConfigContext,
  ImportActualsConfigContextValue,
} from './context/ImportActualsConfigContext'
import { ImportModalContainer } from './containers/ImportModalContainer'
import { ConfirmationModal } from './components/ConfirmationModal'
import {
  ImportConfigItem,
  updateImportConfig,
  useImportConfigQueryInvalidate,
} from '@fintastic/web/data-access/import-configs'
import { useWaitImportCalculationEvent } from './hooks/useWaitImportCalculationEvent'
import { Maybe } from '@fintastic/shared/util/types'

const MAX_WAIT_TIME = 2 * 60 * 1000

export function useImportActualsDialog(version: Version) {
  const mainModal = useModalState()
  const confirmationModal = useModalState()

  const [dateFrom, setDateFrom] = useState<string>('')
  const [dateTo, setDateTo] = useState<string>('')
  const [selectedItems, setSelectedItems] = useState<ImportConfigItem[]>(
    () => [],
  )
  const appliedConfigRef = useRef<ImportConfigItem[]>([])

  const importActualsMutation = useImportActualsToVersion(version.uuid)
  const invalidateImportConfig = useImportConfigQueryInvalidate(version.uuid)

  const minPossibleDate = useMemo(
    () =>
      dayjs(version.period_start)
        .startOf('M')
        .format(DEFAULT_DAYJS_DATE_FORMAT),
    [version.period_start],
  )
  const maxPossibleDate = useMemo(
    () =>
      dayjs(version.period_end).endOf('M').format(DEFAULT_DAYJS_DATE_FORMAT),
    [version.period_end],
  )
  const defaultDateTo = useMemo(() => {
    const prevMonth = dayjs(new Date()).subtract(1, 'month').endOf('month')
    return prevMonth.isAfter(maxPossibleDate)
      ? maxPossibleDate
      : prevMonth.format(DEFAULT_DAYJS_DATE_FORMAT)
  }, [maxPossibleDate])

  useEffect(() => {
    setDateFrom(minPossibleDate)
    setDateTo(defaultDateTo)
  }, [minPossibleDate, defaultDateTo])

  const handleAskForConfirmation = useCallback(() => {
    mainModal.close()
    confirmationModal.open()
  }, [confirmationModal, mainModal])

  const [locked, setLocked] = useState(false)
  const [calculating, setCalculating] = useState(false)

  const handleDecline = useCallback(() => {
    mainModal.open()
    confirmationModal.close()
  }, [confirmationModal, mainModal])

  const handleOpenMainModal = useCallback(async () => {
    await invalidateImportConfig()
    appliedConfigRef.current = []
    setDateFrom(minPossibleDate)
    setDateTo(defaultDateTo)
    importActualsMutation.reset()
    mainModal.open()
  }, [
    defaultDateTo,
    invalidateImportConfig,
    mainModal,
    minPossibleDate,
    importActualsMutation,
  ])

  const updateDateRange = useCallback<
    ImportActualsConfigContextValue['updateDateRange']
  >((newDateFrom, newDateTo) => {
    setDateFrom(newDateFrom)
    setDateTo(newDateTo)
  }, [])

  const importActualsConfigContextValue =
    useMemo<ImportActualsConfigContextValue>(
      () => ({
        maxPossibleDate,
        minPossibleDate,
        versionId: version.uuid,
        dateFrom,
        dateTo,
        updateDateRange,
        appliedConfigRef,
        loading: locked || importActualsMutation.isLoading || calculating, // loading includes calculating
        calculating,
        selectedItems,
        setSelectedItems,
      }),
      [
        dateFrom,
        dateTo,
        importActualsMutation.isLoading,
        locked,
        calculating,
        maxPossibleDate,
        minPossibleDate,
        updateDateRange,
        version.uuid,
        selectedItems,
        setSelectedItems,
      ],
    )

  const [resetKey, setResetKey] = useState(1)

  const handleRequestClose = useCallback(async () => {
    appliedConfigRef.current = []
    setResetKey((k) => k + 1)
    setDateFrom(minPossibleDate)
    setDateTo(defaultDateTo)
    setCalculating(() => false)

    mainModal.close()
  }, [defaultDateTo, mainModal, minPossibleDate])

  const currentTaskId = useRef<Maybe<string>>(null)
  // handle calculation errors and timeouts
  const calculationTimeout = useRef<ReturnType<typeof setTimeout>>()

  const handleSuccessImport = useCallback(
    async (taskIds: string[]) => {
      if (!currentTaskId.current || !taskIds.includes(currentTaskId.current)) {
        return
      }

      clearTimeout(calculationTimeout.current)

      setTimeout(() => {
        // to guarantee the message
        toast.success('All selected metrics/lists were successfully updated')
      }, 200)

      try {
        await invalidateImportConfig() // <-- may fail
      } catch (e) {
        console.error(e)
      }

      try {
        await handleRequestClose()
      } catch (e) {
        console.error(e)
      }
    },
    [invalidateImportConfig, handleRequestClose, currentTaskId],
  )

  const flushEventCheck = useWaitImportCalculationEvent(
    version.uuid,
    handleSuccessImport,
  )

  useEffect(
    () => () => {
      clearTimeout(calculationTimeout.current)
    },
    [],
  )

  const handleConfirm = useCallback(async () => {
    mainModal.open()
    confirmationModal.close()
    currentTaskId.current = null

    if (!dateFrom || !dateTo) {
      return
    }

    setLocked(true)
    clearTimeout(calculationTimeout.current)

    try {
      await Promise.all(
        appliedConfigRef.current.map((configItem) =>
          updateImportConfig(configItem),
        ),
      )
    } catch (ex) {
      toast.error('Failed to update import configuration')
      console.error(ex)
      return
    } finally {
      setLocked(false)
    }

    const exclusiveDateTo = dayjs(dateTo, DEFAULT_DAYJS_DATE_FORMAT)
      .add(1, 'M')
      .startOf('M')
      .format(DEFAULT_DAYJS_DATE_FORMAT)

    // add guard...
    calculationTimeout.current = setTimeout(() => {
      setLocked(() => false)
      setCalculating(() => false)
      toast.error('Calculation timeout.')
    }, MAX_WAIT_TIME)

    // call mutation
    importActualsMutation.mutate(
      {
        periodStart: dateFrom,
        periodEnd: exclusiveDateTo,
      },
      {
        onError: (err: any) => {
          console.log('error', err)
          clearTimeout(calculationTimeout.current)
          setLocked(false)
          setCalculating(false)
          toast.error(
            err ? err.message || err.toString() : 'Unknown error on mutation!',
          )
        },
        onSuccess: async (taskId) => {
          setCalculating(true)
          currentTaskId.current = taskId
          setLocked(false)
          flushEventCheck()
        },
      },
    )
  }, [
    mainModal,
    confirmationModal,
    dateFrom,
    dateTo,
    importActualsMutation,
    flushEventCheck,
  ])

  return useMemo(
    () =>
      ({
        openModal: handleOpenMainModal,
        content: (
          <ImportActualsConfigContext.Provider
            value={importActualsConfigContextValue}
          >
            <ImportModalContainer
              key={resetKey}
              isOpen={mainModal.isOpen}
              onAskForConfirmation={handleAskForConfirmation}
              onRequestClose={handleRequestClose}
              isLoading={
                importActualsMutation.isLoading || locked || calculating
              }
              isCalculating={calculating}
              isError={importActualsMutation.isError}
            />
            <ConfirmationModal
              onConfirm={handleConfirm}
              onDecline={handleDecline}
              isOpen={confirmationModal.isOpen}
            />
          </ImportActualsConfigContext.Provider>
        ),
      } as const),
    [
      handleOpenMainModal,
      importActualsConfigContextValue,
      resetKey,
      mainModal.isOpen,
      handleAskForConfirmation,
      handleRequestClose,
      importActualsMutation.isLoading,
      importActualsMutation.isError,
      locked,
      calculating,
      handleConfirm,
      handleDecline,
      confirmationModal.isOpen,
    ],
  )
}
