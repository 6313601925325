import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { keyBy, pick } from 'lodash'
import { useLoadVersionEntities } from '@fintastic/web/data-access/versions'
import {
  useHistoryLogGlobalApi,
  useHistoryLogParamsState,
  useLoadHistoryLog,
} from '@fintastic/web/data-access/history'
import { useLocalFiltersAndOrderingParamsState } from '../filters-and-ordering'
import { SupportedEntry, supportedEntryTypes } from './supported-entry-types'
import {
  StyledFilters,
  StyledHeader,
  StyledList,
  StyledPagination,
  StyledRoot,
} from './EntityLevelHistory.styled'
import { Header } from './Header'
import { FiltersAndOrdering } from './FiltersAndOrdering'
import { EntriesList } from './EntriesList'
import { Pagination } from '../pagination'
import {
  ErrorStatus,
  InitialLoadingStatus,
  NoResultsStatus,
} from '../status-indication'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { CollapsibleContainer } from '@fintastic/shared/ui/components'
import { Maybe } from '@fintastic/shared/util/types'

export const EntityLevelHistory: React.FC = () => {
  const globalApi = useHistoryLogGlobalApi()
  const globalState =
    globalApi?.state.level === 'entity' ? globalApi.state : null
  const versionId = globalState ? globalState.versionId : null
  const { params, changeParams, changeCurrentPage, reset, defaultParams } =
    useHistoryLogParamsState(
      useMemo(
        () =>
          globalState
            ? {
                paginationLimit: 30,
                filter: {
                  parent_entity_id: [globalState.versionId],
                  parent_entity_type: ['version'],
                  entity_id: [globalState.entityId],
                },
              }
            : {},
        [globalState],
      ),
    )

  const localFiltersAndOrderingStateApi = useLocalFiltersAndOrderingParamsState(
    useMemo(() => pick(params, ['filter', 'desc']), [params]),
    useMemo(() => pick(defaultParams, ['filter', 'desc']), [defaultParams]),
  )
  const applyLocalFiltersAndOrdering = useCallback(() => {
    changeParams(localFiltersAndOrderingStateApi.localParams)
  }, [changeParams, localFiltersAndOrderingStateApi.localParams])

  const logQuery = useLoadHistoryLog(params, 'entity', versionId !== null)
  const entries = useMemo(() => {
    if (!logQuery.data) {
      return []
    }
    return logQuery.data.result.filter(({ _type }) =>
      supportedEntryTypes.includes(_type),
    ) as SupportedEntry[]
  }, [logQuery.data])

  const entitiesQuery = useLoadVersionEntities(versionId, versionId !== null)
  const dimensions = useMemo(
    () => keyBy(entitiesQuery.data?.dimensions || [], 'id'),
    [entitiesQuery.data?.dimensions],
  )

  const isBusy =
    logQuery.isFetching || logQuery.isLoading || entitiesQuery.isLoading
  const isInitialLoading = logQuery.isLoading || entitiesQuery.isLoading
  const isError =
    !isInitialLoading && (logQuery.isError || entitiesQuery.isError)

  const listContainerRef = useRef<Maybe<HTMLDivElement>>(null)
  useEffect(() => {
    listContainerRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [params.pagination.offset])

  if (!globalApi || globalState === null) {
    return null
  }

  return (
    <StyledRoot>
      <StyledHeader>
        <Header onClose={globalApi.close} label={globalState.entityLabel} />
      </StyledHeader>
      <FintasticThemeProvider applyLegacyTheme={false}>
        <StyledFilters>
          <CollapsibleContainer
            closeTitleText="Hide filters"
            openTitleText="Show filters"
            counter={localFiltersAndOrderingStateApi.diffsFromDefaultsCounter}
            initialOpen={
              localFiltersAndOrderingStateApi.diffsFromDefaultsCounter > 0
            }
          >
            <FiltersAndOrdering
              localParamsStateApi={localFiltersAndOrderingStateApi}
              isBusy={isBusy}
              resetToDefaults={reset}
              apply={applyLocalFiltersAndOrdering}
            />
          </CollapsibleContainer>
        </StyledFilters>
        <StyledList ref={listContainerRef}>
          {isInitialLoading && <InitialLoadingStatus />}
          {isError && <ErrorStatus message={'Cannot load hisory'} />}
          {!isInitialLoading && !isError && (
            <>
              {entries.length === 0 ? (
                <NoResultsStatus />
              ) : (
                <EntriesList
                  entries={entries}
                  isBusy={isBusy}
                  dimensions={dimensions}
                />
              )}
            </>
          )}
        </StyledList>
        {entries.length > 0 && (
          <StyledPagination>
            <Pagination
              goToPage={changeCurrentPage}
              entriesPerPage={params.pagination.limit}
              offset={params.pagination.offset}
              totalEntries={logQuery.data?.total || 0}
              isBusy={isBusy}
              siblingCount={0}
              boundaryCount={1}
            />
          </StyledPagination>
        )}
      </FintasticThemeProvider>
    </StyledRoot>
  )
}
