import type { CellRange, IRowModel } from 'ag-grid-community'
import { Column, RowNode } from 'ag-grid-community'

export function isCellRangeContainsMoreThanOneCell(range: CellRange): boolean {
  if (range.columns.length > 1) {
    return true
  }
  return range.startRow?.rowIndex !== range.endRow?.rowIndex
}

export function isCellRangesContainMoreThanOneCell(
  ranges: CellRange[],
): boolean {
  if (ranges.length > 1) {
    return true
  }
  return isCellRangeContainsMoreThanOneCell(ranges[0])
}

export function forEachCellInRange<TData = any>(
  cellRange: CellRange,
  model: IRowModel,
  cb: (
    rowNode: RowNode<TData>,
    column: Column,
    cellRange: CellRange,
    model: IRowModel,
  ) => void,
) {
  const { startRow, endRow, columns } = cellRange

  if (!startRow || !endRow) {
    return
  }

  const startIndex = Math.min(startRow.rowIndex, endRow.rowIndex)
  const endIndex = Math.max(startRow.rowIndex, endRow.rowIndex)

  for (let rowIndex = startIndex; rowIndex <= endIndex; rowIndex++) {
    const row = model.getRow(rowIndex)
    if (!row) {
      continue
    }

    columns.forEach((column) => {
      cb(row, column, cellRange, model)
    })
  }
}

export function forEachCellInRangeList<TData = any>(
  cellRanges: CellRange[],
  model: IRowModel,
  cb: (
    rowNode: RowNode<TData>,
    column: Column,
    cellRange: CellRange,
    cellRangeList: CellRange[],
    model: IRowModel,
  ) => void,
) {
  cellRanges.forEach((cellRange) => {
    forEachCellInRange(cellRange, model, (row, column, cellRange, model) => {
      cb(row, column, cellRange, cellRanges, model)
    })
  })
}
