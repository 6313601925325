import { QueryKey, useMutation, useQueryClient } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from '@fintastic/shared/ui/toast-framework'
import {
  metricsAndListsCacheKeys,
  metricsAndListsMutationsOnlyKeys,
} from '../cache'
import { attachLabelToEntity, detachLabelFromEntity } from '../api/labels-api'
import type { CompactMetricOrList } from '@fintastic/web/util/metrics-and-lists'
import { VersionLabelToggleParams } from '../types'

export const useToggleLabelForListOrMetric = (
  params: Omit<VersionLabelToggleParams, 'labelId'>,
) => {
  const queryClient = useQueryClient()

  const result = useMutation<
    AxiosResponse<string>,
    AxiosError,
    {
      operation: 'attach' | 'detach'
    } & VersionLabelToggleParams,
    {
      previousEntities: CompactMetricOrList[]
      cacheKey: QueryKey
    }
  >(
    metricsAndListsMutationsOnlyKeys.labels(params),
    ({ operation, ...params }) =>
      operation === 'attach'
        ? attachLabelToEntity(params)
        : detachLabelFromEntity(params),
    {
      onMutate: async ({
        labelId,
        operation,
        versionId,
        entityType,
        entityId,
      }) => {
        const cacheKey =
          entityType === 'list'
            ? metricsAndListsCacheKeys.lists(versionId, false)
            : metricsAndListsCacheKeys.metrics(versionId, false)

        await queryClient.cancelQueries(cacheKey)

        const previousEntities =
          queryClient.getQueryData<CompactMetricOrList[]>(cacheKey) || []

        queryClient.setQueryData<CompactMetricOrList[]>(cacheKey, (prev) => [
          ...(prev || []).map((i) => {
            if (i.id !== entityId) {
              return i
            }

            const label_ids = i.info?.label_ids || []

            return {
              ...i,
              info: {
                ...i.info,
                label_ids:
                  operation === 'attach'
                    ? [...label_ids, labelId]
                    : label_ids.filter((id) => id !== labelId),
              },
            }
          }),
        ])

        return { previousEntities, cacheKey }
      },
      onError: (err, v, ctx) => {
        if (ctx?.cacheKey) {
          // Reset optimistic update
          queryClient.setQueryData(ctx.cacheKey, ctx.previousEntities || [])
        }

        console.error(err)
        toast.error(
          `Failed to save version labels. Error: ${err?.code || 'unknown'}`,
        )
      },
      async onSettled(data, err, v, ctx) {
        if (ctx?.cacheKey) {
          return queryClient.invalidateQueries({
            queryKey: ctx?.cacheKey,
          })
        }
      },
    },
  )

  return result
}
