import type { Maybe } from '@fintastic/shared/util/types'
import { Filter, FilterValue, FilterValuesModel } from '../types'
import { isFilterValueEmpty } from './filter-value-utils'
import { cloneDeep, isEmpty } from 'lodash'

export const addValueToModel = (
  model: FilterValuesModel,
  filter: Filter,
  timeDimensionValueId: Maybe<string>,
  value?: FilterValue,
): FilterValuesModel => {
  const nextModel = cloneDeep(model)
  const timeKey = timeDimensionValueId ?? NO_TIME_SPREAD_FILTER

  if (isFilterValueEmpty(filter, value)) {
    if (nextModel[filter.id]?.[timeKey]) {
      delete nextModel[filter.id]?.[timeKey]

      if (isEmpty(nextModel[filter.id])) {
        delete nextModel[filter.id]
      }
    }

    return nextModel
  }

  if (!nextModel[filter.id]) {
    nextModel[filter.id] = {}
  }

  nextModel[filter.id]![timeKey] = value
  return nextModel
}

export const getValueFromTheModel = <T extends FilterValue = FilterValue>(
  model: FilterValuesModel,
  filterId: string,
  timeDimensionValueId: Maybe<string>,
): T | undefined => {
  const timeKey = timeDimensionValueId ?? NO_TIME_SPREAD_FILTER

  return model[filterId]?.[timeKey] as T
}

export const NO_TIME_SPREAD_FILTER = 'all'
