export const BLANK_VALUE_UI_REPRESENTATION = '-'
export const BLANK_VALUE = Symbol('Blank value')

export const isRawBlankValue = (value: unknown): value is null => value === null

export const containsBlankValue = (
  value: unknown,
): value is typeof BLANK_VALUE => value === BLANK_VALUE

export const isRowDataFullyBlank = <TData extends Record<string, unknown>>(
  rowData: TData,
  fieldsToCheck?: string[],
): boolean => {
  const keys = fieldsToCheck ?? Object.keys(rowData)
  return keys
    .map((k) => {
      const v = rowData[k]
      if (containsBlankValue(v) || isRawBlankValue(v)) {
        return null
      }
      return v
    })
    .every(isRawBlankValue)
}
