import {
  CalendarDatePickerConfig,
  CalendarDatePickerConfigItem,
  PeriodResolution,
} from '../types'
import { Maybe } from '@fintastic/shared/util/types'
import { RESOLUTIONS_ORDERED, RESOLUTIONS_ORDERED_MAP } from './const'
import { isResolutionHigherOrEqual } from './resolutions'
import { compact } from 'lodash'

export const closestDimensionByResolution = (
  lowestResolution: PeriodResolution,
  calendarConfig: CalendarDatePickerConfig,
): Maybe<CalendarDatePickerConfigItem> => {
  const dimByResolutionMap = calendarDimensionsByResolutionMap(calendarConfig)

  const activeResolution =
    RESOLUTIONS_ORDERED.find(
      (resolution) =>
        isResolutionHigherOrEqual(resolution, lowestResolution) &&
        !!dimByResolutionMap[resolution],
    ) || ''

  const fallback = compact(Object.values(dimByResolutionMap))[0] ?? null

  const activeResolutionValue =
    activeResolution && dimByResolutionMap?.[activeResolution]

  if (activeResolutionValue) {
    return activeResolutionValue
  }

  return fallback
}

export const leastGranularDimensionInEntities = (
  calendarConfig: CalendarDatePickerConfig,
): Maybe<CalendarDatePickerConfigItem> => {
  const dimByResolutionMap = calendarDimensionsByResolutionMap(calendarConfig)

  const activeResolution =
    RESOLUTIONS_ORDERED.findLast(
      (resolution) => !!dimByResolutionMap[resolution],
    ) || ''

  return activeResolution ? dimByResolutionMap[activeResolution] ?? null : null
}

export const calendarDimensionsByResolutionMap = (
  calendarConfig: CalendarDatePickerConfig,
): Partial<Record<PeriodResolution, CalendarDatePickerConfigItem>> =>
  Object.fromEntries(
    Object.keys(RESOLUTIONS_ORDERED_MAP).map(
      (resolution) =>
        [
          resolution,
          calendarConfig.find((i) => i.resolution === resolution),
        ] as const,
    ),
  )
