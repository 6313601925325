import {
  ListDoesNotExistError,
  ListGridError,
  NetworkError,
  VersionsAreNotSelectedError,
  RestrictedByPermissionsError,
  UnknownError,
} from './errors'
import { Maybe } from '@fintastic/shared/util/types'
import { AxiosError } from 'axios'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { metricEmptyBecauseOfPermissions } from '@fintastic/web/feature/metrics-and-lists'
import {
  UnsupportedGranularityAggregationError,
  isUnsupportedGranularityAggregationErrorResponse,
} from '@fintastic/web/util/period-selector'

export const getListGridError = (
  versionId: Maybe<string>,
  errors: {
    listItselfError: Maybe<AxiosError | Error>
    versionsApiError: Maybe<AxiosError | Error>
    columnsError: Maybe<AxiosError | Error>
  },
  columnsData: Maybe<Metric[]>,
  emptiness: {
    listItselfOriginallyEmpty: boolean
  },
): Maybe<ListGridError> => {
  const { listItselfError, versionsApiError, columnsError } = errors
  const { listItselfOriginallyEmpty } = emptiness

  if (!versionId) {
    return new VersionsAreNotSelectedError()
  }

  if (versionsApiError) {
    console.error(versionsApiError)
    return new NetworkError()
  }

  if (listItselfError || columnsError) {
    const error = queryErrorToGridError(
      (listItselfError || columnsError) as AxiosError | Error,
    )
    if (error) {
      return error
    }
  }

  if (columnsData) {
    const error = columnsDataToGridError(columnsData, listItselfOriginallyEmpty)
    if (error) {
      return error
    }
  }

  return null
}

const queryErrorToGridError = (
  queryError: AxiosError | Error,
): Maybe<ListGridError> => {
  if ('isAxiosError' in queryError && queryError.isAxiosError) {
    return axiosErrorToGridError(queryError)
  }
  return errorToGridError(queryError)
}

const columnsDataToGridError = (
  columnsData: Metric[],
  listItselfOriginallyEmpty: boolean,
): Maybe<ListGridError> => {
  if (columnsData.length === 0 || listItselfOriginallyEmpty) {
    return null
  }
  const allColumnsMissIndexes = columnsData.every(
    (c) => !!metricEmptyBecauseOfPermissions(c),
  )
  if (allColumnsMissIndexes) {
    return new RestrictedByPermissionsError()
  }
  return null
}

const axiosErrorToGridError = (axiosError: AxiosError): ListGridError => {
  if (axiosError.response?.status === 404) {
    return new ListDoesNotExistError()
  }

  if (axiosError.response?.status === 403) {
    return new RestrictedByPermissionsError()
  }

  if (isUnsupportedGranularityAggregationErrorResponse(axiosError)) {
    return new UnsupportedGranularityAggregationError(axiosError)
  }

  return new NetworkError()
}

const errorToGridError = (error: Error): ListGridError =>
  new UnknownError(error)
