import React, { useCallback, useMemo } from 'react'
import { FilterValue } from '@fintastic/web/util/filters'
import { DatePicker } from '@mui/x-date-pickers'
import type { Maybe } from '@fintastic/shared/util/types'
import { formatDate } from './format-date'
import dayjs, { Dayjs } from 'dayjs'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'
import { CalendarIcon } from '@fintastic/shared/ui/icons'

export const NormalDateField: React.FC<NormalDateFieldProps> = ({
  filterValue,
  onChange,
}) => {
  const handleChange = useCallback(
    (value: Maybe<Dayjs>) => {
      onChange({
        value: formatDate(value),
        operator: filterValue.operator ?? 'equal',
      })
    },
    [filterValue.operator, onChange],
  )

  const parsedDate = useMemo(
    () =>
      filterValue.value
        ? dayjs(filterValue.value, DEFAULT_DAYJS_DATE_FORMAT)
        : null,
    [filterValue.value],
  )

  return (
    <DatePicker<Dayjs>
      value={parsedDate}
      onChange={handleChange}
      slotProps={{ textField: { size: 'small' } }}
      sx={{ width: '100%' }}
      slots={{
        openPickerIcon: CalendarIcon,
      }}
      format={DEFAULT_DAYJS_DATE_FORMAT}
    />
  )
}

export type NormalDateFieldProps = {
  filterValue: Partial<FilterValue<string | ''>>
  onChange: (nextValue: FilterValue<string | ''>) => void
}
