import { MetricDataWithVersionId } from '../../types'
import { Dimension, Maybe, toMaybe } from '@fintastic/shared/util/types'
import { VersionEntitiesWrapper } from '@fintastic/web/util/versions'
import { DimensionId } from '@fintastic/web/util/dimensions'

// @todo add tests
export function dimensionsLabelMapFromMetricDataList(
  dataList: MetricDataWithVersionId[],
  entities?: Record<string, Maybe<VersionEntitiesWrapper>>,
) {
  const dimensions: Record<DimensionId, Omit<Dimension, 'id'>> = {}
  dataList.forEach((versionMetric) => {
    const versionEntities = toMaybe(entities?.[versionMetric.version])
    const metric = versionMetric.metric
    if (metric === null) {
      return
    }

    metric.data.indexes.forEach((dimId) => {
      // @todo remove dimensions metadata usage
      const localDimension = metric.metadata.dimensions.find(
        (d) => d.id === dimId && d.type === 'Range' && d.values !== undefined,
      )
      const versionDimension = versionEntities
        ?.findDimensionById(dimId)
        ?.unwrap()
      dimensions[dimId] = {
        type: localDimension?.type || versionDimension?.type || 'Category',
        values: {
          ...localDimension?.values,
          ...(versionDimension && 'values' in versionDimension
            ? versionDimension.values
            : {}),
        },
        label: versionDimension?.label || dimId,
      }
    })
  })

  return dimensions
}
