import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { StyledAddLinesGridRoot } from './AddLinesGrid.styled'
import { AddLinesStateValuesRecord } from '@fintastic/web/feature/list-add-lines'
import {
  AgGridThemeFintasticWrapper,
  getFintasticAgIconsRecord,
} from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { AgGridReact } from 'ag-grid-react'
import {
  agGridComponentsMap,
  useCopyFormattedValue,
} from '@fintastic/shared/ui/ag-grid'
import {
  GridReadyEvent,
  RowSelectedEvent,
} from 'ag-grid-community/dist/lib/events'
import { Maybe } from '@fintastic/shared/util/types'
import {
  ColumnApi,
  GridApi,
  type ProcessDataFromClipboardParams,
} from 'ag-grid-community'
import { useSelectedCellAggregationAgGridContext } from '@fintastic/web/feature/selected-cell-aggregation'
import {
  ColumnColor,
  MetricGridRow,
} from '@fintastic/web/util/metrics-and-lists'
import {
  GridColumnColorsCssWrapper,
  useProcessClipboardPaste,
} from '@fintastic/web/feature/metrics-and-lists'
import { AddLinesColDefs } from '../../columns'
import { LinearProgress } from '@mui/material'

const icons = getFintasticAgIconsRecord()

export type AddLinesGridProps = {
  data: AddLinesStateValuesRecord[]
  setSelection: (ids: string[]) => void
  busy: boolean
  colDefs: AddLinesColDefs
  showValidationColumn: boolean
  columnColors?: ColumnColor[]
  periods?: string[]
}

export type AddLinesHandlerProps = {
  commitEdit: () => void
}

export const AddLinesGrid = forwardRef<AddLinesHandlerProps, AddLinesGridProps>(
  (props, ref) => {
    const {
      data,
      setSelection,
      colDefs,
      busy,
      showValidationColumn,
      columnColors,
      periods,
    } = props

    const containerRef = useRef<Maybe<HTMLDivElement>>(null)
    const gridApiRef = useRef<Maybe<GridApi>>(null)
    const gridColumnRef = useRef<Maybe<ColumnApi>>(null)

    const agContext = useSelectedCellAggregationAgGridContext()

    const onGridReady = useCallback(
      (event: GridReadyEvent) => {
        gridApiRef.current = event.api
        gridColumnRef.current = event.columnApi
        gridColumnRef.current.setColumnVisible('_valid', showValidationColumn) //In that case we hide it
      },
      [showValidationColumn],
    )

    useImperativeHandle(ref, () => ({
      commitEdit() {
        gridApiRef.current?.stopEditing(false)
      },
    }))

    const handleRowSelected = useCallback(
      (event: RowSelectedEvent<MetricGridRow>) => {
        const currentSelection =
          event.api.getSelectedNodes().map((n) => n.data?._rowId || '') || []

        setSelection(currentSelection)
      },
      [setSelection],
    )

    useEffect(() => {
      if (busy) {
        gridApiRef.current?.stopEditing(true)
        gridApiRef.current?.showLoadingOverlay()
      } else {
        gridApiRef.current?.hideOverlay()
      }
    }, [busy])

    useEffect(() => {
      gridColumnRef.current?.setColumnVisible('_valid', showValidationColumn) //In that case we hide it
    }, [showValidationColumn, colDefs])

    const handleProcessDataFromClipboard = useProcessClipboardPaste()

    const handlePasteClipboard = useCallback(
      (params: ProcessDataFromClipboardParams<MetricGridRow>) =>
        handleProcessDataFromClipboard(params, 'list'),
      [handleProcessDataFromClipboard],
    )

    const handleCopyFormattedValue = useCopyFormattedValue()

    return (
      <StyledAddLinesGridRoot>
        {busy && (
          <LinearProgress
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 2,
            }}
          />
        )}
        <AgGridThemeFintasticWrapper
          ref={containerRef}
          sx={{
            flex: 1,
            width: '100%',
            height: '100%',
          }}
          themeOptions={{
            inscribedShape: true,
          }}
        >
          {({ defaultGridProps }) => (
            <GridColumnColorsCssWrapper
              columnColors={columnColors || []}
              periods={periods || []}
            >
              <AgGridReact<MetricGridRow>
                {...defaultGridProps}
                icons={icons}
                getRowId={(node) => node.data._rowId}
                suppressMovableColumns={true}
                rowSelection="multiple"
                singleClickEdit
                suppressRowClickSelection
                // enterMovesDownAfterEdit
                overlayLoadingTemplate="<div class='add-lines-loading'></div>"
                onRowSelected={handleRowSelected}
                animateRows
                suppressAggFuncInHeader
                context={agContext}
                rowData={data}
                columnDefs={colDefs}
                components={agGridComponentsMap}
                onGridReady={onGridReady}
                enableRangeSelection={true}
                suppressExcelExport={true}
                suppressCsvExport={true}
                suppressClipboardPaste={false}
                processCellForClipboard={handleCopyFormattedValue}
                processDataFromClipboard={handlePasteClipboard}
              />
            </GridColumnColorsCssWrapper>
          )}
        </AgGridThemeFintasticWrapper>
      </StyledAddLinesGridRoot>
    )
  },
)
