import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { Maybe, Uuid } from '@fintastic/shared/util/types'
import {
  switchVersionUserLockers,
  useLoadVersionsList,
  useVersionEntitiesContextValue,
  useVersionsListMap,
  useVersionUserLocks,
  VersionEntitiesContext,
} from '@fintastic/web/data-access/versions'
import {
  BaseGridContextProp,
  SetGridSizeCallback,
} from '@fintastic/shared/ui/grid-framework'
import { MetricGrid } from './MetricGrid'
import { useMetricInVersions } from '../../hooks/useMetricInVersions'
import {
  HandleMetricUpdateCallbackParams,
  MetricDataWithVersionId,
  MetricGridApi,
  MetricGridConnectorSettingsPanelProp,
} from './types'
import { useReadonly } from './features/readonly/useReadonly'
import { useUpdateMetricHandler } from './features/data-editing/useUpdateMetricHandler'
import { useMetricGridDataProps } from './features/props-helpers/useMetricGridDataProps'
import { useWeightedAverageMetrics } from './features/weighted-average/useWeightedAverageMetrics'
import { useHasMetricFormula } from './features/formulas/useHasMetricFormula'
import { useChangeIntentQueue } from '../../hooks/useChangeIntentQueue'
import { SettingsPanelContextsWrapper } from './features/settings/SettingsPanelContextsWrapper'
import { useSettingsPanelProps } from './features/settings/useSettingsPanelProps'
import {
  MetricWrapper,
  useEditingMetric,
} from '@fintastic/web/data-access/metrics-and-lists'
import { useIsSettingsEditingActive } from './features/settings/useIsSettingsEditingActive'
import { useSettingsRouterLocation } from './features/settings/useSettingsRouterLocation'
import { useAggregatedTimeCellEditing } from '../shared/features/aggregated-time-cell-editing/useAggregatedTimeCellEditing'
import { AggregatedTimeCellEditorContext } from '../shared/features/aggregated-time-cell-editing/cell-editor-context'
import { ColumnColorsGridProps } from '../column-color-selector/types'
import uniq from 'lodash/uniq'
import { useQueryClient } from 'react-query'
import { getMetricGridError } from './features/errors/getMetricGridError'
import { useSetBaseTimeDimensionEffect } from '../shared/features/base-time-dimension/useSetBaseTimeDimensionEffect'
import { compact } from 'lodash'
import { useSetBaseTimeDimensionFromErrorEffect } from '@fintastic/web/data-access/base-time-dimension'
import { SelectedCellAggregationProvider } from '@fintastic/web/util/selected-cell-aggregation'
import { DiffMode, DiffPart } from '@fintastic/web/util/versions'
import { useCellLevelUpdate } from './features/data-editing/cell-level-update'
import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'

export type MetricGridConnectorProps = {
  metricId: Uuid
  versions: string[]
  diffs: Array<[string, string, DiffMode]>
  context?: BaseGridContextProp
  title?: string
  enableGrouping?: boolean
  enableGridReset?: boolean
  setGridSizeCallback?: SetGridSizeCallback
  settingsPanel?: MetricGridConnectorSettingsPanelProp
  isVersionPage?: boolean
  isCreationDummy?: boolean
  isLiveActuals?: boolean
  periodSelectorComponent: React.ReactNode
} & Partial<ColumnColorsGridProps>

export const MetricGridConnector: React.FC<MetricGridConnectorProps> = memo(
  ({
    metricId,
    versions,
    diffs,
    context,
    title,
    enableGrouping,
    enableGridReset,
    setGridSizeCallback,
    isLiveActuals,
    settingsPanel,
    isVersionPage,
    columnColors,
    enableColumnColors,
    handleUpdateColumnColors,
    isCreationDummy,
    periodSelectorComponent,
  }) => {
    const queryClient = useQueryClient()
    const isSettingsEditingActive = useIsSettingsEditingActive(metricId)
    const loadData = !isCreationDummy && !isSettingsEditingActive
    const metricGridRef = useRef<Maybe<MetricGridApi>>(null)

    const aggregatedCellEditing = useAggregatedTimeCellEditing()

    const versionsListQuery = useLoadVersionsList({
      versionsIds: versions,
      withLiveActuals: true,
      showArchived: true,
    })

    const versionsMetadata = useVersionsListMap(
      useMemo(() => versionsListQuery.data || [], [versionsListQuery.data]),
    )

    const periodSelection = usePeriodSelectorContext()
    const metricInVersionsQuery = useMetricInVersions(
      versions,
      metricId,
      loadData,
      periodSelection,
    )

    const existingMetrics = useMemo(
      () =>
        metricInVersionsQuery.metricsWithVersion.filter(
          (versionMetric) => !!versionMetric.metric,
        ),
      [metricInVersionsQuery.metricsWithVersion],
    )

    const editingMetric = useEditingMetric()

    const settingsPanelProps = useSettingsPanelProps(
      versions[0] || null,
      metricId,
      settingsPanel || null,
    )
    const settingsRouterLocation = useSettingsRouterLocation(
      settingsPanelProps.editingActive,
    )

    const usingMetrics = useMemo<MetricDataWithVersionId[]>(() => {
      if (settingsPanelProps.editingActive) {
        return [
          {
            version: versions[0],
            metric: editingMetric
              ? {
                  ...editingMetric,
                  data:
                    !settingsPanelProps.isNewMetric &&
                    editingMetric.source === 'calculated'
                      ? existingMetrics[0]?.metric?.data || editingMetric.data
                      : editingMetric.data,
                }
              : null,
          },
        ]
      }
      return existingMetrics
    }, [
      settingsPanelProps.editingActive,
      settingsPanelProps.isNewMetric,
      existingMetrics,
      versions,
      editingMetric,
    ])

    const cellLevelUpdateApi = useCellLevelUpdate(versions, metricId, {
      updateCellDataValue: useCallback((payload) => {
        metricGridRef.current?.updateCellDataValue(payload)
      }, []),
      timeDimension: usingMetrics[0]?.metric
        ? new MetricWrapper(usingMetrics[0].metric).timeDimension()
        : null,
      periodSelection,
      maskedValueMask: usingMetrics[0]?.metric?.metadata.value?.mask || '',
    })

    const weightMetrics = useWeightedAverageMetrics(usingMetrics)

    const entitiesContextValue = useVersionEntitiesContextValue(versions)

    const dataProps = useMetricGridDataProps(
      metricId,
      usingMetrics,
      versionsMetadata,
      weightMetrics.isLoading ? undefined : weightMetrics.weightMetrics,
      entitiesContextValue.entities,
    )

    const handleUpdate = useUpdateMetricHandler(
      usingMetrics[0]?.metric,
      {
        dataFillStrategy: aggregatedCellEditing.action,
      },
      {
        updateCellData: (payload) => {
          try {
            metricGridRef?.current?.updateCellDataValue(payload)
          } catch (e) {
            console.error(e)
          }
        },
        awaitEventId: cellLevelUpdateApi.awaitEventId,
      },
    )

    const hasFormulas = useHasMetricFormula(dataProps.data)

    const readonly = useReadonly({
      versions,
      versionsLoading: versionsListQuery.isLoading,
      metricsLoading:
        metricInVersionsQuery.isLoading || weightMetrics.isLoading,
      settingsEditingActive: isSettingsEditingActive,
    })

    const handleUpdateQueued = useChangeIntentQueue(handleUpdate)

    const error = useMemo(
      () =>
        getMetricGridError(versions, {
          metrics: metricInVersionsQuery.metricsWithVersion,
        }),
      [metricInVersionsQuery.metricsWithVersion, versions],
    )

    const updateHandler = useCallback(
      (intents: HandleMetricUpdateCallbackParams[]) => {
        // @todo: Should we somehow handle non-smallest aggregation?
        switchVersionUserLockers(
          queryClient,
          uniq(intents.map((i) => i.versionId)),
          'calc',
        )

        return handleUpdateQueued(intents)
      },
      [handleUpdateQueued, queryClient],
    )

    const mappedDiffs = useMemo(
      () =>
        diffs.filter(
          (diff) =>
            dataProps.data.find(
              (d) => d.versionId === diff[DiffPart.firstVersion],
            ) &&
            dataProps.data.find(
              (d) => d.versionId === diff[DiffPart.secondVersion],
            ),
        ),
      [diffs, dataProps.data],
    )

    useEffect(() => {
      if (!loadData ? null : error) {
        setGridSizeCallback?.({
          rows: 0,
          rowHeight: 0,
          headerHeight: 0,
        })
      }
    }, [error, loadData, setGridSizeCallback])

    useSetBaseTimeDimensionEffect(
      useMemo(
        () =>
          compact(
            usingMetrics.map((i) => i.metric?.metadata.base_time_dimension_id),
          ),
        [usingMetrics],
      ),
    )

    useSetBaseTimeDimensionFromErrorEffect(error)

    const versionUserLocks = useVersionUserLocks(versions)

    return (
      <SelectedCellAggregationProvider entityIdForPersistentSettings={metricId}>
        <AggregatedTimeCellEditorContext.Provider value={aggregatedCellEditing}>
          <SettingsPanelContextsWrapper
            versionId={versions[0] || null}
            editingAllowed={
              settingsPanelProps.editingActive &&
              settingsPanelProps.editingAllowed
            }
            metric={usingMetrics[0]?.metric}
            location={settingsRouterLocation}
          >
            <VersionEntitiesContext.Provider value={entitiesContextValue}>
              <MetricGrid
                ref={metricGridRef}
                hasFormulas={hasFormulas}
                readonly={readonly}
                context={context}
                isLoading={
                  metricInVersionsQuery.isLoading ||
                  weightMetrics.isLoading ||
                  entitiesContextValue.isLoading
                }
                onUpdate={updateHandler}
                title={title}
                diffs={mappedDiffs.length > 0 ? mappedDiffs : undefined}
                enableGrouping={enableGrouping}
                enableGridReset={enableGridReset}
                setGridSizeCallback={setGridSizeCallback}
                isLiveActuals={isLiveActuals}
                settingsPanel={settingsPanelProps}
                isVersionPage={isVersionPage}
                columnColors={columnColors}
                enableColumnColors={enableColumnColors}
                handleUpdateColumnColors={handleUpdateColumnColors}
                error={!loadData ? null : error}
                metricId={metricId}
                versions={versions}
                periodSelectorComponent={periodSelectorComponent}
                versionUserLocks={versionUserLocks}
                {...dataProps}
              />
            </VersionEntitiesContext.Provider>
          </SettingsPanelContextsWrapper>
        </AggregatedTimeCellEditorContext.Provider>
      </SelectedCellAggregationProvider>
    )
  },
)
