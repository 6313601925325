import type {
  DimensionId,
  DimensionLabelMap,
  Maybe,
  RollUpFunction,
} from '@fintastic/shared/util/types'
import type { LegacyListGridColumnData } from '@fintastic/web/feature/legacy-list-grid'
import type {
  CellRange,
  CellValueChangedEvent,
  Column,
  RowNode,
} from 'ag-grid-community'
import type {
  MetricMetadata,
  MetricDisplaySettings,
  MetricData,
} from '@fintastic/web/util/metrics-and-lists'
import type {
  HandleMetricUpdateCallbackChange,
  HandleMetricUpdateCallbackParams,
} from '../components/metric-grid/types'
import type {
  BaseGridOnCellValueChangedMetadata,
  BaseGridRowData,
} from '@fintastic/shared/ui/grid-framework'
import {
  forEachCellInRangeList,
  isCellRangesContainMoreThanOneCell,
} from '@fintastic/shared/util/ag-grid'
import {
  MetricOrListSource,
  MetricDataValue,
  MetricMetadataWithoutDimensions,
} from '@fintastic/web/util/metrics-and-lists'
import { MetricGridRow } from '../components/metric-grid/types'
import {
  createFieldKey,
  destructureField,
} from '@fintastic/web/util/metrics-and-lists'
import { createWeightedAverageWeightFieldKey } from '../components/metric-grid/features/weighted-average/createWeightedAverageWeightFieldKey'
import { MASKED_VALUE } from '@fintastic/web/util/blanks-and-masked'
import { expandMetricData } from '@fintastic/web/util/metrics-and-lists'
import { VersionEntities } from '@fintastic/web/util/versions'
import { idLooksLikeDimension } from '@fintastic/web/util/dimensions'

export type VersionMetric = {
  metricId: string
  metricLabel: string
  versionId: string
  versionLabel: string
  metricData: MetricData
  metricSource: MetricOrListSource
  versionEditable: boolean
  formula?: Maybe<string>
  metricMetadata: MetricMetadata
  displaySettings: MetricDisplaySettings
}

export type ListMetric = Omit<
  VersionMetric,
  'metricData' | 'metricMetadata'
> & {
  dataType: LegacyListGridColumnData
  dimensionId?: DimensionId
  rollUpFunction: RollUpFunction
  metricData: Maybe<MetricData>
  metricMetadata: MetricMetadata | MetricMetadataWithoutDimensions
}

export type RowsMap = Record<string, Omit<MetricGridRow, '_rowId'>>

// @todo add tests
function rowsMapToRowsList(rowsMap: RowsMap): MetricGridRow[] {
  return Object.entries(rowsMap).map(([k, v]) => ({ ...v, _rowId: k }))
}

// @todo add tests
function mergeRowsMaps(mainMap: RowsMap, mergingMap: RowsMap): RowsMap {
  return Object.fromEntries(
    [...Object.keys(mainMap), ...Object.keys(mergingMap)].map((key) => [
      key,
      {
        ...mainMap[key],
        ...mergingMap[key],
      },
    ]),
  )
}

// @todo update tests
export const mergeMetricsToFlatRows = (
  data: (VersionMetric | ListMetric)[],
  entities: Maybe<
    Array<{
      entities?: VersionEntities
      versionId: string
    }>
  > = [],
  periodDim = '',
  weightedAverageData?: (VersionMetric | ListMetric)[],
): MetricGridRow[] => {
  const mainRowsMap = expandMetricData(
    data,
    periodDim,
    createFieldKey,
    entities,
  )

  if (!weightedAverageData || weightedAverageData.length === 0) {
    return rowsMapToRowsList(mainRowsMap)
  }

  const weightsRowsMap = expandMetricData(
    weightedAverageData,
    periodDim,
    createWeightedAverageWeightFieldKey,
    entities,
  )

  return rowsMapToRowsList(mergeRowsMaps(mainRowsMap, weightsRowsMap))
}

export function hideCompleteBlankRows(
  rowData: MetricGridRow[],
): MetricGridRow[] {
  if (!rowData.length) {
    return rowData
  }

  return rowData.filter(
    (row) =>
      !!Object.entries(row).find(
        ([key, value]) =>
          key !== '_rowId' && value !== null && !idLooksLikeDimension(key),
      ),
  )
}

// @todo @ipomazkin-fin add unit tests
function extractRowDimensions<TData extends BaseGridRowData = BaseGridRowData>(
  data: TData,
  dimensions: DimensionId[],
): Record<DimensionId, string> {
  return Object.fromEntries(
    dimensions
      .map((dimensionId) => [dimensionId, data?.[dimensionId]])
      .filter(([_, value]) => value !== undefined),
  )
}

/**
 * Mutates the 'mutDims' argument
 */
// @todo @ipomazkin-fin add unit tests
function addDimension(
  mutDims: Record<DimensionId, string>,
  dimKey?: Maybe<DimensionId>,
  dimValue?: Maybe<string>,
): Record<DimensionId, string> {
  if (
    dimKey === undefined ||
    dimKey === null ||
    dimValue === null ||
    dimValue === undefined
  ) {
    return mutDims
  }
  // eslint-disable-next-line no-param-reassign
  mutDims[dimKey] = dimValue
  return mutDims
}

export type FilterCellForChangeCallbackCell<
  TData extends BaseGridRowData = BaseGridRowData,
> = {
  row: RowNode<TData>
  column: Column
  dimensions: Record<string, string>
  timeDimensionValue?: string
  versionId: string
  metricId: string
}

export type FilterCellForChangeCallback<
  TData extends BaseGridRowData = BaseGridRowData,
  TValue extends MetricDataValue = MetricDataValue,
> = (
  originalEvent: CellValueChangedEvent<TData, TValue>,
  originalEventCell: FilterCellForChangeCallbackCell<TData>,
  checkingCell: FilterCellForChangeCallbackCell<TData>,
) => boolean

// @todo @ipomazkin-fin add unit tests
function extractChangesFromEventAndCellRanges<
  TData extends BaseGridRowData = BaseGridRowData,
  TValue extends MetricDataValue = MetricDataValue,
>(
  nonTimeDimensions: DimensionId[],
  timeDimension: DimensionId | undefined,
  event: CellValueChangedEvent<TData, TValue>,
  cellRanges: CellRange[],
  filterCellCallback?: FilterCellForChangeCallback<TData, TValue>,
  isTimeDimensionDimRequired = true,
): HandleMetricUpdateCallbackParams[] {
  const { api, newValue } = event
  const changesPerVersion: Record<string, HandleMetricUpdateCallbackChange[]> =
    {}
  const model = api.getModel()

  /**
   * Mutates the 'mutChanges' argument
   */
  const addChange = (
    mutChanges: Record<string, HandleMetricUpdateCallbackChange[]>,
    versionId: string,
    metricId: string,
    dimensions: Record<string, string>,
    value: TValue,
  ) => {
    if (!mutChanges[versionId]) {
      // eslint-disable-next-line no-param-reassign
      mutChanges[versionId] = []
    }

    mutChanges[versionId].push({
      metricId,
      dimensions,
      value,
    })
  }

  const editingCellRowDimensions = extractRowDimensions(
    event.data,
    nonTimeDimensions,
  )
  const [editingCellVersionId, editingCellMetricId, editingCellPeriod] =
    destructureField(event.column.getColDef().colId || '')

  forEachCellInRangeList<TData>(cellRanges, model, (row, column) => {
    if (row.data === undefined) {
      return
    }

    const rowDimensions = extractRowDimensions(row.data, nonTimeDimensions)
    const [versionId, metricId, period] = destructureField(
      column.getColDef().colId || '',
    )

    // filter out by wrong column ids
    if (
      versionId === undefined ||
      metricId === undefined ||
      (isTimeDimensionDimRequired
        ? timeDimension !== undefined && period === undefined
        : false)
    ) {
      return
    }

    // filter out non-editable columns
    if (!column.isCellEditable(row)) {
      return
    }

    // filter out by provided custom callback
    if (
      filterCellCallback &&
      !filterCellCallback(
        event,
        {
          row: event.node,
          column: event.column,
          dimensions: editingCellRowDimensions,
          timeDimensionValue: editingCellPeriod,
          versionId: editingCellVersionId,
          metricId: editingCellMetricId,
        },
        {
          row,
          column,
          dimensions: rowDimensions,
          timeDimensionValue: period,
          versionId,
          metricId,
        },
      )
    ) {
      return
    }

    addChange(
      changesPerVersion,
      versionId,
      metricId,
      addDimension({ ...rowDimensions }, timeDimension, period),
      newValue,
    )
  })

  return Object.entries(changesPerVersion).map(([versionId, changes]) => ({
    versionId,
    changes,
  }))
}

// @todo @ipomazkin-fin add unit tests
export const extractChangeFromEvent = <
  TData extends BaseGridRowData = BaseGridRowData,
  TValue extends MetricDataValue = MetricDataValue,
>(
  nonTimeDimensions: DimensionId[],
  timeDimension: DimensionId | undefined,
  params: CellValueChangedEvent,
  metadata?: BaseGridOnCellValueChangedMetadata,
  filterCellCallback?: FilterCellForChangeCallback<TData, TValue>,
  isTimeDimensionDimRequired = true,
): HandleMetricUpdateCallbackParams[] => {
  if (
    metadata?.cellRangesAtEditingStarted?.length &&
    isCellRangesContainMoreThanOneCell(metadata.cellRangesAtEditingStarted)
  ) {
    return extractChangesFromEventAndCellRanges(
      nonTimeDimensions,
      timeDimension,
      params,
      metadata.cellRangesAtEditingStarted,
      filterCellCallback,
      isTimeDimensionDimRequired,
    )
  } else {
    const dimensions = extractRowDimensions(params.data, nonTimeDimensions)
    const [versionId, metricId, period] = destructureField(
      params.colDef?.colId || '',
    )
    
    return [
      {
        versionId,
        changes: [
          {
            metricId,
            dimensions: addDimension(dimensions, timeDimension, period),
            value: params.newValue,
            oldValue: params.oldValue,
          },
        ],
      },
    ]
  }
}

export const extractChangesFromPopulate = <
  TData extends BaseGridRowData = BaseGridRowData,
>(
  versionId: string,
  metricId: string,
  value: unknown,
  data: TData,
  nonTimeDimensions: DimensionId[],
  timeDimension: DimensionId | undefined,
  periods: string[],
): HandleMetricUpdateCallbackParams[] => [
  {
    versionId,
    changes: periods
      .filter(
        (p) => data[createFieldKey(versionId, metricId, p)] !== MASKED_VALUE,
      )
      .map((period) => ({
        metricId,
        dimensions: addDimension(
          extractRowDimensions(data, nonTimeDimensions),
          timeDimension,
          period,
        ),
        value: value as MetricDataValue,
      })),
  },
]

export const removeTimeDimension = (dimensions: DimensionLabelMap) => {
  const nonTimeDimensions = Object.keys(dimensions).filter(
    (dimensionId) => dimensions[dimensionId].type !== 'Time',
  )

  const timeDimension = Object.keys(dimensions).find(
    (dimensionId) => dimensions[dimensionId].type === 'Time',
  )

  return { nonTimeDimensions, timeDimension }
}
