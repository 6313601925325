import { PanelDragHandle } from '@fintastic/shared/ui/panel-framework'
import { Tooltip } from '@mui/material'
import { TopBar } from '@fintastic/shared/ui/grid-framework'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Uuid } from '@fintastic/shared/util/types'

import {
  getMetricGridError,
  MetricFormulaEditorWrapper,
  useHasMetricFormula,
  useMetricGridDataProps,
  useMetricInVersions,
  useSetBaseTimeDimensionEffect,
  useWeightedAverageMetrics,
} from '@fintastic/web/feature/metrics-and-lists'
import {
  useBaseTimeDimensionCurrentValue,
  useSetBaseTimeDimensionFromErrorEffect,
} from '@fintastic/web/data-access/base-time-dimension'
import {
  StyledMetricChartFormulas,
  StyledMetricChartRoot,
} from './MetricChartContainer.styled'
import { FormulaButton } from '@fintastic/web/feature/formulas'
import {
  useLoadVersionEntities,
  useLoadVersionsList,
  useVersionEntitiesContextValue,
  useVersionsListMap,
} from '@fintastic/web/data-access/versions'
import { compact } from 'lodash'
import { MetricChartSidePanel } from '../SidePanel/MetricChartSidePanel'
import { ChartDebugModePanel } from '../ChartDebugPanel/ChartDebugModePanel'
import {
  ChartDisplayMode,
  ChartDisplaySettings,
  ChartSettings,
  MetricChartDimensions,
} from '../../types'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useChartData } from '../../hooks/useChartData'
import { useLocalChartDimensions } from '../../hooks/useLocalChartDimensions'
import { useLocalChartDisplaySettings } from '../../hooks/useLocalChartDisplaySettings'
import { MetricChartPanels } from './MetricChartPanels'
import {
  defaultChartSettings,
  defaultChartTheme,
  defaultChartType,
} from '../../consts'
import { titleFormatter } from '@fintastic/shared/util/formatters'

import { WidgetContextValue } from '@fintastic/shared/ui/widgets-framework'
import { ModelExplorerVersionSelector } from '@fintastic/shared/ui/components'
import { useInvalidateMetricChartData } from '@fintastic/web/data-access/metrics-and-lists'
import { ChartSettingsIcon } from '../icons/ChartSettingIcon'
import { useIsFintasticUser } from '@fintastic/web/feature/auth'

// @todo: diffs?
type MetricChartProps = {
  metricId: Uuid
  widgetId: string
  versions: string[]
  title?: string
  isDesignMode?: boolean
  periodSelectorComponent: React.ReactNode
  isCollapsedVert?: boolean
  widgetChartSettings?: ChartSettings
  periodSelectionValue: PeriodSelection
  widgetContext: WidgetContextValue
  collapseButton: React.ReactNode
}

export const MetricChartContainer: React.FC<MetricChartProps> = memo(
  ({
    title,
    versions,
    isDesignMode,
    metricId,
    widgetId,
    periodSelectorComponent,
    widgetChartSettings,
    periodSelectionValue,
    isCollapsedVert,
    widgetContext,
    collapseButton,
  }) => {
    const versionsListQuery = useLoadVersionsList({
      versionsIds: versions,
      withLiveActuals: true,
    })

    const versionsMetadata = useVersionsListMap(
      useMemo(() => versionsListQuery.data || [], [versionsListQuery.data]),
    )

    const metricInVersionsQuery = useMetricInVersions(
      versions,
      metricId,
      true,
      periodSelectionValue,
    )

    const existingMetrics = useMemo(
      () =>
        metricInVersionsQuery.metricsWithVersion.filter(
          (versionMetric) => !!versionMetric.metric,
        ),
      [metricInVersionsQuery.metricsWithVersion],
    )

    const weightMetrics = useWeightedAverageMetrics(existingMetrics)

    const entitiesContextValue = useVersionEntitiesContextValue(versions)

    const dataProps = useMetricGridDataProps(
      metricId,
      existingMetrics,
      versionsMetadata,
      weightMetrics.isLoading ? undefined : weightMetrics.weightMetrics,
      entitiesContextValue.entities,
    )

    const error = useMemo(
      () =>
        getMetricGridError(versions, {
          metrics: metricInVersionsQuery.metricsWithVersion,
        }),
      [metricInVersionsQuery.metricsWithVersion, versions],
    )

    useSetBaseTimeDimensionEffect(
      useMemo(
        () =>
          compact(
            existingMetrics.map(
              (i) => i.metric?.metadata.base_time_dimension_id,
            ),
          ),
        [existingMetrics],
      ),
    )

    useSetBaseTimeDimensionFromErrorEffect(error)

    const [formulasOpened, setFormulasOpened] = useState(false)

    const toggleFormulas = useCallback(() => {
      setFormulasOpened((prev) => !prev)
    }, [])

    const hasFormulas = useHasMetricFormula(dataProps.data)
    const titleText = titleFormatter(title)

    const leftContent = useMemo(() => {
      if (
        !widgetContext?.draggable &&
        !widgetContext?.collapsable &&
        !hasFormulas
      ) {
        return undefined
      }

      return (
        <>
          {widgetContext?.draggable && <PanelDragHandle />}

          {widgetContext?.collapsable && collapseButton}

          {hasFormulas && (
            <FormulaButton
              icon={undefined}
              onClick={toggleFormulas}
              disabled={false}
              isActive={formulasOpened}
            />
          )}
          <ModelExplorerVersionSelector
            entityId={metricId}
            entityTitle={titleText}
            disabled={widgetContext?.boardInDesignMode}
          />
        </>
      )
    }, [
      widgetContext?.draggable,
      widgetContext?.collapsable,
      widgetContext?.boardInDesignMode,
      hasFormulas,
      collapseButton,
      toggleFormulas,
      formulasOpened,
      metricId,
      titleText,
    ])

    const baseTimeDimension = useBaseTimeDimensionCurrentValue()
    const showPeriodSelector = !!baseTimeDimension

    const [displayMode, setDisplayMode] = useState<ChartDisplayMode>('chart')

    const [localDimensions] = useLocalChartDimensions(
      widgetId,
      widgetChartSettings?.dimensions || [],
    )

    const [localDisplaySettings] = useLocalChartDisplaySettings(
      widgetId,
      widgetChartSettings,
    )

    const referenceDisplaySettings: ChartDisplaySettings = useMemo(
      () => ({
        theme: widgetChartSettings?.theme || defaultChartTheme,
        type: widgetChartSettings?.type || defaultChartType,
      }),
      [widgetChartSettings],
    )

    const actualParams = useMemo(() => {
      const referenceDimensions: MetricChartDimensions = isDesignMode
        ? widgetChartSettings?.dimensions || []
        : localDimensions

      return {
        metricId: metricId,
        versions: versions,
        periodSelection: periodSelectionValue,
        displaySettings: isDesignMode
          ? referenceDisplaySettings
          : localDisplaySettings,
        dimensions: isDesignMode ? referenceDimensions : localDimensions,
      }
    }, [
      localDisplaySettings,
      localDimensions,
      metricId,
      versions,
      periodSelectionValue,
      isDesignMode,
      widgetChartSettings,
      referenceDisplaySettings,
    ])

    // all the dimensions in all the version have to be the same, so -
    const versionEntities = useLoadVersionEntities(versions[0])

    const versionDimensions = useMemo(
      () => versionEntities.data?.dimensions || [],
      [versionEntities.data],
    )

    const { isLoading, isError, request, data } = useChartData(
      versions,
      metricId,
      periodSelectionValue,
      localDimensions,
      versionDimensions,
      versionsMetadata,
    )
    const { invalidateQueries } = useInvalidateMetricChartData(
      versions,
      metricId,
      periodSelectionValue,
      localDimensions,
    )

    const handleRetry = useCallback(() => {
      invalidateQueries()
    }, [invalidateQueries])

    const hasLocalChanges = useMemo(
      () =>
        JSON.stringify(localDimensions) !==
        JSON.stringify(widgetChartSettings?.dimensions),
      [localDimensions, widgetChartSettings],
    )

    const [showDebugPanels, setShowDebugPanels] = useState(false)

    const isDebugModalEnabled = useIsFintasticUser()

    useEffect(() => {
      const downHandler = (event: KeyboardEvent) => {
        if (
          event.ctrlKey &&
          event.shiftKey &&
          event.key === 'D' &&
          isDebugModalEnabled
        ) {
          setShowDebugPanels((v) => !v)
        }
      }

      window.addEventListener('keydown', downHandler)

      return () => {
        window.removeEventListener('keydown', downHandler)
      }
    }, [isDebugModalEnabled])

    return (
      <StyledMetricChartRoot>
        <TopBar
          data-testid="topbar-container"
          title={titleText}
          titleIcon={
            <Tooltip title="Metric Chart">
              <ChartSettingsIcon sx={{ width: '22px', fill: '#2C45FE' }} />
            </Tooltip>
          }
          sx={{ flexGrow: 0, flexShrink: 0 }}
          leftContent={leftContent}
          rightContent={
            showPeriodSelector ? periodSelectorComponent : undefined
          }
        />

        {hasFormulas && formulasOpened && !isCollapsedVert && (
          <StyledMetricChartFormulas>
            <MetricFormulaEditorWrapper
              versions={dataProps.data}
              onRequestClose={() => setFormulasOpened(false)}
              showVersionsSelector={true}
              controlledFormulaError={null}
              isSettingsEditingActive={false}
            />
          </StyledMetricChartFormulas>
        )}

        {!isCollapsedVert && (
          <MetricChartPanels
            data={data}
            dataError={isError}
            displayMode={displayMode}
            loading={isLoading}
            isDesignMode={isDesignMode}
            displaySettings={
              isDesignMode ? referenceDisplaySettings : localDisplaySettings
            }
            params={actualParams}
            request={request}
            tryAgainCallback={handleRetry}
            sidePanel={
              <MetricChartSidePanel
                loading={isLoading}
                isDesignMode={isDesignMode}
                widgetId={widgetId}
                metricId={metricId}
                versions={versions}
                initialSettings={widgetChartSettings || defaultChartSettings}
                highlightSettings={hasLocalChanges}
              />
            }
          />
        )}

        {!isCollapsedVert && showDebugPanels && (
          <ChartDebugModePanel mode={displayMode} onSetMode={setDisplayMode} />
        )}
      </StyledMetricChartRoot>
    )
  },
)
