import {
  CellDataType,
  cellDataTypeIsInteger,
  cellDataTypeIsPercentage,
  cellDataTypeIsPureNumeric,
  removeSigns,
} from '../hooks/clipboard-cell-utils'

const pointsCounter = /\./g

export const parseFormattedNumber = (
  maybeNumber: string,
  targetDataType: CellDataType = 'NUMERIC',
): number => {
  let normalizedString = maybeNumber

  const negativeMultiplier =
    (maybeNumber.includes('(') && maybeNumber.includes(')')) ||
    maybeNumber.includes('-')
      ? -1
      : 1

  const maybeNumberPositive = removeSigns(maybeNumber)

  const isPercentage = maybeNumber.includes('%')

  if (maybeNumber.includes(',')) {
    // 1,333,444.2 -> 1333444.2
    const pointPos = maybeNumber.indexOf('.')
    const commaPos = maybeNumber.indexOf(',')

    if (pointPos !== -1 && commaPos !== -1) {
      if (pointPos < commaPos) {
        // 100.222,00
        // 100.200.400,20
        normalizedString = maybeNumber.replaceAll('.', '')
        normalizedString = normalizedString.replaceAll(',', '.')
      } else {
        // 100,200.00
        normalizedString = maybeNumber.replaceAll(',', '')
      }
    }

    if (commaPos !== -1 && pointPos === -1) {
      // 100,200
      if (maybeNumberPositive.lastIndexOf(',') === 3) {
        normalizedString = maybeNumber.replaceAll(',', '')
      } else {
        // 0,12
        normalizedString = maybeNumber.replaceAll(',', '.')
      }
    }

    normalizedString = normalizedString.replaceAll(',', '')
  }

  if (
    normalizedString.includes('.') &&
    (normalizedString.match(pointsCounter) || []).length > 1
  ) {
    // 100.200.300.1
    normalizedString = normalizedString.replaceAll('.', '')
  }

  // remove anything except 1-9 and .
  const number = parseFloat(normalizedString.replace(/[^\d.]/g, ''))
  if (!Number.isFinite(number)) {
    return Number.NaN
  }

  if (isPercentage) {
    // pasting 100.23%, value with % sign
    // to numeric: Divide by 100 and remove % sign and paste

    if (cellDataTypeIsPureNumeric(targetDataType)) {
      // numeric, integer, currency
      let value = (number / 100) * negativeMultiplier
      if (cellDataTypeIsInteger(targetDataType)) {
        value = Math.trunc(value)
      }
      return value
    }

    if (cellDataTypeIsPercentage(targetDataType)) {
      // percent, percent_integer
      let value = (number / 100) * negativeMultiplier
      if (cellDataTypeIsInteger(targetDataType)) {
        value = Math.trunc(value * 100) / 100
      }
      return value
    }
  }

  // not a percentage value, generic number
  if (cellDataTypeIsPercentage(targetDataType)) {
    // Multiply by 100, add % sign and paste (10.23>1023.00%)
    // but *100 is automatic for cell type
    let value = number * negativeMultiplier
    if (cellDataTypeIsInteger(targetDataType)) {
      value = Math.trunc(value * 100) / 100
    }
    return value
  }

  // duplicate logic
  let value = number * negativeMultiplier
  if (cellDataTypeIsInteger(targetDataType)) {
    value = Math.trunc(value * 100) / 100
  }
  return value
}
