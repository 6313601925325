import React, { useCallback } from 'react'
import {
  TextFilterInput,
  TextFilterInputProps,
} from '@fintastic/shared/ui/filters'
import {
  FilterByString,
  filterUtils,
  FilterValue,
  useFilterContext,
} from '@fintastic/web/util/filters'
import type { Maybe } from '@fintastic/shared/util/types'

export const TextFilterGroup: React.FC<{
  filter: FilterByString
  timeDimensionValueId: Maybe<string>
  disableClear?: boolean
}> = ({ filter, timeDimensionValueId, disableClear }) => {
  const filterContext = useFilterContext()

  const handleValueChanged = useCallback<TextFilterInputProps['onChange']>(
    (nextValue) => {
      filterContext.setFilterValue(filter.id, timeDimensionValueId, nextValue)
    },
    [filter.id, filterContext, timeDimensionValueId],
  )

  return (
    <TextFilterInput
      onChange={handleValueChanged}
      disableClear={disableClear}
      filter={filter}
      filterValue={
        filterUtils.getValueFromTheModel<FilterValue<string>>(
          filterContext.values,
          filter.id,
          timeDimensionValueId,
        ) ?? {}
      }
    />
  )
}

TextFilterGroup.displayName = 'TextFilterGroup'
