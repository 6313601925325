import { Box, IconButton, styled } from '@mui/material'

export const StyledRoot = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-self: flex-start;
  padding: 0;

  width: 100%;
  height: 48px;
  min-height: 48px;
`

export const StyledButton = styled(IconButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ theme, size }) => theme.spacing(2)};
  margin-left: 12px;

  svg {
    width: 20px;
    height: 20px;
  }
`

export const StyledText = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
`

export const StyledLabel = styled('span')`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
`
