import { EChartsOption } from 'echarts'

export const ChartStyle: EChartsOption = {
  legend: {
    textStyle: {
      fontSize: 12,
      fontFamily: 'Ubuntu',
      color: '#333',
    },
    icon: 'pin',
    bottom: 10,
    type: 'scroll',
  },
  // https://github.com/apache/echarts/issues/14724
  grid: {
    bottom: 120,
  },
  xAxis: {
    type: 'category',
    axisTick: {
      alignWithLabel: true,
    },
    axisLabel: {
      rotate: 90,
      fontSize: 12,
      fontFamily: 'Ubuntu',
      color: '#333',
    },
  },
  yAxis: {
    axisLabel: {
      fontSize: 12,
      fontFamily: 'Ubuntu',
      color: '#333',
    },
  },

  tooltip: {
    trigger: 'item',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: {
      fontSize: 12,
      fontFamily: 'Ubuntu',
      color: '#333',
    },
  },
}
