import { Box, BoxProps, styled } from '@mui/material'
import React from 'react'

export const StyledWidgetTabsRoot = styled(Box)`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
`

export const StyledWidgetTabContent = styled(Box)`
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  width: 250px;
  min-width: 250px;
  height: 100%;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.palette.grey[500]} white;
`

export const StyledWidgetTabs = styled(Box)`
  height: 100%;
  width: 40px;
  min-width: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  align-content: center;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  padding-top: ${({ theme }) => theme.spacing(1)};

  svg {
    width: 20px;
  }
`

export const StyledWidgetTab = styled(
  (
    props: {
      active?: boolean
      disabled?: boolean
      highlight?: boolean
    } & BoxProps,
  ) => {
    const { active, disabled, highlight, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) =>
      propName !== 'active' &&
      propName !== 'disabled' &&
      propName !== 'highlight',
  },
)`
  width: 40px;
  height: 43px;
  display: flex;
  place-content: center;

  background: ${({ theme, highlight }) =>
    highlight ? theme.palette.primary.light : 'none'};

  margin: ${({ theme }) => theme.spacing(1)} 0;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.text.disabled : theme.palette.text.primary};
  position: relative;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  svg {
    fill: ${({ disabled, theme }) =>
      disabled ? theme.palette.text.disabled : 'inherit'};
  }

  box-sizing: border-box;
  border-left: 2px solid
    ${({ active, theme }) =>
      active ? theme.palette.primary.main : 'transparent'};

  &:hover {
    button {
      background: none;
      border-color: transparent;

      svg {
        fill: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  button {
    width: 40px;
    height: 43px;
    position: absolute;
    top: 0;
    left: -2px;
    display: flex;
    place-content: center;

    &:hover,
    &:focus {
      background: none;
      border-color: transparent;
    }
  }
`
