import { useMemo } from 'react'
import { filterUtils, useFilterContext } from '@fintastic/web/util/filters'
import { destructureField } from '@fintastic/web/util/metrics-and-lists'

export const useColumnFilter = (colId: string) => {
  const filterContext = useFilterContext()
  if (!filterContext) {
    throw new Error('FloatingPanelFilters depends on FilterContext')
  }

  const { filters } = filterContext
  const [, metricId, period] = useMemo(() => destructureField(colId), [colId])

  const currentFilter = useMemo(
    () => filters.find((f) => f.id === metricId) ?? null,
    [filters, metricId],
  )

  const { value, dirty } = useMemo(() => {
    const applied = filterUtils.getValueFromTheModel(
      filterContext.appliedValues,
      metricId,
      period ?? null,
    )
    const nonApplied = filterUtils.getValueFromTheModel(
      filterContext.values,
      metricId,
      period ?? null,
    )

    if (
      !filterUtils.filterHasMeaningfulValue(applied) &&
      !filterUtils.filterHasMeaningfulValue(nonApplied)
    ) {
      return {
        value: applied,
        dirty: false,
      }
    }

    return {
      value: applied,
      dirty: currentFilter
        ? !filterUtils.areFilterValuesEqual(currentFilter, applied, nonApplied)
        : false,
    }
  }, [
    currentFilter,
    filterContext.appliedValues,
    filterContext.values,
    metricId,
    period,
  ])

  return {
    currentFilter,
    period,
    metricId,
    value,
    dirty,
  }
}
