import { SharedActionsContextValue } from '../../../shared/connection/actions/types'
import { createContext, useContext } from 'react'
import { Actions } from './types'

export type ActionsContextValue = SharedActionsContextValue<Actions> & {
  allowExistingColumnsDeletion: boolean
}

const stub = () => {
  throw new Error('ActionsContext is not initialised')
}

export const ActionsContext = createContext<ActionsContextValue>({
  readonly: false,
  flow: 'creation',
  allowExistingColumnsDeletion: false,
  actions: {
    addColumn: stub,
    reorderColumns: stub,
    removeColumn: stub,
    changeColumnType: stub,
    changeColumnName: stub,
    changeColumnDimension: stub,
    changeColumnTimeBreakdown: stub,
    changeColumnValueDataType: stub,
    changeColumnValueDataFormatting: stub,
    changeColumnTimeAggregation: stub,
    changeColumnCategoryAggregation: stub,
    changeColumnCurrencySignPosition: stub,
    changeColumnVisibility: stub,
    changeListTimeDimension: stub,
  },
})

export const useActionsContext = () => useContext(ActionsContext)
