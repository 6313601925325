import React from 'react'
import Container from '@mui/material/Container'
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { Login } from '@fintastic/web/feature/auth'
import { Footer } from '../Footer'
import {
  StyledAppLoaderFooter,
  StyledAppLoaderForm,
  StyledAppLoaderPaper,
  StyledAppLoaderRoot,
} from './AppLoader.styled'

export type AppLoaderProps = {
  isAuthLoading: boolean
  isAuthenticated: boolean
  isAppInitStarted: boolean
  isAppInitFinished: boolean
}

export const AppLoader: React.FC<AppLoaderProps> = ({
  isAppInitStarted,
  isAppInitFinished,
  isAuthLoading,
  isAuthenticated,
}) => {
  const isShowLoadingContent =
    (!isAuthenticated && isAuthLoading) || isAuthenticated
  const isShowLoadingIndicator =
    isAuthLoading || (isAppInitStarted && !isAppInitFinished)
  const isShowLoginContent = !isAuthenticated && !isAuthLoading

  return (
    <StyledAppLoaderRoot data-testid="app-loader">
      <StyledAppLoaderForm>
        <Container maxWidth="xs" fixed disableGutters>
          <StyledAppLoaderPaper>
            {isShowLoadingIndicator && <LinearProgress />}
            <Box
              px={2}
              py={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="space-between"
              width="100%"
            >
              <img
                src="/fintastic.png"
                alt="Fintastic"
                style={{ marginTop: 8, width: '100px' }}
              />
              {isShowLoadingContent && (
                <Box mt={2} width="100%">
                  <Typography>Loading...</Typography>
                </Box>
              )}
              {isShowLoginContent && <Login />}
            </Box>
          </StyledAppLoaderPaper>
        </Container>
      </StyledAppLoaderForm>
      <StyledAppLoaderFooter>
        <Footer />
      </StyledAppLoaderFooter>
    </StyledAppLoaderRoot>
  )
}
