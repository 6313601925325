import React, { useCallback } from 'react'
import {
  DateFilterInput,
  DateFilterInputProps,
} from '@fintastic/shared/ui/filters'
import {
  FilterByDate,
  FilterDateRange,
  filterUtils,
  FilterValue,
  useFilterContext,
} from '@fintastic/web/util/filters'
import type { Maybe } from '@fintastic/shared/util/types'

export const DateFilterGroup: React.FC<{
  filter: FilterByDate
  timeDimensionValueId: Maybe<string>
  disableClear?: boolean
}> = ({ filter, timeDimensionValueId, disableClear }) => {
  const filterContext = useFilterContext()

  const handleValueChanged = useCallback<DateFilterInputProps['onChange']>(
    (nextValue) => {
      filterContext.setFilterValue(filter.id, timeDimensionValueId, nextValue)
    },
    [filter.id, filterContext, timeDimensionValueId],
  )

  return (
    <DateFilterInput
      onChange={handleValueChanged}
      filterValue={
        filterUtils.getValueFromTheModel<FilterValue<string | FilterDateRange>>(
          filterContext.values,
          filter.id,
          timeDimensionValueId,
        ) ?? {}
      }
      disableClear={disableClear}
      filter={filter}
    />
  )
}

DateFilterGroup.displayName = 'DateFilterGroup'
