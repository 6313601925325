import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  ListSettingsPanelDataProvider,
  ListSettingsPanelActionsProvider,
} from '@fintastic/web/feature/metrics-and-lists'
import {
  EditableListWithoutData,
  useCurrentEditingFlow,
} from '@fintastic/web/data-access/metrics-and-lists'
import { useSettingsPanelData } from './useSettingsPanelData'
import { Version } from '@fintastic/web/util/versions'
import { NavigationContext } from '@fintastic/web/feature/metrics-and-lists'
import { UseLocationResult } from '@fintastic/shared/util/memory-router'

export type SettingsPanelContextsWrapperProps = {
  version: Maybe<Pick<Version, 'uuid' | 'period_start' | 'period_end'>>
  list: Maybe<EditableListWithoutData>
  editingAllowed: boolean
  children: React.ReactNode
  location: UseLocationResult
  allowExistingColumnsDeletion: boolean
  requestEntityDeletion: (entityId: string) => void
}

export const SettingsPanelContextsWrapper: React.FC<
  SettingsPanelContextsWrapperProps
> = ({
  version,
  list,
  editingAllowed,
  children,
  location,
  allowExistingColumnsDeletion,
  requestEntityDeletion,
}) => {
  const { flow, type } = useCurrentEditingFlow()
  const data = useSettingsPanelData(version)

  return (
    <NavigationContext.Provider value={location}>
      <ListSettingsPanelDataProvider list={list} {...data}>
        <ListSettingsPanelActionsProvider
          flow={flow}
          readonly={!editingAllowed || type !== 'list'}
          allowExistingColumnsDeletion={allowExistingColumnsDeletion}
          requestEntityDeletion={requestEntityDeletion}
        >
          {children}
        </ListSettingsPanelActionsProvider>
      </ListSettingsPanelDataProvider>
    </NavigationContext.Provider>
  )
}
