import { WeightsGetter } from '@fintastic/shared/ui/ag-grid'
import { MetricGridRow } from '../../types'
import { WEIGHTED_AVERAGE_WEIGHT_COLUMN_POSTFIX } from './const'

// @todo add tests
export const weightsGetter: WeightsGetter<MetricGridRow> = (params) => {
  let result: number[] = []

  if (
    !params.rowNode.childrenAfterFilter ||
    params.rowNode.childrenAfterFilter.length === 0
  ) {
    return result
  }

  result = params.rowNode.childrenAfterFilter.map((row) => {
    const data = row.data || row.aggData
    if (!data) {
      return 0
    }
    return (data[
      `${params.colDef.colId || ''}:${WEIGHTED_AVERAGE_WEIGHT_COLUMN_POSTFIX}`
    ] || 0) as number
  })

  return result
}
