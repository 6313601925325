import { Maybe } from '@fintastic/shared/util/types'
import {
  NonTimeDimensionId,
  NonTimeDimensionValueId,
  TimeDimensionValueId,
} from '@fintastic/web/util/dimensions'

// path - because of tests
import { DiffMode, DiffPart } from 'libs/web/util/versions/src/lib/diff'

export const KEY_DELIMITER = ':'
const DIFF_DELIMITER = '__VS__'

export type FieldKeyGetter = (
  versionId: string,
  metricId: string,
  period: Maybe<TimeDimensionValueId>,
) => string

// @todo add tests
// @todo Rewrite this please
export const createFieldKey = (
  versionId: Maybe<string>,
  metricId: string,
  period: Maybe<TimeDimensionValueId> = null,
) => {
  if (!versionId && !period && metricId) {
    return metricId
  }

  let key = `${versionId}${
    metricId !== undefined ? KEY_DELIMITER + metricId : ''
  }`
  if (period != null) {
    key += `${KEY_DELIMITER}${period}`
  }
  return key
}

export const createDiffFieldKey = (
  diff: [versionA: string, versionB: string, mode: DiffMode],
  metricId: string,
  period: Maybe<TimeDimensionValueId> = null,
) =>
  createFieldKey(
    `${diff[DiffPart.firstVersion]}${DIFF_DELIMITER}${
      diff[DiffPart.secondVersion]
    }${DIFF_DELIMITER}${diff[DiffPart.mode]}`,
    metricId,
    period,
  )

// @todo add tests
export const destructureField = (key: string): DestructuredFieldKey =>
  key.split(KEY_DELIMITER) as DestructuredFieldKey

type DestructuredFieldKey = [
  versionId: string,
  metricId: string,
  period?: TimeDimensionValueId,
]

export const destructureDiffField = (key: string): DestructuredDiffFieldKey => {
  const [diffSource, metricId, periodId] = key.split(KEY_DELIMITER)

  if (!diffSource.includes(DIFF_DELIMITER)) {
    throw new Error('key is not a diff column id')
  }

  if (!metricId) {
    throw new Error('diff key does not contain metricId')
  }

  if (!periodId) {
    throw new Error('diff key does not contain periodId')
  }

  return [
    diffSource.split(DIFF_DELIMITER) as DestructuredDiffFieldKey[0],
    metricId,
    periodId,
  ]
}

export const isDiffFieldKey = (key: string): boolean => {
  const [diffSource] = key.split(KEY_DELIMITER)
  return diffSource.includes(DIFF_DELIMITER)
}

type DestructuredDiffFieldKey = [
  diff: [versionA: string, versionB: string, mode: DiffMode],
  metricId: string,
  period: TimeDimensionValueId,
]

// @todo: maybe there is a faster way to stringify object with sorting
export const createRowKey = (
  dimensionsSet: Record<NonTimeDimensionId, NonTimeDimensionValueId>,
): string =>
  JSON.stringify(
    Object.fromEntries(
      Object.keys(dimensionsSet)
        .sort()
        .map((key) => [key, dimensionsSet[key]]),
    ),
  )
