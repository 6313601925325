import { useCallback, useMemo, useRef, useState } from 'react'
import {
  PUSHER_EVENT_CALCULATION_PROGRESS,
  useSubscribeToTenantEvent,
} from '@fintastic/web/data-access/service-pusher'
import { CalculationProgressEvent } from './abstract-data-types/calculation-progress-event'
import { isCalculationProgressEvent } from './guards'

/**
 * Uses deprecated API
 * Need to migrate to `usePusherDataUpdateTaskOperation`
 * @deprecated
 */
export const usePusherCalculationOperation = <TArgs extends any[] = []>({
  requestFunction,
  onError,
  onSuccess,
}: {
  requestFunction: TaskRequestFunction<TArgs>
  onError?: TaskRequestErrorHandler
  onSuccess?: TaskRequestSuccessHandler<TArgs>
}) => {
  const [taskId, setTaskId] = useState('')
  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)
  const [loading, setLoading] = useState(false)
  const [eventData, setEventData] = useState<CalculationProgressEvent>()
  const [error, setError] = useState<CalculationProgressEvent | Error>()
  const currentArgs = useRef<TArgs>()

  const handleOperation = useCallback(
    async (...args: TArgs) => {
      currentArgs.current = args
      let operationResult

      try {
        setFinished(false)
        setStarted(true)
        setLoading(true)
        setEventData(undefined)
        operationResult = await requestFunction(...args)
      } catch (ex) {
        setStarted(false)
        setFinished(true)
        setError(ex as Error)
        if (onError) {
          onError(ex as Error)
          return
        } else {
          throw ex
        }
      } finally {
        setLoading(false)
      }

      setError(undefined)

      if (!isResponseTaskResult(operationResult)) {
        const err = new Error(
          'API Response is not a task result for usePusherTaskOperation',
        )
        setError(err)

        if (onError) {
          onError(err)
          return
        } else {
          throw err
        }
      }

      setTaskId(operationResult.result)
    },
    [onError, requestFunction, setTaskId],
  )

  const handlePusherEventReceived = useCallback(
    (event: CalculationProgressEvent | unknown) => {
      if (!isCalculationProgressEvent(event)) {
        return
      }

      if (event.task_id !== taskId) {
        return
      }

      setFinished(true)
      setStarted(false)
      setTaskId('')

      if (event.status !== 'success') {
        setError(event)
        onError?.(event)
        return
      }

      setError(undefined)
      setEventData(event)

      onSuccess?.(event, ...((currentArgs.current || []) as TArgs))
    },
    [onError, onSuccess, taskId],
  )

  useSubscribeToTenantEvent<CalculationProgressEvent>(
    PUSHER_EVENT_CALCULATION_PROGRESS,
    handlePusherEventReceived,
  )

  const processing = loading || (!finished && started)

  return useMemo(
    () => ({
      taskId,
      loading,
      processing,
      started,
      finished,
      eventData,
      error,
      handleOperation,
    }),
    [
      error,
      eventData,
      finished,
      handleOperation,
      loading,
      processing,
      started,
      taskId,
    ],
  )
}

export type TaskRequestFunction<TArgs extends any[] = []> = (
  ...args: TArgs
) => Promise<{ result: string }>
export type TaskRequestErrorHandler = (
  error: CalculationProgressEvent | Error,
) => void
export type TaskRequestSuccessHandler<TArgs extends any[] = []> = (
  data: CalculationProgressEvent,
  ...args: TArgs
) => void
export type TaskResult = { result: string }

const isResponseTaskResult = (obj: unknown): obj is TaskResult =>
  typeof (obj as TaskResult)?.result === 'string'
