import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import type {
  ColumnApi,
  GridApi,
  IsGroupOpenByDefaultParams,
  RowNode,
} from 'ag-grid-community'
import {
  useAllThreadsBulk,
  useBoardId,
} from '@fintastic/web/data-access/comments'
import {
  useReadDeeplinkValue,
  useSyncDeeplinkValue,
} from '@fintastic/web/util/deeplink'
import { useComponentState } from '@fintastic/web/data-access/preferences-state'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import type {
  GenericReportTreeRowCellValue,
  SortableDimension,
} from '@fintastic/web/util/generic-report'
import type { RowGroupOpenedEvent } from 'ag-grid-community/dist/lib/events'
import { compact, isEqual, reverse, slice } from 'lodash'
import { parseObjectId } from '../utils/parse-object-id'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'

const OPEN_GROUP_KEY = 'OpenGroups'

const deeplinkDefaultValue: string[] = []

export const useDeeplinkOpenedGroups = (
  category: string,
  deeplinkWidgetId: string,
  gridRef: React.RefObject<AgGridReactType<GenericReportTreeRowCellValue>>,
  onSyncedWithUrl: ({
    api,
    columnApi,
  }: {
    api: GridApi<GenericReportTreeRowCellValue>
    columnApi: ColumnApi
  }) => void,
  onSyncWithUrlEffectStarted: () => void,
  dimensions: SortableDimension[],
) => {
  // Preferences (in local storage)
  const [openedGroupsPreferenceOriginal, setOpenedGroupsPreference] =
    useComponentState<string[]>(category + OPEN_GROUP_KEY)

  // Deeplink value (in URL)
  const [urlOpendedGroupsOriginal, setUrlOpendedGroups] = useSyncDeeplinkValue<
    string[]
  >({
    key: `w${deeplinkWidgetId}_groups`,
    defaultValue: deeplinkDefaultValue,
  })
  const urlOpendedGroups = useReferenceMemo(urlOpendedGroupsOriginal, isEqual)

  const boardId = useBoardId()

  const { data: threads } = useAllThreadsBulk('board' + boardId, false)

  const threadId = useReadDeeplinkValue<number>('thread_id')

  const selectedObjectKey = useMemo(() => {
    if (!threadId) {
      return undefined
    }
    return threads?.find((t) => t.id === threadId)?.object_key
  }, [threadId, threads])

  const allParsedPathsOriginal = useMemo(() => {
    if (!selectedObjectKey) {
      return []
    }
    const parsed = parseObjectId(selectedObjectKey)
    const parsedPath = compact(
      dimensions.map((d) => parsed.dimensions?.[d.name]),
    )

    return parsedPath.map((p, index) =>
      reverse(slice(parsedPath, 0, index + 1)).join(PATH_DELIMETER),
    )
  }, [dimensions, selectedObjectKey])

  const openedGroupsPreference = useReferenceMemo(
    openedGroupsPreferenceOriginal ?? [],
    isEqual,
  )
  const allParsedPaths = useReferenceMemo(allParsedPathsOriginal, isEqual)

  const isGroupOpenByDefault = useCallback(
    (params: IsGroupOpenByDefaultParams): boolean => {
      const rowId = params.rowNode.key

      if (!rowId) {
        return false
      }

      const { path, legacyBrokenPath } = getOpenedGroupsAllParentsPath(
        params.rowNode,
      )

      const isURLOpened =
        urlOpendedGroups.includes(path) ||
        urlOpendedGroups.includes(legacyBrokenPath)
      const preferencesOpen =
        openedGroupsPreference?.includes(path) ||
        openedGroupsPreference?.includes(legacyBrokenPath)

      if (isURLOpened || preferencesOpen) {
        return true
      }

      if (allParsedPaths.includes(path)) {
        return true
      }

      return false
    },
    [urlOpendedGroups, openedGroupsPreference, allParsedPaths],
  )

  const isProcessingRef = useRef(false)

  const handleRowGroupOpened = useCallback(
    ({ api, event }: RowGroupOpenedEvent<GenericReportTreeRowCellValue>) => {
      if (isProcessingRef.current || !event) {
        return
      }

      const openedRowNodes = []
      const counter = api.getDisplayedRowCount()

      for (let i = 0; i < counter; i++) {
        openedRowNodes.push(api.getDisplayedRowAtIndex(i))
      }

      const openedGroups = compact(
        openedRowNodes
          .filter((node) => node && node.expanded && node.key)
          .map((i) => (i ? getOpenedGroupsAllParentsPath(i)?.path : null)),
      )

      setUrlOpendedGroups(openedGroups)
      setOpenedGroupsPreference(openedGroups)
    },
    [setOpenedGroupsPreference, setUrlOpendedGroups],
  )

  const makeGroupsOpen = useCallback(() => {
    isProcessingRef.current = true
    gridRef.current?.api.forEachNodeAfterFilterAndSort((node) => {
      const { path, legacyBrokenPath, pathLength } =
        getOpenedGroupsAllParentsPath(node)
      const isURLOpened =
        urlOpendedGroups.includes(path) ||
        urlOpendedGroups.includes(legacyBrokenPath)

      const parsedOpen =
        allParsedPaths.length > pathLength && allParsedPaths.includes(path)

      const open = isURLOpened || parsedOpen

      node.setExpanded(open)
    })
    isProcessingRef.current = false
  }, [allParsedPaths, gridRef, urlOpendedGroups])

  const onSyncedWithUrlRef = useRef(onSyncedWithUrl)
  onSyncedWithUrlRef.current = onSyncedWithUrl
  const onSyncWithUrlEffectStartedRef = useRef(onSyncWithUrlEffectStarted)
  onSyncWithUrlEffectStartedRef.current = onSyncWithUrlEffectStarted

  useEffect(() => {
    if (!gridRef.current?.api || isProcessingRef.current) {
      return
    }
    onSyncWithUrlEffectStartedRef.current?.()
    makeGroupsOpen()

    onSyncedWithUrlRef.current?.({
      api: gridRef.current.api,
      columnApi: gridRef.current.columnApi,
    })
    return () => {
      isProcessingRef.current = false
    }
  }, [makeGroupsOpen, gridRef])

  return {
    isGroupOpenByDefault,
    handleRowGroupOpened,
    makeGroupsOpen,
  }
}

const PATH_DELIMETER = '-'

export const getOpenedGroupsAllParentsPath = (node: RowNode<unknown>) => {
  let parent = node
  const path = []

  if (!parent.parent) {
    return {
      path: parent.key || '',
      legacyBrokenPath: '',
      pathLength: 0,
    }
  }

  while (parent?.key) {
    path.push(parent?.key)
    if (parent.parent) {
      parent = parent.parent
    } else {
      break
    }
  }

  return {
    path: path.join(PATH_DELIMETER),
    legacyBrokenPath: [node.key, ...path].join(PATH_DELIMETER),
    pathLength: path.length,
  }
}
