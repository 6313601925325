import { PeriodResolution } from '../types'

export const doNotSupportRequestedGranularityMessage = (
  type: 'metric' | 'list' | 'report',
  resolution: PeriodResolution,
): string =>
  `This ${typeName[type] ?? type} does not have data at ${
    casedResolution[resolution] ?? resolution
  } level.`

const casedResolution: Record<PeriodResolution, string> = {
  day: 'daily',
  week: 'weekly',
  month: 'monthly',
  quarter: 'quarterly',
  half_year: 'half yearly',
  year: 'yearly',
}

const typeName = {
  metric: 'Metric',
  list: 'List',
  report: 'Report',
}
