import { styled } from '@mui/material'

export const StyledDrawerResizeHandler = styled('div')`
  position: absolute;
  top: 0;
  left: 4px;
  right: 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  z-index: 10;

  border-top: 1px solid transparent;

  &.in-resize {
    border-color: ${({ theme }) => theme.palette.action.selected};
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.1);

    .resize-handler {
      background-color: ${({ theme }) => theme.palette.action.hover};
      border-color: ${({ theme }) => theme.palette.action.selected};
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

export const StyledButton = styled('div')`
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  cursor: ns-resize;
  height: 12px;
  display: flex;
  align-items: center;
  width: 32px;
  justify-content: center;
  border-radius: 2.65px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  font-size: 14px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
    border-color: ${({ theme }) => theme.palette.action.selected};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
