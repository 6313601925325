import { Box, styled } from '@mui/material'

export const StyledChartParams = styled(Box)`
  width: 100%;
  overflow-y: auto;
  padding: 0 ${({ theme }) => theme.spacing(2)};
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.palette.grey[500]} white;

  pre {
    font-size: 10px;
    font-family: monospace;
  }

  table {
    width: 100%;
    padding: 0 ${({ theme }) => theme.spacing(1)};
    margin: ${({ theme }) => theme.spacing(1)} 0;

    thead {
      th {
        font-size: 12px;
        font-weight: 500;
      }
    }

    td {
      border: 1px solid ${({ theme }) => theme.palette.divider};
      padding: ${({ theme }) => theme.spacing(1)};
      width: 33.3%;
      vertical-align: top;
      border-radius: 6px;
    }
  }
`
