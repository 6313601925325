import { Box, BoxProps, styled, Switch } from '@mui/material'
import React from 'react'

export const StyledSorter = styled(
  (props: BoxProps & { disabled?: boolean }) => {
    const { disabled, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'disabled',
  },
)`
  transition: all ${({ theme }) => theme.transitions.duration.standard}ms
    ${({ theme }) => theme.transitions.easing.easeInOut};

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  filter: ${({ disabled }) => (disabled ? 'grayscale(100)' : 'none')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

export const StyledSorterTitle = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  padding-top: ${({ theme }) => theme.spacing(2)};
`
export const StyledSorterHeaders = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  font-size: 11px;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)} 0;
`

export const StyledSorterPanel = styled(Box)`
  overflow: visible;
`

export const StyledColumnItem = styled(Box)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  height: 32px;
  cursor: pointer;
  align-content: center;
  align-items: center;

  svg {
    width: 16px;
    margin-right: ${({ theme }) => theme.spacing(1)};
    color: ${({ theme }) => theme.palette.divider};
  }
`
export const StyledColumnItemContainer = styled(Box)`
  position: relative;
`

export const StyledDimensionName = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  width: 100%;

  svg {
    width: 21px;
    margin-right: ${({ theme }) => theme.spacing(1)};
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`

export const StyledSwitch = styled(Switch)`
  z-index: 1;
  position: absolute;
  pointer-events: all;
  right: 0;
  top: 50%;
  transition: all 0.2s ease;
  transform: translateY(-50%);

  &.drag-switch {
    opacity: 0;
    pointer-events: none;
  }

  &.real-switch {
    opacity: 1;
  }

  .dragging &.real-switch {
    opacity: 0;
  }

  .dragging &.drag-switch {
    opacity: 0.5;
  }
`
