import React, { useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { ActionsContext, ActionsContextValue } from './actions-context'
import { useAddColumnFlow } from './hooks/useAddColumnFlow'
import { useReorderColumnsFlow } from './hooks/useReorderColumnsFlow'
import { useChangeColumnCategoryAggregationFlow } from './hooks/useChangeColumnCategoryAggregationFlow'
import { useChangeColumnDimensionFlow } from './hooks/useChangeColumnDimensionFlow'
import { useChangeColumnNameFlow } from './hooks/useChangeColumnNameFlow'
import { useChangeColumnTimeAggregationFlow } from './hooks/useChangeColumnTimeAggregationFlow'
import { useChangeColumnTimeBreakdownFlow } from './hooks/useChangeColumnTimeBreakdownFlow'
import { useChangeColumnTypeFlow } from './hooks/useChangeColumnTypeFlow'
import { useChangeColumnValueDataFormattingFlow } from './hooks/useChangeColumnValueDataFormattingFlow'
import { useChangeColumnValueDataTypeFlow } from './hooks/useChangeColumnValueDataTypeFlow'
import { useRemoveColumnFlow } from './hooks/useRemoveColumnFlow'
import { MetricOrListEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { useDataContext } from '../data/data-context'
import { useChangeColumnCurrencySignPositionFlow } from './hooks/useChangeColumnCurrencySignPositionFlow'
import { useChangeColumnVisibilityFlow } from './hooks/useChangeColumnVisibilityFlow'
import { useChangeListTimeDimensionFlow } from './hooks/useChangeListTimeDimensionFlow'

export type ListSettingsPanelActionsProviderProps = {
  readonly?: boolean
  flow: Maybe<MetricOrListEditingFlow>
  children: React.ReactNode
  allowExistingColumnsDeletion: boolean
  requestEntityDeletion: (entityId: string) => void
}

export const ListSettingsPanelActionsProvider: React.FC<
  ListSettingsPanelActionsProviderProps
> = ({
  readonly = false,
  children,
  flow,
  allowExistingColumnsDeletion,
  requestEntityDeletion,
}) => {
  const data = useDataContext()

  const addColumnFlow = useAddColumnFlow(data)
  const reorderColumnsFlow = useReorderColumnsFlow()
  const removeColumnFlow = useRemoveColumnFlow(data, flow, {
    requestEntityDeletion,
  })
  const changeColumnCategoryAggregationFlow =
    useChangeColumnCategoryAggregationFlow()
  const changeColumnDimensionFlow = useChangeColumnDimensionFlow(data)
  const changeColumnNameFlow = useChangeColumnNameFlow()
  const changeColumnTimeAggregationFlow = useChangeColumnTimeAggregationFlow()
  const changeColumnTimeBreakdownFlow = useChangeColumnTimeBreakdownFlow(
    data,
    flow,
  )
  const changeColumnTypeFlow = useChangeColumnTypeFlow(data)
  const changeColumnValueDataFormattingFlow =
    useChangeColumnValueDataFormattingFlow()
  const changeColumnValueDataTypeFlow = useChangeColumnValueDataTypeFlow(
    data,
    flow,
  )
  const changeColumnCurrencySignPositionFlow =
    useChangeColumnCurrencySignPositionFlow()
  const changeColumnVisibilityFlow = useChangeColumnVisibilityFlow()
  const changeListTimeDimensionFlow = useChangeListTimeDimensionFlow(data, flow)

  const contextValue = useMemo<ActionsContextValue>(
    () => ({
      readonly,
      allowExistingColumnsDeletion,
      flow,
      actions: {
        addColumn: addColumnFlow.action,
        reorderColumns: reorderColumnsFlow.action,
        removeColumn: removeColumnFlow.action,
        changeColumnCategoryAggregation:
          changeColumnCategoryAggregationFlow.action,
        changeColumnDimension: changeColumnDimensionFlow.action,
        changeColumnName: changeColumnNameFlow.action,
        changeColumnTimeAggregation: changeColumnTimeAggregationFlow.action,
        changeColumnTimeBreakdown: changeColumnTimeBreakdownFlow.action,
        changeColumnType: changeColumnTypeFlow.action,
        changeColumnValueDataFormatting:
          changeColumnValueDataFormattingFlow.action,
        changeColumnValueDataType: changeColumnValueDataTypeFlow.action,
        changeColumnCurrencySignPosition:
          changeColumnCurrencySignPositionFlow.action,
        changeColumnVisibility: changeColumnVisibilityFlow.action,
        changeListTimeDimension: changeListTimeDimensionFlow.action,
      },
    }),
    [
      addColumnFlow.action,
      allowExistingColumnsDeletion,
      changeColumnCategoryAggregationFlow.action,
      changeColumnCurrencySignPositionFlow.action,
      changeColumnDimensionFlow.action,
      changeColumnNameFlow.action,
      changeColumnTimeAggregationFlow.action,
      changeColumnTimeBreakdownFlow.action,
      changeColumnTypeFlow.action,
      changeColumnValueDataFormattingFlow.action,
      changeColumnValueDataTypeFlow.action,
      changeColumnVisibilityFlow.action,
      changeListTimeDimensionFlow.action,
      flow,
      readonly,
      removeColumnFlow.action,
      reorderColumnsFlow.action,
    ],
  )

  return (
    <ActionsContext.Provider value={contextValue}>
      {children}
      {changeColumnValueDataTypeFlow.popups}
      {changeColumnTimeBreakdownFlow.popups}
      {removeColumnFlow.popups}
    </ActionsContext.Provider>
  )
}
