import React from 'react'
import {
  StyledRoot,
  StyledHeader,
  StyledTitle,
  StyledBackButton,
  StyledContent,
  StyledFooter,
} from './ViewLayout.styled'
import { useMemoryRouterContext } from '@fintastic/shared/util/memory-router'
import { ArrowLeftIcon } from '@fintastic/shared/ui/icons'
import { Nullable } from '@fintastic/shared/util/functional-programming'

export type ViewLayoutProps = {
  enableBackButton?: boolean
  title: React.ReactNode
  children: React.ReactNode
  footer?: Nullable<React.ReactNode>
}

export const ViewLayout: React.FC<ViewLayoutProps> = ({
  enableBackButton = true,
  title,
  children,
  footer,
}) => {
  const { goBack } = useMemoryRouterContext()

  return (
    <StyledRoot>
      <StyledHeader>
        {enableBackButton && (
          <StyledBackButton color="textBlack" onClick={goBack}>
            <ArrowLeftIcon fontSize="small" color="inherit" />
          </StyledBackButton>
        )}
        <StyledTitle variant="subtitle1">{title}</StyledTitle>
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
      {footer && <StyledFooter>{footer}</StyledFooter>}
    </StyledRoot>
  )
}
