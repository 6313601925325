import type { AllEffect, Effect } from 'redux-saga/effects'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'
import { actions } from './slice'
import {
  authLoadUserInfoSaga,
  authTenantOverrideSaga,
} from '@fintastic/web/feature/auth'
import { configLoadSaga } from '@fintastic/web/feature/config'
import { syntaxInjectSaga } from '@fintastic/web/data-access/formulas'

export function* appInitializationSaga() {
  yield call(authTenantOverrideSaga)
  yield all([syntaxInjectSaga(), authLoadUserInfoSaga(), configLoadSaga()])
}

function* onEmitStart() {
  yield put(actions.start())

  try {
    yield appInitializationSaga()
  } catch (err) {
    console.error('app initialization failed:', err)
    yield put(actions.finish({ error: (err as Error)?.message }))
  }

  yield put(actions.finish({ error: null }))
}

function* watchersSaga(): Iterator<AllEffect<Effect>> {
  yield all([takeEvery(actions.emitStart, onEmitStart)])
}

const sagas: Saga[] = [watchersSaga]

export default sagas
