import React, { useCallback, useMemo } from 'react'
import { DimensionFilterInput } from '@fintastic/shared/ui/filters'
import {
  FilterByDimension,
  filterUtils,
  FilterValue,
  useFilterContext,
} from '@fintastic/web/util/filters'
import { CheckboxesListChangeHandler } from '@fintastic/shared/ui/components'
import type { Maybe } from '@fintastic/shared/util/types'

export const DimensionFilterGroup: React.FC<{
  filter: FilterByDimension
  timeDimensionValueId: Maybe<string>
}> = ({ filter, timeDimensionValueId }) => {
  const filterContext = useFilterContext()

  const handleDimensionSelected = useCallback<CheckboxesListChangeHandler>(
    (nextDimensions) => {
      filterContext.setFilterValue(filter.id, timeDimensionValueId, {
        value: nextDimensions,
        operator: 'dimension',
      })
    },
    [filter.id, filterContext, timeDimensionValueId],
  )

  const allSelectedOptions = useMemo(
    () => filter.options.map((i) => i.value),
    [filter],
  )

  return (
    <DimensionFilterInput
      onChange={handleDimensionSelected}
      options={filter.options}
      selected={
        filterUtils.getValueFromTheModel<FilterValue<string[]>>(
          filterContext.values,
          filter.id,
          timeDimensionValueId,
        )?.value ?? allSelectedOptions
      }
    />
  )
}

DimensionFilterGroup.displayName = 'DimensionFilterGroup'
