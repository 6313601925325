import type { ICellRendererParams } from 'ag-grid-community'
import { VersionTable, VersionTableReport } from '../types'
import { Tag, Tags } from '@fintastic/shared/ui/components'
import {
  Box,
  BoxProps,
  ClickAwayListener,
  LinearProgress,
  Popper,
  Skeleton,
  styled,
} from '@mui/material'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { LabelsMenu } from '@fintastic/web/feature/labels'
import { useLabelsListQuery } from '@fintastic/web/data-access/labels'
import { compact } from 'lodash'
import {
  useToggleLabelForListOrMetric,
  VersionLabelToggleParams,
} from '@fintastic/web/data-access/metrics-and-lists'

export const LabelsCellRenderer = (
  params: ICellRendererParams<Exclude<VersionTable, VersionTableReport>> & {
    versionId: string
  },
) => {
  const toggleParams = useMemo<Omit<VersionLabelToggleParams, 'labelId'>>(
    () => ({
      versionId: params.versionId,
      entityId: params.data?.id || '',
      entityType: params.data?.type || 'list',
    }),
    [params.data?.id, params.data?.type, params.versionId],
  )

  const [localLabelIds, setLocalLabelIds] = useState(
    () => params?.data?.info?.label_ids || [],
  )

  const toggleLabelMutation = useToggleLabelForListOrMetric(toggleParams)
  const labelsQuery = useLabelsListQuery('versions', false)

  const [focused, setFocused] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)

  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false)
  }, [])

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true)
  }, [])

  const handleRemove = useCallback(
    ({ id }: Tag) => {
      setLocalLabelIds((prev) => prev.filter((i) => i !== id))
      toggleLabelMutation.mutate({
        ...toggleParams,
        operation: 'detach',
        labelId: id,
      })
    },
    [toggleParams, toggleLabelMutation],
  )

  const tags = useMemo<Tag[]>(() => {
    if (!labelsQuery?.data?.length) {
      return []
    }

    return compact(
      labelsQuery.data?.filter((i) => localLabelIds.includes(i.id)),
    )
  }, [localLabelIds, labelsQuery.data])

  const handleActiveLabelChange = useCallback(
    async (labelId: number, checked: boolean) => {
      setLocalLabelIds((prev) =>
        checked ? [...prev, labelId] : prev.filter((i) => i !== labelId),
      )
      toggleLabelMutation.mutate({
        ...toggleParams,
        operation: checked ? 'attach' : 'detach',
        labelId,
      })
    },
    [toggleParams, toggleLabelMutation],
  )

  // Sync row height
  const prevRowHeight = useRef(40)
  useEffect(() => {
    const wrapperHeight = wrapperRef.current?.clientHeight || 40
    let nextRowHeight = focused ? wrapperHeight : 40

    if (nextRowHeight < 40) {
      nextRowHeight = 40
    }

    if (prevRowHeight.current === nextRowHeight) {
      return
    }

    params.node.setRowHeight(nextRowHeight)
    params.api.onRowHeightChanged()
    prevRowHeight.current = nextRowHeight
  }, [focused, params.api, params.node, tags])

  if (labelsQuery.isLoading) {
    if (localLabelIds.length) {
      return (
        <Box py="6px" display="flex" gap={1}>
          {localLabelIds.map((id, index) => (
            <Skeleton key={id} width={index % 2 === 0 ? 60 : 80} />
          ))}
        </Box>
      )
    }

    return null
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setFocused(false)
        handleMenuClose()
      }}
    >
      <LabelsCellDynamicAddButtonStyled
        py="6px"
        onClick={() => setFocused(true)}
        ref={wrapperRef}
        focused={focused || menuOpen}
      >
        {toggleLabelMutation.isLoading && !menuOpen ? (
          <LinearProgress
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 2,
            }}
          />
        ) : (
          <div /> // Maintain DOM-elements number and order
        )}
        <Tags
          tags={tags}
          singleline={!focused}
          limit={!focused}
          onDelete={focused ? handleRemove : undefined}
          showAddButton={focused || tags.length === 0}
          onAddButtonClick={handleMenuOpen}
        />
        <Popper open={menuOpen} anchorEl={wrapperRef.current}>
          <LabelsMenu
            context="versions"
            activeLabels={localLabelIds}
            onActiveLabelChange={handleActiveLabelChange}
            onCreated={handleMenuClose}
            minWidth={wrapperRef.current?.clientWidth}
            showFetching={toggleLabelMutation.isLoading}
          />
        </Popper>
      </LabelsCellDynamicAddButtonStyled>
    </ClickAwayListener>
  )
}

export const LabelsCellDynamicAddButtonStyled = styled(
  forwardRef<
    HTMLElement,
    BoxProps & {
      focused: boolean
    }
  >((props, ref) => {
    const { focused, ...boxProps } = props
    return <Box ref={ref} {...boxProps} />
  }),
  {
    shouldForwardProp: (propName) => propName !== 'focused',
  },
)(
  ({ focused }) => `
  position: relative;
  [data-testid='add-new-label'] {
    opacity: 0;
    transition: opacity .2s ease-in;
  }

  &:hover [data-testid='add-new-label'] {
    opacity: 1;
  }

  ${
    focused &&
    `
    [data-testid='add-new-label'] {
      opacity: 1;
    }
  `
  }
`,
)
