import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { DimensionId } from '@fintastic/web/util/dimensions'

type Filters = Partial<{
  versionId: string
  metricId: string
  aggregationDimensionId: DimensionId
  metricIds: string[]
  predicate: (entry: Entry) => boolean
}>

class Entry {
  constructor(
    private versionId: string,
    private metric: Metric,
    private aggregationDimensionId: DimensionId,
  ) {}

  public getVersionId = () => this.versionId
  public getMetricId = () => this.metric.id
  public getMetric = () => this.metric
  public getAggregationType = () => this.aggregationDimensionId
  public getBaseTimeDimensionId = () => this.getMetric().metadata.time_dimension_id
  public isTimeAggregated = () =>
    !!this.getMetric().metadata.dimensions.find((dim) => dim.type === 'Time')
  public isLowestTimeAggregation = () => this.getAggregationType() === this.getBaseTimeDimensionId()

  public matches = ({
    metricIds,
    metricId,
    aggregationDimensionId,
    versionId,
    predicate,
  }: Filters): boolean => {
    if (
      !metricIds === undefined &&
      metricId === undefined &&
      aggregationDimensionId === undefined &&
      versionId === undefined &&
      predicate === undefined
    ) {
      return false
    }

    if (predicate && !predicate(this)) {
      return false
    }

    if (versionId !== undefined && this.getVersionId() !== versionId) {
      return false
    }

    if (
      (metricIds || metricId) &&
      !(metricIds || [metricId]).includes(this.getMetricId())
    ) {
      return false
    }

    if (
      aggregationDimensionId !== undefined &&
      this.getAggregationType() !== aggregationDimensionId
    ) {
      return false
    }

    return true
  }
}

class EditedInputMetricsRegistry {
  private entries: Entry[] = []

  public add(
    versionId: string,
    metric: Metric,
    aggregationDimensionId: DimensionId,
  ) {
    if (this.has({ versionId, metricId: metric.id, aggregationDimensionId })) {
      return
    }
    this.entries.push(new Entry(versionId, metric, aggregationDimensionId))
  }

  public remove(filters: Filters) {
    this.entries = this.entries.filter((e) => !e.matches(filters))
  }

  public has(filters: Filters): boolean {
    return this.entries.some((e) => e.matches(filters))
  }

  public get(filters: Filters) {
    return this.entries.filter((e) => e.matches(filters))
  }
}

export const editedInputMetricsRegistry = new EditedInputMetricsRegistry()
