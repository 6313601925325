import React, { ChangeEvent, useCallback } from 'react'
import {
  StyledTextFieldField,
  StyledTextFieldFieldCont,
  StyledTextFieldLabel,
  StyledTextFieldLabelCont,
  StyledTextFieldRoot,
} from './TextField.styled'
import { Box, CircularProgress } from '@mui/material'

export type TextFieldProps = {
  label: string
  fullWidth?: boolean
  autoFocus?: boolean
  placeholder?: string
  disabled?: boolean
  loading?: boolean
  maxLength?: number
  value: string
  onChange: (newValue: string) => void
  error?: boolean
  errorMessage?: string
  ['data-testid']?: string
}

export const TextField: React.FC<TextFieldProps> = (props) => {
  const {
    label,
    placeholder,
    fullWidth,
    autoFocus,
    disabled,
    loading,
    maxLength,
    value,
    onChange,
    error,
    errorMessage,
  } = props

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    },
    [onChange],
  )

  return (
    <StyledTextFieldRoot>
      {!fullWidth && <StyledTextFieldLabelCont>
        <StyledTextFieldLabel>{label}</StyledTextFieldLabel>
      </StyledTextFieldLabelCont>}
      <StyledTextFieldFieldCont fullWidth={fullWidth}>
        <StyledTextFieldField
          placeholder={placeholder}
          label={fullWidth ? label : undefined}
          autoFocus={autoFocus}
          autoComplete={'off'}
          disabled={disabled || false}
          value={value}
          onChange={handleChange}
          error={error}
          helperText={errorMessage}
          size="small"
          fullWidth={true}
          data-testid={props['data-testid']}
          inputProps={{
            maxLength: maxLength,
          }}
          InputProps={{
            startAdornment: loading && (<Box mt={1}>
                <CircularProgress size={16} />
              </Box>
            ),
          }}
        />
      </StyledTextFieldFieldCont>
    </StyledTextFieldRoot>
  )
}
