import React, { memo } from 'react'
import {
  StyledChartDebugPanel,
  StyledDebugButton,
} from './ChartDebugModePanel.styled'
import { ChartTypeBarsIcon } from '../icons/ChartTypeBarsIcon'
import { ChartDisplayMode } from '../../types'
import { WidgetMetricIcon } from '@fintastic/shared/ui/icons'
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined'
import { useIsFintasticUser } from '@fintastic/web/feature/auth'

export type ChartDebugModePanelProps = {
  mode: ChartDisplayMode
  onSetMode: (mode: ChartDisplayMode) => void
}

export const ChartDebugModePanel: React.FC<ChartDebugModePanelProps> = memo(
  ({ mode, onSetMode }) => {
    const isDebugModalEnabled = useIsFintasticUser()

    return (
      <StyledChartDebugPanel>
        <StyledDebugButton
          size="small"
          active={mode === 'chart'}
          onClick={() => onSetMode('chart')}
        >
          <ChartTypeBarsIcon />
        </StyledDebugButton>

        <StyledDebugButton
          size="small"
          active={mode === 'table'}
          onClick={() => onSetMode('table')}
        >
          <WidgetMetricIcon />
        </StyledDebugButton>

        {isDebugModalEnabled && (
          <StyledDebugButton
            size="small"
            active={mode === 'debug'}
            onClick={() => onSetMode('debug')}
          >
            <DataObjectOutlinedIcon />
          </StyledDebugButton>
        )}
      </StyledChartDebugPanel>
    )
  },
)
