import React from 'react'
import { ActionButtonsContainer } from '../../../shared/ActionButtonsContainer'
import { ActionButton } from '../../../shared/ActionButton'
import { DeleteIcon } from '@fintastic/shared/ui/icons'

export type FooterProps = {
  onRemove: () => void
}

export const Footer: React.FC<FooterProps> = ({ onRemove }) => (
  <ActionButtonsContainer>
    <ActionButton
      label="Delete column"
      onClick={onRemove}
      icon={<DeleteIcon />}
    />
  </ActionButtonsContainer>
)
