import { useCallback, useMemo, useRef, useState } from 'react'
import {
  DataUpdateEvent,
  SuccessfulDataUpdateEvent,
  FailedDataUpdateEvent,
  isDataUpdateEvent,
} from '@fintastic/web/util/metric-data-editing'
import {
  PUSHER_EVENT_CELL_DATA_UPDATE,
  useSubscribeToTenantEvent,
} from '@fintastic/web/data-access/service-pusher'

export const usePusherDataUpdateTaskOperation = <TArgs extends any[] = []>({
  requestFunction,
  onError,
  onSuccess,
}: {
  requestFunction: TaskRequestFunction<TArgs>
  onError?: TaskRequestErrorHandler
  onSuccess?: TaskRequestSuccessHandler<TArgs>
}) => {
  const [streamEventId, setStreamEventId] = useState('')
  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)
  const [loading, setLoading] = useState(false)
  const [eventData, setEventData] = useState<SuccessfulDataUpdateEvent>()
  const [error, setError] = useState<FailedDataUpdateEvent | Error>()
  const currentArgs = useRef<TArgs>()

  const handleOperation = useCallback(
    async (...args: TArgs) => {
      currentArgs.current = args

      let operationResult
      try {
        setFinished(false)
        setStarted(true)
        setLoading(true)
        setEventData(undefined)
        operationResult = await requestFunction(...args)
      } catch (ex) {
        setStarted(false)
        setFinished(true)
        setError(ex as Error)
        return
      } finally {
        setLoading(false)
      }

      setError(undefined)

      if (!isResponseTaskResult(operationResult)) {
        const err = new Error(
          'API Response is not a task result for usePusherTaskOperation',
        )
        setError(err)

        if (onError) {
          onError(err)
          return
        } else {
          throw err
        }
      }

      setStreamEventId(operationResult.stream_event_id)
    },
    [onError, requestFunction],
  )

  const handlePusherEventReceived = useCallback(
    (event: DataUpdateEvent | unknown) => {
      if (!isDataUpdateEvent(event)) {
        return
      }

      if (event.update?.event_id !== streamEventId) {
        return
      }

      setFinished(true)
      setStarted(false)
      setStreamEventId('')

      if (!event.success) {
        setError(event)
        onError?.(event)
        return
      }

      setError(undefined)
      setEventData(event)
      onSuccess?.(event, ...((currentArgs.current || []) as TArgs))
    },
    [onError, onSuccess, streamEventId],
  )

  useSubscribeToTenantEvent<DataUpdateEvent>(
    PUSHER_EVENT_CELL_DATA_UPDATE,
    handlePusherEventReceived,
  )

  const processing = loading || (!finished && started)

  return useMemo(
    () => ({
      streamEventId,
      loading,
      processing,
      started,
      finished,
      eventData,
      error,
      handleOperation,
    }),
    [
      error,
      eventData,
      finished,
      handleOperation,
      loading,
      processing,
      started,
      streamEventId,
    ],
  )
}

export type TaskRequestFunction<TArgs extends any[] = []> = (
  ...args: TArgs
) => Promise<{ stream_event_id: string }>
export type TaskRequestErrorHandler = (
  error: FailedDataUpdateEvent | Error,
) => void
export type TaskRequestSuccessHandler<TArgs extends any[] = []> = (
  data: SuccessfulDataUpdateEvent,
  ...args: TArgs
) => void
export type TaskResult = { stream_event_id: string }

const isResponseTaskResult = (obj: unknown): obj is TaskResult =>
  typeof (obj as TaskResult)?.stream_event_id === 'string'
