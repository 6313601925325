import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setIsDiscussionsOpened, setIsHistoryOpened } from './index'

export function useCloseLateralOnDestroy() {
  const dispatch = useDispatch()
  useEffect(
    () => () => {
      dispatch(setIsDiscussionsOpened(false))
      dispatch(setIsHistoryOpened(false))
    },
    [dispatch],
  )
}
