import React from 'react'
import { ViewLayout } from '../../shared/ViewLayout'
import { ValueDataFormatSettings } from '../../shared/settings/value-data-format'
import { useDataContext } from '../connection/data/data-context'
import { Currency } from '@fintastic/shared/data-access/currencies'
import { useActionsContext } from '../connection/actions/actions-context'
import { MetricConfigurableDataValueType } from '@fintastic/web/util/metrics-and-lists'

const defaultCurrenciesList: Currency[] = []

export const MetricValueDataFormat: React.FC = () => {
  const { metric, currenciesList } = useDataContext()

  const { readonly, actions, flow } = useActionsContext()

  if (!metric) {
    return null
  }

  return (
    <ViewLayout title={'Value data format'}>
      <ValueDataFormatSettings
        allowDataTypeConversions={flow === 'creation'}
        readonly={readonly}
        onChangeDataType={actions.changeValueDataType}
        onChangeFormatting={actions.changeValueDataFormatting}
        onChangeCurrencySignPosition={actions.changeCurrencySignPosition}
        currenciesList={currenciesList || defaultCurrenciesList}
        flow={flow}
        dataType={metric.dataType() as MetricConfigurableDataValueType}
        displaySettings={metric.displaySettings()}
      />
    </ViewLayout>
  )
}
