import { GridApi } from 'ag-grid-community'
import { MetricGridRow } from '../../types'
import { ValueColumnFieldAndRollUp } from '../grid-columns/types'
import { calculateTotalRow } from './calculateTotalRow'
import { Maybe } from '@fintastic/shared/util/types'

// @todo add tests
export function updateTotalRowData(
  api: GridApi<MetricGridRow>,
  valueColumnsWithRollups: ValueColumnFieldAndRollUp[],
) {
  const rowsToAggregate: Array<Record<string, string | number>> = []
  api.forEachNodeAfterFilter((row) => {
    if (row.group || !row.data) {
      return
    }
    rowsToAggregate.push(row.data as Record<string, string | number>)
  })

  const bottomData = calculateTotalRow(rowsToAggregate, valueColumnsWithRollups)
  if (valueColumnsWithRollups.length > 0) {
    addTotalRowLabel(api, valueColumnsWithRollups[0]?.field, bottomData)
  }

  api.setPinnedBottomRowData([bottomData])
}

function addTotalRowLabel(
  api: GridApi<MetricGridRow>,
  firstColumnField: string,
  mutableRowObject: Record<string, string | number>,
) {
  const firstColumnDef = api.getColumnDefs()?.[0]
  let firstNonValueColumnField: Maybe<string> = null
  if (
    firstColumnDef &&
    !('children' in firstColumnDef) &&
    firstColumnDef.field !== firstColumnField
  ) {
    firstNonValueColumnField = firstColumnDef.field || null
  }

  if (firstNonValueColumnField) {
    // eslint-disable-next-line no-param-reassign
    mutableRowObject[firstNonValueColumnField] = 'Total'
  }
}
