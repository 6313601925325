import React from 'react'
import {
  StyledRoot,
  StyledButton,
  StyledText,
  StyledLabel,
} from './Header.styled'
import { HistoryCloseIcon } from '@fintastic/shared/ui/icons'

export const Header: React.FC<{ onClose: () => void; label: string }> = ({
  onClose,
  label,
}) => (
  <StyledRoot>
    <StyledButton onClick={onClose}>
      <HistoryCloseIcon />
    </StyledButton>
    <StyledText>
      <b style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>History</b>
      <StyledLabel>{label}</StyledLabel>
    </StyledText>
  </StyledRoot>
)
