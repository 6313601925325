import { environment } from '@fintastic/web/data-access/environment'
import { PusherProviderProps } from '@harelpls/use-pusher'

export const pusherProviderConfig: PusherProviderProps = {
  // required config props
  clientKey: environment.REACT_APP_PUSHER_APP_KEY,
  cluster: 'eu',
  enabledTransports: ['ws'],
  forceTLS: true,

  // optional if you'd like to trigger events. BYO endpoint.
  // see "Trigger Server" below for more info
  //triggerEndpoint: '/pusher/trigger',

  // required for private/presence channels
  // also sends auth headers to trigger endpoint
  //authEndpoint: '/pusher/auth',
  //auth: {
  //  headers: { Authorization: 'Bearer token' },
  //},
}
