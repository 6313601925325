import React, { useCallback, useMemo, useState } from 'react'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { MetricChartSortableColumn } from './MetricChartSortableColumn'
import {
  StyledSorter,
  StyledSorterHeaders,
  StyledSorterPanel,
  StyledSorterTitle,
} from './MetricChartColumns.styled'
import { MetricChartDimensions } from '../../../../types'
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'

export type MetricChartColumnsUIProps = {
  disabled?: boolean
  dimensions: MetricChartDimensions
  onSetDimensionsOrder: (dimensions: MetricChartDimensions) => void
  onSetDimensionAggregateState: (id: string, checked: boolean) => void
}

const columnLineHeight = 32

export const MetricChartSortableColumns: React.FC<
  MetricChartColumnsUIProps
> = ({
  disabled,
  dimensions,
  onSetDimensionsOrder,
  onSetDimensionAggregateState,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const [inDrag, setInDrag] = useState(false)

  const handleDragStart = useCallback(() => {
    setInDrag(() => true)
  }, [])

  const draggableDims = useMemo(
    () => dimensions.filter((d) => d.type !== 'Version' && d.type !== 'Time'),
    [dimensions],
  )

  const versionDim = useMemo(
    () => dimensions.find((d) => d.type === 'Version'),
    [dimensions],
  )

  const timeDim = useMemo(
    () => dimensions.find((d) => d.type === 'Time'),
    [dimensions],
  )

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const oldIndex = dimensions.findIndex((dim) => dim.id === active.id)
        const newIndex = dimensions.findIndex((dim) => dim.id === over?.id)

        const newData = arrayMove(dimensions, oldIndex, newIndex)
        onSetDimensionsOrder(newData)
      }
      setInDrag(() => false)
    },
    [dimensions, onSetDimensionsOrder],
  )

  return (
    <StyledSorter disabled={disabled}>
      <StyledSorterTitle>Chart data</StyledSorterTitle>

      <StyledSorterHeaders>
        <span>Versions and dimensions</span>
        <span>Aggregate</span>
      </StyledSorterHeaders>

      <StyledSorterPanel
        className={
          'ag-theme-alpine ag-column-drop-vertical-list' +
          (inDrag ? ' dragging' : 'not-dragging')
        }
        style={{
          height: dimensions.length * columnLineHeight + 'px',
        }}
        data-testid="dimensions-sorter-panel"
      >
        {versionDim && (
          <MetricChartSortableColumn
            key={versionDim.id}
            dimension={versionDim}
            onSetDimensionAggregateState={onSetDimensionAggregateState}
            staticColumn={true}
          />
        )}

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[
            restrictToVerticalAxis,
            restrictToFirstScrollableAncestor,
          ]}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext
            items={draggableDims}
            strategy={verticalListSortingStrategy}
          >
            {draggableDims.map((dimension) => (
              <MetricChartSortableColumn
                key={dimension.id}
                dimension={dimension}
                onSetDimensionAggregateState={onSetDimensionAggregateState}
              />
            ))}
          </SortableContext>
        </DndContext>

        {timeDim && (
          <MetricChartSortableColumn
            key={timeDim.id}
            dimension={timeDim}
            onSetDimensionAggregateState={onSetDimensionAggregateState}
            staticColumn={true}
          />
        )}
      </StyledSorterPanel>
    </StyledSorter>
  )
}
