import React, { useCallback, useMemo, useState } from 'react'
import {
  StyledWidgetTab,
  StyledWidgetTabContent,
  StyledWidgetTabs,
  StyledWidgetTabsRoot,
} from './WidgetTabs.styled'
import { WidgetTabProp } from './WidgetTab'
import { IconButton, Tooltip } from '@mui/material'

export type WidgetTabsProps = {
  children:
    | React.ReactElement<WidgetTabProp>[]
    | React.ReactElement<WidgetTabProp>
}

const filterValidTabs = (tabProp: WidgetTabProp) =>
  !!tabProp.id && !!tabProp.icon && !!tabProp.children

export const WidgetTabs: React.FC<WidgetTabsProps> = (props) => {
  const [currentTabId, setCurrentTabId] = useState('')

  const validTabs = useMemo(
    () =>
      (
        React.Children.map(
          props.children,
          (child: React.ReactElement<WidgetTabProp>) => child.props,
        ) || []
      ).filter(filterValidTabs),
    [props.children],
  )

  const handleToggleActive = useCallback(
    (id: string) => {
      if (!currentTabId) {
        setCurrentTabId(() => id)
        return
      }
      if (currentTabId === id) {
        setCurrentTabId(() => '')
      } else {
        setCurrentTabId(() => id)
      }
    },
    [currentTabId],
  )

  const currentTabContent = useMemo(
    () => validTabs.find((tab) => tab.id === currentTabId && !tab.disabled),
    [currentTabId, validTabs],
  )

  if (!validTabs || validTabs.length === 0) {
    return null
  }

  return (
    <StyledWidgetTabsRoot data-testid="widget-tabs">
      {currentTabContent && (
        <StyledWidgetTabContent>
          {currentTabContent.children}
        </StyledWidgetTabContent>
      )}

      <StyledWidgetTabs>
        {validTabs.map((tab) => (
          <StyledWidgetTab
            key={tab.id}
            disabled={tab.disabled}
            highlight={tab.highlight}
            active={tab.id === currentTabId && tab.disabled !== true}
          >
            <Tooltip title={tab.title} placement="top" enterDelay={600}>
              <IconButton onClick={() => handleToggleActive(tab.id)}>
                {tab.icon}
              </IconButton>
            </Tooltip>
          </StyledWidgetTab>
        ))}
      </StyledWidgetTabs>
    </StyledWidgetTabsRoot>
  )
}
