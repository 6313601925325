import React from 'react'
import { ViewLayout } from '../../../shared/ViewLayout'
import { AggregationFunctionSettings } from '../../../shared/settings/aggregation-function'
import { useTimeAggregation } from './useTimeAggregation'
import { useCategoryAggregation } from './useCategoryAggregation'
import { useDataContext } from '../../connection/data/data-context'
import { useActionsContext } from '../../connection/actions/actions-context'
import {
  MetricAsOption,
  MetricConfigurableDataValueType,
} from '@fintastic/web/util/metrics-and-lists'

const defaultMetricsList: MetricAsOption[] = []

export const MetricAggregationFunction: React.FC = () => {
  const { metricsList, metric } = useDataContext()
  const { readonly } = useActionsContext()
  const timeAggregation = useTimeAggregation()
  const categoryAggregation = useCategoryAggregation()

  if (!metric) {
    return null
  }

  return (
    <ViewLayout title={'Aggregation function'}>
      <AggregationFunctionSettings
        timeAggregation={timeAggregation}
        categoryAggregation={categoryAggregation}
        metricsList={metricsList || defaultMetricsList}
        readonly={readonly}
        metricHasTimeDimension={metric.hasTimeDimension()}
        dataType={metric.dataType() as MetricConfigurableDataValueType}
      />
    </ViewLayout>
  )
}
