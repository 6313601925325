import React, { useCallback, useMemo } from 'react'
import { Actions } from '../../connection/actions/types'
import {
  ListColumnWrapper,
  MetricOrListEditingFlow,
} from '@fintastic/web/data-access/metrics-and-lists'
import { useLocalColumnActions } from './useLocalColumnActions'
import { Box, Divider } from '@mui/material'
import {
  StyledNameAndType,
  StyledSubsections,
  StyledBreakdown,
  StyledFooter,
} from './ColumnSettings.styled'
import { BreakdownByTime } from './BreakdownByTime'
import { Footer } from './Footer'
import { SubsectionsNavigation } from './SubsectionsNavigation'
import {
  MetricOrListSource,
  ColumnType,
} from '@fintastic/web/util/metrics-and-lists'
import { TypeInput } from './TypeInput'
import { NameInput } from './NameInput'
import { DimensionInput } from './DimensionInput/DimensionInput'
import { Maybe } from '@fintastic/shared/util/types'
import {
  VersionCategoryDimension,
  VersionDimension,
} from '@fintastic/web/util/dimensions'

export type ColumnSettingsProps = {
  listSource: MetricOrListSource
  column: ListColumnWrapper
  actions: Actions
  editingFlow: Maybe<MetricOrListEditingFlow>
  readonly: boolean
  allowExistingColumnsDeletion: boolean
  onGoToRoute: (routePath: string) => void
  dimensionsList: VersionDimension[]
  restExistingColumnNames: string[]
  anotherInputColumnsExist: boolean
  listHasBaseTimeDimension: boolean
}

export const ColumnSettings: React.FC<ColumnSettingsProps> = ({
  listSource,
  column,
  readonly,
  actions,
  onGoToRoute,
  dimensionsList,
  restExistingColumnNames,
  anotherInputColumnsExist,
  listHasBaseTimeDimension,
  allowExistingColumnsDeletion,
}) => {
  const columnType = column.type()
  const isNewColumn = column.isNewColumn()
  const localActions = useLocalColumnActions(column.clientOnlyId(), actions)

  const selectableDimensions = useMemo(
    () =>
      // @todo add selected range dim for not new column
      dimensionsList.filter((d) => d.type === 'Category'),
    [dimensionsList],
  )

  const handleChangeType = useCallback(
    (newType: ColumnType) => {
      localActions.changeType(
        newType,
        newType === 'dimension' ? selectableDimensions[0].id : undefined,
      )
    },
    [localActions, selectableDimensions],
  )

  const handleChangeTimeBreakdown = useCallback(
    (applyBreakdown: boolean) => {
      actions.changeColumnTimeBreakdown(column.clientOnlyId(), applyBreakdown)
    },
    [actions, column],
  )

  return (
    <>
      <StyledNameAndType>
        <TypeInput
          value={columnType}
          onChange={handleChangeType}
          readonly={readonly || !isNewColumn}
          disableCalculatedColumnType={
            columnType !== 'calculated' && !anotherInputColumnsExist
          }
        />
        {columnType === 'dimension' && (
          <Box mt={1.5}>
            <DimensionInput
              value={column.valuesDimensionId()}
              onChange={localActions.changeDimension}
              dimensionsList={selectableDimensions}
              readonly={!isNewColumn || readonly}
            />
          </Box>
        )}
        <Box mt={1.5} mb={1}>
          <NameInput
            value={column.label()}
            onChange={localActions.changeName}
            readonly={!isNewColumn || readonly}
            optional={columnType === 'dimension'}
            existingNames={restExistingColumnNames}
          />
        </Box>
      </StyledNameAndType>
      {columnType !== 'dimension' && (
        <>
          <Divider sx={{ mt: 2, mb: 1 }} />
          <StyledSubsections>
            <SubsectionsNavigation
              onGoToRoute={onGoToRoute}
              columnId={column.clientOnlyId()}
              columnDataType={column.dataType()}
              columnCategoryAggregation={column.categoryAggregation()}
            />
          </StyledSubsections>

          <Divider sx={{ mt: 1, mb: 2 }} />
          <StyledBreakdown>
            <BreakdownByTime
              readonly={
                !listHasBaseTimeDimension ||
                readonly ||
                column.isCalculated() ||
                !column.isNewColumn()
              }
              onChange={handleChangeTimeBreakdown}
              isApplied={column.hasTimeDimension()}
            />
          </StyledBreakdown>
        </>
      )}
      {listSource === 'input' &&
        (readonly ? allowExistingColumnsDeletion : column.isNewColumn()) && (
          <>
            <Divider sx={{ my: 2 }} />
            <StyledFooter>
              <Footer onRemove={localActions.remove} />
            </StyledFooter>
          </>
        )}
    </>
  )
}
