import { Box, Container, Paper, styled } from '@mui/material'

export const StyledAppLoaderRoot = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[200],
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledAppLoaderForm = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  disableGutters: true,
  flexGrow: 1,
}))

export const StyledAppLoaderPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
}))

export const StyledAppLoaderFooter = styled(Box)(({ theme }) => ({
  justifySelf: 'flex-end',
  textAlign: 'center',
  padding: `${theme.spacing(4, 'px')} 0 ${theme.spacing(3, 'px')}`,
}))
