import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  AddLinesState,
  AddLinesTargetApi,
} from '@fintastic/web/feature/list-add-lines'
import { AddLineDrawerTitle } from '../AddDrawerTitle'
import {
  useLoadVersion,
  useLoadVersionEntities,
} from '@fintastic/web/data-access/versions'
import { LinearProgress } from '@mui/material'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import {
  StyledContainerRoot,
  StyledError,
  StyledGridContainer,
} from './AddLinesConnector.styled'
import {
  AddLinesGrid,
  AddLinesHandlerProps,
} from '../AddLinesGrid/AddLinesGrid'
import { useLoadListWithoutData } from '@fintastic/web/data-access/metrics-and-lists'
import {
  ConfirmDiscardChangesModal,
  ConfirmPartialSaveModal,
  ConfirmRemoveLinesModal,
  ConfirmSaveOrphanedRecordsModal,
  SaveErrorFatalModal,
  SaveErrorRecoverableModal,
} from '../modals'
import {
  AddLinesColDef,
  ColumnsIdStructure,
  createAddLinesColumnDefs,
  ExtendedColWithOptionalChildren,
  getColumnsIdStructure,
} from '../../columns'
import { useListDimensionsPermissionsApi } from '@fintastic/web/feature/lists'
import { getPeriodSelectorDataPeriodList } from '@fintastic/web/feature/period-selector'
import { isEqual, sortBy } from 'lodash'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { useSaveNewRows } from '../../hooks/useSaveNewRows'
import {
  CantSaveDataPermissionError,
  CantSaveDataUnknownError,
  RecoverableSelectedPermissionsDeleted,
} from '../../consts'
import { MetricWithoutData } from '@fintastic/web/util/metrics-and-lists'
import {
  DrawerNotification,
  DrawerNotifications,
} from '@fintastic/shared/ui/drawer-framework'
import { Maybe } from '@fintastic/shared/util/types'
import { useLayoutStateIsBottomDrawerOpened } from '@fintastic/shared/ui/app-layout-framework'
import { ConfirmReplaceTargetModal } from '../modals/flow'

export type AddLineConnectorProps = {
  versionId: string
  listId: string
  targetApi: AddLinesTargetApi
  addLinesData: AddLinesState
}

export const AddLinesConnector: React.FC<AddLineConnectorProps> = memo(
  ({ versionId, listId, targetApi, addLinesData }) => {
    const currentColDefs = useRef<AddLinesColDef[]>([])
    const currentColIds = useRef<ColumnsIdStructure>([])

    const usedBaseColumns = useRef<MetricWithoutData[]>([])
    const currentBaseColumns = useRef<MetricWithoutData[]>([])

    const destroyed = useRef(false)

    useEffect(
      () => () => {
        destroyed.current = true

        currentColDefs.current = []
        currentColIds.current = []
        usedBaseColumns.current = []
        currentBaseColumns.current = []
      },
      [],
    )

    // to get: version name
    const versionQuery = useLoadVersion(versionId)

    const versionName = useMemo(() => {
      if (!versionQuery.data) {
        return ''
      }
      return versionQuery.data.name
    }, [versionQuery.data])

    // to get: base_time_dimension_id, list name, all Column Ids
    const listParams = useLoadListWithoutData(
      versionId,
      listId,
      undefined,
      true,
    )

    const { listBaseTimeDimensionId, listName, allColumns } = useMemo<{
      listBaseTimeDimensionId: Maybe<string>
      listName: string
      allColumns: MetricWithoutData[]
    }>(
      () => ({
        listBaseTimeDimensionId:
          listParams[0].data?.list?.metadata?.base_time_dimension_id || null,

        listName: listParams[0].data?.list?.label || listId,

        allColumns: listParams[0].data?.list?.metrics || [],
      }),
      [listId, listParams],
    )

    const versionEntities = useLoadVersionEntities(versionId)
    const [isDrawerOpened] = useLayoutStateIsBottomDrawerOpened()

    useEffect(() => {
      if (!isDrawerOpened) {
        return
      }

      if (
        !versionEntities.data ||
        versionEntities?.data.lists?.length === 0 ||
        !listId
      ) {
        return
      }

      const listInVersion = versionEntities.data?.lists.find(
        (l) => l.id === listId,
      )

      if (!listInVersion) {
        toast.error(
          `List ${listName || listId || ''} has been deleted by another user`,
        )
        currentColDefs.current = []
        currentColIds.current = []
        usedBaseColumns.current = []
        currentBaseColumns.current = []

        targetApi.targetFlow.closeTarget()
      }
    }, [
      isDrawerOpened,
      listId,
      listName,
      targetApi.targetFlow,
      versionEntities,
    ])

    const versionDimensions = useMemo(
      () => versionEntities.data?.dimensions || [],
      [versionEntities.data],
    )

    const dimensionsPermissions = useListDimensionsPermissionsApi(
      versionId,
      listId,
    )

    const localPeriodSelection = useMemo(() => {
      if (!addLinesData.periodSelection.selection) {
        return null
      }

      if (!listBaseTimeDimensionId) {
        return null
      }

      return {
        ...addLinesData.periodSelection.selection,
        aggregationDimensionId: listBaseTimeDimensionId,
      }
    }, [listBaseTimeDimensionId, addLinesData])

    const assumedPeriodList = useMemo(() => {
      if (
        !localPeriodSelection ||
        !addLinesData.calendarConfig.calendarConfig
      ) {
        return []
      }
      return getPeriodSelectorDataPeriodList(
        addLinesData.calendarConfig.calendarConfig,
        localPeriodSelection,
      )
    }, [addLinesData.calendarConfig, localPeriodSelection])

    const [showStructureChanged, setShowStructureChanged] =
      useState<boolean>(false)

    const colDefs = useMemo(() => {
      if (!allColumns || allColumns.length === 0 || !versionDimensions) {
        return []
      }

      const colDefs = createAddLinesColumnDefs({
        versionId,
        versionDimensions,
        timeDimensionId: listBaseTimeDimensionId || null,
        dimensionsPermissions,
        setCellValue: addLinesData.data.setCellValue,
        columns: allColumns,
        currentPeriodDims: assumedPeriodList,
      })
      const columnIds = getColumnsIdStructure(
        colDefs as ExtendedColWithOptionalChildren[],
      )

      if (isEqual(columnIds, currentColIds.current)) {
        return currentColDefs.current
      }

      currentColDefs.current = colDefs
      currentColIds.current = columnIds

      // prepare current list sorted
      usedBaseColumns.current = sortBy(allColumns, 'id')

      // we need data structure to extract mandatory fields
      addLinesData.data.updateMandatoryFields(columnIds)

      return currentColDefs.current
    }, [
      allColumns,
      versionDimensions,
      versionId,
      listBaseTimeDimensionId,
      dimensionsPermissions,
      addLinesData.data,
      assumedPeriodList,
    ])

    useEffect(() => {
      const areBaseColumnsEqual = isEqual(
        usedBaseColumns.current,
        currentBaseColumns.current,
      )

      if (!areBaseColumnsEqual && currentBaseColumns.current.length > 0) {
        // compare base columns set (without time breaking)
        setShowStructureChanged(() => true)
      }

      currentBaseColumns.current = [...usedBaseColumns.current]
    }, [allColumns])

    const { saveNewRows } = useSaveNewRows(versionId, listId)

    const [openSaveErrorRecoverable, setOpenSaveErrorRecoverable] =
      useState(false)

    const handleDiscardSaveRecoverable = useCallback(() => {
      setOpenSaveErrorRecoverable(false)
      currentColDefs.current = []
      currentColIds.current = []
      usedBaseColumns.current = []
      currentBaseColumns.current = []

      targetApi.targetFlow.closeTarget()
    }, [targetApi.targetFlow])

    const handleContinueSaveRecoverable = useCallback(() => {
      setOpenSaveErrorRecoverable(false)
    }, [])

    const [openSaveErrorFatal, setOpenSaveErrorFatal] = useState(false)
    const handleCloseSaveErrorFatal = useCallback(() => {
      setOpenSaveErrorFatal(false)
      currentColDefs.current = []
      currentColIds.current = []
      usedBaseColumns.current = []
      currentBaseColumns.current = []

      targetApi.targetFlow.closeTarget()
    }, [targetApi.targetFlow])

    const handleSaveNewRecords = useCallback(async () => {
      addLinesData.data.setBusy(true)

      let result
      let userShouldDecide = false

      try {
        result = await saveNewRows(addLinesData.data, currentColIds.current)
      } catch (ex: unknown) {
        if (ex instanceof RecoverableSelectedPermissionsDeleted) {
          setOpenSaveErrorRecoverable(true)
          userShouldDecide = true
        }

        if (ex instanceof CantSaveDataPermissionError) {
          setOpenSaveErrorFatal(true)
          userShouldDecide = true
        }

        if (ex instanceof CantSaveDataUnknownError) {
          // @todo: other message?
          setOpenSaveErrorFatal(true)
          userShouldDecide = true
        }
      }
      addLinesData.data.setBusy(false)

      if (userShouldDecide) {
        return
      }

      if (!result) {
        toast.error('Something went wrong')
      }
      currentColDefs.current = []
      currentColIds.current = []
      usedBaseColumns.current = []
      currentBaseColumns.current = []

      targetApi.targetFlow.closeTarget()
    }, [addLinesData.data, saveNewRows, targetApi.targetFlow])

    const [showValidationColumn, setShowValidationColumn] = useState(false)

    const [openPartialSaveConfirmation, setOpenPartialSaveConfirmation] =
      useState(false)

    const handleConfirmPartialSave = useCallback(() => {
      setOpenPartialSaveConfirmation(() => false)
      void handleSaveNewRecords()
    }, [handleSaveNewRecords])

    const handleCancelPartialSave = useCallback(() => {
      setOpenPartialSaveConfirmation(() => false)
      setShowValidationColumn(() => true)
    }, [])

    const saveOrphanedColumnsConfirmed = useRef(false)
    const [openSaveOrphanedRecordsModal, setOpenSaveOrphanedRecordsModal] =
      useState(false)

    const gridHandlerRef = useRef<AddLinesHandlerProps>(null)

    const handleSaveButtonClick = useCallback(() => {
      const hasOrphanedColumns = addLinesData.data.hasOrphanedColumns(
        currentColIds.current,
      )
      if (hasOrphanedColumns && !saveOrphanedColumnsConfirmed.current) {
        setOpenSaveOrphanedRecordsModal(() => true)
        return
      }

      if (
        addLinesData.data.validRecordsCount !==
        addLinesData.data.totalRecordsCount
      ) {
        setOpenPartialSaveConfirmation(() => true)
        return
      }
      void handleSaveNewRecords()
    }, [addLinesData.data, handleSaveNewRecords])

    const handleConfirmSaveWithOrphanedRecords = useCallback(() => {
      setOpenSaveOrphanedRecordsModal(() => false)
      saveOrphanedColumnsConfirmed.current = true

      setTimeout(() => {
        handleSaveButtonClick()
      }, 20)
    }, [handleSaveButtonClick])

    const handleCancelSaveWithOrphanedRecords = useCallback(() => {
      setOpenSaveOrphanedRecordsModal(() => false)
      saveOrphanedColumnsConfirmed.current = false
    }, [])

    const [openDiscardChanges, setOpenDiscardChanges] = useState(false)

    const handleConfirmDiscard = useCallback(() => {
      setOpenDiscardChanges(() => false)

      currentColDefs.current = []
      currentColIds.current = []
      usedBaseColumns.current = []
      currentBaseColumns.current = []

      targetApi.targetFlow.closeTarget()
    }, [targetApi])

    const handleCancelDiscard = useCallback(() => {
      setOpenDiscardChanges(() => false)
    }, [])

    const changed = addLinesData.data.changed || false

    const handleShowDiscardConfirmation = useCallback(() => {
      if (changed) {
        setOpenDiscardChanges(() => true)
      } else {
        handleConfirmDiscard()
      }
    }, [changed, handleConfirmDiscard])

    const handleAddRows = useCallback(
      (numberOfRows: number) => {
        targetApi.addRows(numberOfRows)
      },
      [targetApi],
    )

    const [openRemoveLinesConfirmation, setOpenRemoveLinesConfirmation] =
      useState(false)

    const handleConfirmRemoveLines = useCallback(() => {
      setOpenRemoveLinesConfirmation(() => false)
      addLinesData.selection.removeSelectedRows()
    }, [addLinesData.selection])

    const handleCancelRemoveLines = useCallback(() => {
      setOpenRemoveLinesConfirmation(() => false)
    }, [])

    const handleDelete = useCallback(() => {
      if (addLinesData.selection.selectedRowIds.length === 0) {
        return
      }
      setOpenRemoveLinesConfirmation(() => true)
    }, [addLinesData.selection])

    const isLoading =
      versionQuery.isLoading || // version params (name, live_actuals)
      listParams[0].isLoading || // list params (label, base dimension id)
      dimensionsPermissions.loading ||
      versionEntities.isLoading // dimensions

    if (isLoading || !allColumns || allColumns.length === 0) {
      return (
        <LinearProgress
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}
        />
      )
    }

    if (!versionId || versionQuery.data?.uuid !== versionId) {
      return (
        <StyledError>
          <ErrorAlert title="Loading error" message="Version not found." />
        </StyledError>
      )
    }

    if (listParams[0].data?.list.id !== listId) {
      return (
        <StyledError>
          <ErrorAlert title="Loading error" message="List not found." />
        </StyledError>
      )
    }

    return (
      <StyledContainerRoot>
        <AddLineDrawerTitle
          totalRecordsCount={addLinesData.data.totalRecordsCount}
          selectedRecordsCount={addLinesData.selection.selectedRecordsCount}
          validRecordsCount={addLinesData.data.validRecordsCount}
          isBusy={addLinesData.data.busy}
          versionName={versionName}
          versionId={versionId}
          listName={listName || ''}
          onDiscardClick={handleShowDiscardConfirmation}
          onSaveClick={handleSaveButtonClick}
          onAddRows={handleAddRows}
          onDelete={handleDelete}
        />

        <DrawerNotifications>
          <DrawerNotification
            open={showStructureChanged}
            message={'The List structure has been updated by another user'}
            onClose={() => setShowStructureChanged(false)}
            severity={'warning'}
          />
        </DrawerNotifications>

        <StyledGridContainer>
          {colDefs.length > 0 && (
            <AddLinesGrid
              data={addLinesData.data.values}
              busy={addLinesData.data.busy}
              showValidationColumn={showValidationColumn}
              setSelection={addLinesData.selection.setSelectedRowIds}
              colDefs={colDefs}
              ref={gridHandlerRef}
              columnColors={addLinesData.columnColors.columnColors}
              periods={assumedPeriodList}
            />
          )}
        </StyledGridContainer>

        <ConfirmDiscardChangesModal
          open={openDiscardChanges}
          onCancel={handleCancelDiscard}
          onConfirm={handleConfirmDiscard}
        />

        <ConfirmPartialSaveModal
          open={openPartialSaveConfirmation}
          invalidRecordsCount={addLinesData.data.invalidRecordsCount}
          onCancel={handleCancelPartialSave}
          onConfirm={handleConfirmPartialSave}
        />

        <ConfirmSaveOrphanedRecordsModal
          open={openSaveOrphanedRecordsModal}
          onCancel={handleCancelSaveWithOrphanedRecords}
          onConfirm={handleConfirmSaveWithOrphanedRecords}
        />

        <ConfirmRemoveLinesModal
          open={openRemoveLinesConfirmation}
          linesCount={addLinesData.selection.selectedRowIds.length}
          onCancel={handleCancelRemoveLines}
          onConfirm={handleConfirmRemoveLines}
        />

        <SaveErrorFatalModal
          open={openSaveErrorFatal}
          onContinue={handleCloseSaveErrorFatal}
        />

        <SaveErrorRecoverableModal
          open={openSaveErrorRecoverable}
          onContinue={handleContinueSaveRecoverable}
          onDiscard={handleDiscardSaveRecoverable}
        />

        <ConfirmReplaceTargetModal
          open={targetApi.targetFlow.showReplaceTargetModal}
          onCancel={targetApi.targetFlow.resetSetTarget}
          onConfirm={targetApi.targetFlow.commitSetTarget}
        />
      </StyledContainerRoot>
    )
  },
)
