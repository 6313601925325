import React, { useCallback } from 'react'
import { StyledActionType } from './ActionType.styled'
import { SmartMultiselect } from '@fintastic/shared/ui/dropdowns'
import {
  HistoryLogAction,
  resolveHistoryLogActionLabel,
} from '@fintastic/web/util/history'

const eactionTypesOptions: { label: string; value: HistoryLogAction }[] = [
  'change_value' as const,
  'add_line' as const,
].map((actionType) => ({
  label: resolveHistoryLogActionLabel(actionType),
  value: actionType,
}))

export type UpdateTypeProps = {
  disabled?: boolean
  actionTypes?: HistoryLogAction[]
  onChangeActionType: (v: HistoryLogAction[] | null) => void
}

export const ActionType: React.FC<UpdateTypeProps> = ({
  disabled,
  actionTypes,
  onChangeActionType,
}) => {
  const handleAddActionType = useCallback(
    (v: HistoryLogAction[] | null) => {
      if (!v) {
        return
      }

      if (actionTypes) {
        onChangeActionType([...actionTypes, ...v])
      } else {
        onChangeActionType([...v])
      }
    },
    [onChangeActionType, actionTypes],
  )

  const handleRemoveActionType = useCallback(
    (v: HistoryLogAction[] | null) => {
      if (!v) {
        return
      }

      if (actionTypes) {
        onChangeActionType(actionTypes.filter((val) => !v.includes(val)))
      } else {
        onChangeActionType([])
      }
    },
    [onChangeActionType, actionTypes],
  )

  return (
    <StyledActionType>
      <SmartMultiselect
        value={actionTypes || []}
        onSelectOptions={handleAddActionType}
        onUnselectOptions={handleRemoveActionType}
        options={eactionTypesOptions}
        placeholder="Update"
        label="Update"
        disabled={disabled}
        autoWidth={true}
      />
    </StyledActionType>
  )
}
