import React, { ChangeEvent, memo, useCallback } from 'react'
import { StyledText, StyledTextField } from './ChartTypeSelect.styled'
import { MenuItem } from '@mui/material'
import {
  ChartType,
  ChartTypeKeys,
  ChartTypes,
  isChartType,
} from '../../../../types'
import { ChartIcons } from '../../../../icons'
import { FormField } from '@fintastic/shared/ui/form-framework'
import { defaultChartType } from '../../../../consts'
import { OnChangeChartSettings } from '../../../../internal-types'

export type ChartTypeSelectProps = {
  chartType: ChartType
  onChangeChartSettings: OnChangeChartSettings
  disabled?: boolean
}

export const ChartTypeSelect: React.FC<ChartTypeSelectProps> = memo(
  ({ chartType, onChangeChartSettings, disabled }) => {
    const handleSelect = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (isChartType(e.target.value)) {
          onChangeChartSettings({ type: e.target.value })
        }
      },
      [onChangeChartSettings],
    )

    return (
      <FormField fullWidth={true}>
        <StyledTextField
          id="chart-type-select"
          fullWidth
          value={chartType}
          size="small"
          select
          label="Chart type"
          defaultValue={defaultChartType}
          onChange={handleSelect}
          disabled={disabled}
        >
          {ChartTypeKeys.map((ct) => (
            <MenuItem key={ct} value={ct}>
              <StyledText data-testid={`chart-type-item-${ct}`}>
                {ChartIcons[ct]} {ChartTypes[ct].name}
              </StyledText>
            </MenuItem>
          ))}
        </StyledTextField>
      </FormField>
    )
  },
)
