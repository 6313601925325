import React, { memo, useMemo } from 'react'
import { StyledChartTable } from './ChartTable.styled'
import { LinearProgress } from '@mui/material'
import { ChartColumnData } from '../../types'
import { AgGridReact } from 'ag-grid-react'
import { AgGridThemeFintasticWrapper } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { ColDef } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'

type ChartTableProps = {
  loading: boolean
  data: Maybe<ChartColumnData>
}

export const ChartTable: React.FC<ChartTableProps> = memo(
  ({ loading, data }) => {
    const colDefs: ColDef[] = useMemo(() => {
      if (!data || data.length === 0) {
        return []
      }

      const cols: ColDef[] = data[0].map((d) => ({
        field: (d ?? '').toString(),
        headerName: (d ?? '').toString(),
      }))
      cols[0].flex = 1
      return cols
    }, [data])

    // @todo: dimensional format?
    const rowData = useMemo(() => {
      if (!data || data.length === 0) {
        return []
      }

      const columns = data[0] // time jan feb
      const mappedData: any[] = []
      data.forEach((row, idx) => {
        if (idx === 0) {
          return
        }
        const data = {}
        row.forEach((cell, colIdx) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data[columns[colIdx]] = cell
        })
        mappedData.push(data)
      })
      return mappedData
    }, [data])

    if (loading) {
      return (
        <StyledChartTable>
          <LinearProgress
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 2,
            }}
          />
        </StyledChartTable>
      )
    }

    return (
      <StyledChartTable>
        <AgGridThemeFintasticWrapper
          sx={{
            flex: 1,
            width: '100%',
            height: '100%',
          }}
          themeOptions={{ variant: 'default' }}
        >
          {({ defaultGridProps }) => (
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              {...defaultGridProps}
            />
          )}
        </AgGridThemeFintasticWrapper>
      </StyledChartTable>
    )
  },
)
