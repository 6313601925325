import { addValueToModel, getValueFromTheModel } from './filter-values-model'
import {
  areFilterModelsEqual,
  areFilterValuesEqual,
  isFilterEmpty,
  isFilterValueEmpty,
  filterHasMeaningfulValue,
} from './filter-value-utils'
import { mapFiltersToApiPayload } from './mapFiltersToApiPayload'

export const filterUtils = {
  mapFiltersToApiPayload,
  isFilterEmpty,
  isFilterValueEmpty,
  areFilterModelsEqual,
  addValueToModel,
  getValueFromTheModel,
  areFilterValuesEqual,
  filterHasMeaningfulValue,
}
