import { StyledDrawer, StyledDrawerContent } from './BottomDrawer.styled'
import {
  layoutConfig,
  useLayoutStateIsBottomDrawerOpened,
} from '@fintastic/shared/ui/app-layout-framework'
import React, {
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { ResizeHandler } from './Resize/ResizeHandler'
import { useLocalStorage } from 'usehooks-ts'
import { Maybe } from '@fintastic/shared/util/types'

export type BottomDrawerProps = {
  pageRef?: MutableRefObject<Maybe<HTMLDivElement>>
} & PropsWithChildren

export const BottomDrawer: React.FC<BottomDrawerProps> = ({
  pageRef,
  children,
}) => {
  const [isDrawerOpened] = useLayoutStateIsBottomDrawerOpened()
  const drawerRef = useRef<HTMLDivElement>(null)

  // @todo: maybe diff height per payload/payload type?
  const [height, setHeight] = useLocalStorage<Maybe<number>>(
    'bottom_drawer_height',
    layoutConfig.bottomdrawer.height,
  )

  const applySize = useCallback(() => {
    const appLayoutPageContent = pageRef?.current

    if (!appLayoutPageContent) {
      return
    }

    const h = height || layoutConfig.bottomdrawer.height

    appLayoutPageContent.style.paddingBottom = isDrawerOpened ? h + 'px' : '0'
    if (drawerRef.current) {
      drawerRef.current.style.height = h + 'px'
    }
  }, [height, isDrawerOpened])

  useEffect(() => {
    applySize()
  }, [applySize, isDrawerOpened])

  useEffect(() => {
    if (isDrawerOpened) {
      drawerRef.current?.classList.add('show')
    } else {
      drawerRef.current?.classList.remove('show')
    }
  }, [isDrawerOpened])

  const handleResizeEnd = useCallback(
    (height: number) => {
      setHeight(height)
      applySize()
    },
    [applySize, setHeight],
  )

  return (
    <StyledDrawer
      open={isDrawerOpened}
      ref={drawerRef}
      data-testid="layout-drawer"
    >
      <ResizeHandler onResizeEnd={handleResizeEnd} />
      <StyledDrawerContent>{children}</StyledDrawerContent>
    </StyledDrawer>
  )
}
