import type {
  ListMetricData,
  MergedDimensions,
  VersionMetricData,
} from './expand-metric-data'
import { Maybe } from '@fintastic/shared/util/types'
import { VersionEntities } from '@fintastic/web/util/versions'
import { uniq } from 'lodash'
import { idLooksLikeDimValueOther } from '@fintastic/web/util/dimensions'

export const getNotExistDimensions = (
  allMetricsDimensions: MergedDimensions,
  dimensionsSet: Record<string, string>,
  periodDim: string,
) => {
  const notExistDims = []

  for (const dimensionId in allMetricsDimensions) {
    if (dimensionId !== periodDim && dimensionsSet[dimensionId] === undefined) {
      notExistDims.push(dimensionId)
    }
  }

  return notExistDims
}

const getOtherDimensionValueId = (
  dimensionId: string,
  allMetricsDimensions: MergedDimensions,
): string =>
  allMetricsDimensions[dimensionId]?.find(idLooksLikeDimValueOther) || '_other'

export const fillNonExistDimsWithOther = (
  mutableDimensionsSet: Record<string, string>,
  notExistDims: string[],
  allMetricsDimensions: MergedDimensions,
) => {
  for (const dimensionId of notExistDims) {
    // eslint-disable-next-line no-param-reassign
    mutableDimensionsSet[dimensionId] = getOtherDimensionValueId(
      dimensionId,
      allMetricsDimensions,
    )
  }
}

export const getDimValuesFromMetricData = (
  m: VersionMetricData | ListMetricData,
  dimensionId: string,
): Maybe<string[]> => {
  const index = m.metricData?.indexes.indexOf(dimensionId)
  if (index === undefined || index === -1) {
    return null
  }

  return m.metricData?.dimensions[index] || null
}

export const mergeDimensions = (
  metrics: (VersionMetricData | ListMetricData)[],
  entities?: Maybe<
    Array<{
      entities?: VersionEntities
      versionId: string
    }>
  >,
): MergedDimensions => {
  const mergedDimensions: MergedDimensions = {}
  metrics.forEach((metric) => {
    if (metric.metricMetadata) {
      const { dimensions } = metric.metricMetadata
      const versionEntities = entities?.find?.(
        ({ versionId }) => versionId === metric.versionId,
      )

      dimensions?.forEach(({ id, values }) => {
        if (!mergedDimensions[id]) {
          mergedDimensions[id] = Object.keys(values)
        } else {
          const existingValues = mergedDimensions[id]
          const newValues = Object.keys(values)
          mergedDimensions[id] = uniq([...existingValues, ...newValues])
          const globalDimensionValues = Object.keys(
            versionEntities?.entities?.dimensions?.find(
              (globalDimensionItem) => globalDimensionItem.id === id,
            ) || {},
          )

          if (globalDimensionValues.length !== mergedDimensions[id].length) {
            mergedDimensions[id] = uniq([
              ...mergedDimensions[id],
              ...globalDimensionValues,
            ])
          }
        }
      })
    }
  })

  return mergedDimensions
}
