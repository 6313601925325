import { Box, Button, styled } from '@mui/material'

export const StyledSearchPanel = styled(Box)`
  width: 100%;
  margin: 0 0 ${({ theme }) => theme.spacing(1)} 0;
  display: flex;
  flex-flow: row nowrap;
`

export const StyledSearchInputContainer = styled(Box)`
  flex-grow: 1;
  padding: 0 0 0 24px;
`

export const StyledNewBoardButton = styled(Button)`
  width: 39px;
  height: 39px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 ${({ theme }) => theme.spacing(.5)};
  border-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-right: ${({ theme }) => theme.spacing(3)};

  &:hover, &:focus, &:focus-within {
    border-color: ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.primary.dark};
    background: #fff;
  }
`
