import { useAuthAccessToken } from '@fintastic/web/feature/auth'
import React, { useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { ChartDisplaySettings } from '../types'
import { defaultChartSettings } from '../consts'

export const useLocalChartDisplaySettings = (
  widgetId: string,
  referenceSettings?: Partial<ChartDisplaySettings>,
): readonly [
  ChartDisplaySettings,
  React.Dispatch<React.SetStateAction<ChartDisplaySettings>>,
] => {
  const { user } = useAuthAccessToken()

  const [cachedSettings, setCachedSettings] =
    useLocalStorage<ChartDisplaySettings>(
      `chartDisplaySettings_${widgetId}_${user?.email}`,
      {},
    )

  const validatedCachedSettings = useMemo(
    () => ({
      ...defaultChartSettings,
      ...{ type: referenceSettings?.type, theme: referenceSettings?.theme },
      ...cachedSettings,
    }),
    [referenceSettings, cachedSettings],
  )

  return [validatedCachedSettings, setCachedSettings] as const
}
