import { styled, Box } from '@mui/material'

export const StyledModalHeaderRoot = styled(Box)(({ theme }) => ({
  position: 'relative',

  width: '100%',
  minHeight: theme.spacing(3),

  padding: theme.spacing(3, 3, 0),
}))

export const StyledModalHeaderCloseButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
}))
