import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { MetricGridRow } from '@fintastic/web/util/metrics-and-lists'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import { useCallback, useMemo, useRef } from 'react'
import { hideMaskedValuesMemorized } from '@fintastic/web/util/metrics-and-lists'
import { parseList } from '@fintastic/web/util/metrics-and-lists'
import { updateColumns } from '@fintastic/web/feature/metrics-and-lists'
import { RowNode } from 'ag-grid-community'
import { useSubscribeToCalculationProgressEvent } from '@fintastic/web/data-access/service-pusher'
import {
  getListColumns,
  invalidateListColumnsCache,
} from '@fintastic/web/data-access/metrics-and-lists'
import keyBy from 'lodash/keyBy'
import { QueryClient, useQueryClient } from 'react-query'
import { ImmutableCalculationProgressEvent } from '@fintastic/web/data-access/calc'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useSubscribeOnCellDataUpdateEvent } from '@fintastic/web/data-access/metric-data-editing'
import {
  AddListRowsDataUpdate,
  FailedDataUpdateEventWrapper,
  SuccessfulDataUpdateEventWrapper,
} from '@fintastic/web/util/metric-data-editing'
import { range } from 'lodash'
import toast from 'react-hot-toast/headless'

type Context = {
  columnIds: string[]
  columns: Record<string, Metric>
  versionId: string
  rowDimension: Maybe<string>
  timeDimension?: string
  periodSelection: PeriodSelection
  listId: string
  currentUserEmail: string
}

export function useRowData(
  gridApi: Maybe<GridApi<MetricGridRow>> | undefined,
  context: Context,
): MetricGridRow[] {
  const { columnIds, columns, versionId, rowDimension, timeDimension, listId } =
    context
  const queryClient = useQueryClient()

  const visibleColumnsRef = useRef<Record<string, Metric>>({})
  const wholeTableRowsRef = useRef<Maybe<MetricGridRow[]>>(null)
  const prevTimeDimensionRef = useRef(timeDimension)
  const contextRef = useRef(context)
  contextRef.current = context

  useSubscribeToCalculationProgressEvent(
    [versionId],
    useCallback(
      async (event) => {
        await handleCalcProgressEvent(
          event,
          gridApi,
          queryClient,
          contextRef.current,
        )
      },
      [gridApi, queryClient],
    ),
  )

  useSubscribeOnCellDataUpdateEvent(
    [versionId],
    useCallback(
      async (event) => {
        if (event instanceof FailedDataUpdateEventWrapper) {
          return
        }

        if (
          event.updateData.action !== 'add_list_rows' &&
          event.updateData.action !== 'add_complete_list_rows'
        ) {
          return
        }

        if (event.updateData.list_id !== listId) {
          return
        }

        await handleAddRowEvent(event, gridApi, queryClient, contextRef.current)
      },
      [gridApi, listId, queryClient],
    ),
  )

  return useMemo(() => {
    if (rowDimension === null) {
      return wholeTableRowsRef.current || []
    }

    let changedColumns = columnIds.filter(
      (columnId) => columns[columnId] !== visibleColumnsRef.current[columnId],
    )

    if (prevTimeDimensionRef.current !== timeDimension) {
      prevTimeDimensionRef.current = timeDimension
      changedColumns = columnIds
    }

    const atLeastOneColumnLoadedFirstTime = changedColumns.some(
      (id) => visibleColumnsRef.current[id] === undefined,
    )

    if (
      atLeastOneColumnLoadedFirstTime ||
      changedColumns.length === columnIds.length
    ) {
      // update whole table data
      wholeTableRowsRef.current = getParsedListRows(context)

      gridApi?.deselectAll()

      console.log('full update', {
        newRows: wholeTableRowsRef.current,
        atLeastOneColumnLoadedFirstTime,
        changedColumns,
        columnIds,
      })
    } else if (changedColumns.length && gridApi) {
      // partial update of the columns
      const rowsToPartialUpdate = getParsedListRows({
        ...context,
        columnIds: changedColumns,
      })

      gridApi?.deselectAll()

      console.log('partial update', {
        partialRows: rowsToPartialUpdate,
        changedColumns,
        columnIds,
      })
      updateColumns(gridApi, rowsToPartialUpdate)
    }

    // always update the ref
    if (changedColumns.length) {
      visibleColumnsRef.current = Object.fromEntries(
        columnIds
          .filter((id) => Boolean(columns[id]))
          .map((id) => [id, columns[id]]),
      )
    }

    return wholeTableRowsRef.current || []
  }, [columnIds, columns, context, gridApi, rowDimension, timeDimension])
}

function getParsedListRows({
  columnIds,
  columns,
  versionId,
  rowDimension,
  timeDimension,
}: Context) {
  const maskedData = columnIds
    .filter((id) => Boolean(columns[id]))
    .map((id) => {
      const metric = columns[id]
      if (!metric.data) {
        return metric
      }

      metric.data.values = hideMaskedValuesMemorized(
        metric.data.values,
        metric.metadata.value.mask,
      )

      return metric
    })

  return (
    parseList(maskedData, rowDimension || '', versionId || '', timeDimension) ||
    []
  )
}

const handleCalcProgressEvent = async (
  event: ImmutableCalculationProgressEvent,
  gridApi: Maybe<GridApi<MetricGridRow>> | undefined,
  queryClient: QueryClient,
  context: Context,
) => {
  const shouldProceed =
    event.successful() &&
    (event.triggeredByDuplicateListRowsAction() ||
      event.triggeredByDeleteListRowsAction())

  if (!shouldProceed) {
    return
  }

  if (event.triggeredByDuplicateListRowsAction()) {
    await handleRowsDuplicationEvent(event, gridApi, queryClient, context)
  }

  if (event.triggeredByDeleteListRowsAction()) {
    await handleRowsDeletionEvent(event, gridApi)
  }
}

const handleRowsDuplicationEvent = async (
  event: ImmutableCalculationProgressEvent,
  gridApi: Maybe<GridApi<MetricGridRow>> | undefined,
  queryClient: QueryClient,
  context: Omit<Context, 'columns'>,
) => {
  const addedRows = event.createdListRows
  if (!addedRows || !gridApi) {
    return
  }

  // @todo remove after the stabilisation
  console.log('add rows to the local aggrid state', {
    event,
    addedRows,
    context,
  })

  let addedRowsData: Metric[]
  try {
    addedRowsData = (
      await getListColumns(
        context.versionId,
        addedRows.listId,
        context.columnIds,
        context.periodSelection,
        true,
        addedRows.rows,
      )
    ).data.result
  } catch (e) {
    console.error(e)
    await invalidateListColumnsCache(queryClient, {
      versionId: context.versionId,
      listId: addedRows.listId,
    })
    return
  }

  const parsedListRows = getParsedListRows({
    ...context,
    columns: keyBy(addedRowsData, 'id'),
  })

  gridApi.deselectAll()
  gridApi.applyTransaction({
    add: parsedListRows,
  })

  if (context.currentUserEmail === event.user) {
    highlightAddedRows(gridApi, addedRows.rows)
  }
}

const highlightAddedRows = (gridApi: GridApi, rowIds: string[]) => {
  if (!rowIds.length) {
    return
  }

  gridApi.ensureNodeVisible(
    (r: RowNode<MetricGridRow>) => r.id === rowIds[rowIds.length - 1],
    'bottom',
  )

  setTimeout(() => {
    gridApi.flashCells({
      rowNodes: rowIds
        .map((rowId) => gridApi.getRowNode(rowId))
        .filter((n) => !!n) as RowNode<MetricGridRow>[],
      fadeDelay: 500,
    })
  }, 100)
}

const handleRowsDeletionEvent = async (
  event: ImmutableCalculationProgressEvent,
  gridApi: Maybe<GridApi<MetricGridRow>> | undefined,
) => {
  if (!event.deletedListRows) {
    return
  }

  // @todo remove after the stabilisation
  console.log('remove rows from the local aggrid state', {
    event,
  })

  // may be triggered by another user
  gridApi?.applyTransaction({
    remove: event.deletedListRows.rows.map((rowId) => ({ _rowId: rowId })),
  })
}

const handleAddRowEvent = async (
  event: SuccessfulDataUpdateEventWrapper,
  gridApi: Maybe<GridApi<MetricGridRow>> | undefined,
  queryClient: QueryClient,
  context: Context,
) => {
  const dataUpdate = event.updateData as AddListRowsDataUpdate
  if (!gridApi) {
    return
  }

  const createdListRowsIds = range(
    dataUpdate.row_index_range[0],
    dataUpdate.row_index_range[1] + 1,
  ).map((index) => `${dataUpdate.row_dim}.${index}`)

  let addedRowsData: Metric[]
  try {
    addedRowsData = (
      await getListColumns(
        context.versionId,
        dataUpdate.list_id,
        context.columnIds,
        context.periodSelection,
        true,
        createdListRowsIds,
      )
    ).data.result
  } catch (e) {
    console.error(e)
    await invalidateListColumnsCache(queryClient, {
      versionId: context.versionId,
      listId: dataUpdate.list_id,
    })
    return
  }

  const parsedListRows = getParsedListRows({
    ...context,
    columns: keyBy(addedRowsData, 'id'),
  })

  gridApi.deselectAll()
  gridApi.applyTransaction({
    add: parsedListRows,
  })

  // eslint-disable-next-line no-restricted-globals
  if (context.currentUserEmail === event.userEmail) {
    setTimeout(() => {
      highlightAddedRows(gridApi, createdListRowsIds)
    }, 300)

    toast.success(
      `${createdListRowsIds.length} line${
        createdListRowsIds.length > 1 ? 's' : ''
      } added successfully`,
    )
  }
}
