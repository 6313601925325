import React, { useEffect } from 'react'
import { WidgetSelectRenderer } from './WidgetSelectRenderer'
import { ListOrMetricOrReportSelectProps } from './WidgetSelect'
import { useLoadListOfMetrics } from '@fintastic/web/data-access/metrics-and-lists'

export const WidgetSelectMetric: React.FC<ListOrMetricOrReportSelectProps> = (
  props,
) => {
  const { data: entityList, isLoading } = useLoadListOfMetrics(
    props.versionId || '',
    false,
  )

  useEffect(() => {
    setTimeout(() => {
      props.onLoadingUpdate?.(isLoading || (entityList || []).length === 0)
    }, 20)
  }, [entityList, isLoading, props])

  return (
    <WidgetSelectRenderer
      {...props}
      entityList={entityList || []}
      isLoading={isLoading || (entityList || []).length === 0}
      mode={'metric'}
    />
  )
}
