import React, { PropsWithChildren } from 'react'
import {
  StyledFormField,
  StyledFormFieldCont,
  StyledFormFieldLabel,
  StyledFormFieldLabelCont,
  StyledFormFieldRoot,
} from './FormField.styled'

export type FormFieldProps = PropsWithChildren & {
  label?: string
  fullWidth?: boolean
  placeholder?: string
  ['data-testid']?: string
}

export const FormField: React.FC<FormFieldProps> = (props) => {
  const { label, fullWidth, children } = props

  return (
    <StyledFormFieldRoot>
      {!fullWidth && !!label && <StyledFormFieldLabelCont>
        <StyledFormFieldLabel>{label}</StyledFormFieldLabel>
      </StyledFormFieldLabelCont>}
      <StyledFormFieldCont fullWidth={fullWidth}>
        <StyledFormField>
          {children}
        </StyledFormField>
      </StyledFormFieldCont>
    </StyledFormFieldRoot>
  )
}
