export class TooManyRecordsToEdit extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'TooManyRecordsToEdit'
    Object.setPrototypeOf(this, TooManyRecordsToEdit.prototype)
  }
}

// recoverable
export class RecoverableSelectedPermissionsDeleted extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'RecoverableSelectedPermissionsDeleted'
    Object.setPrototypeOf(this, RecoverableSelectedPermissionsDeleted.prototype)
  }
}

// not recoverable
export class CantSaveDataPermissionError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'CantSaveDataPermissionError'
    Object.setPrototypeOf(this, CantSaveDataPermissionError.prototype)
  }
}

export class CantSaveDataUnknownError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'CantSaveDataUnknownError'
    Object.setPrototypeOf(this, CantSaveDataUnknownError.prototype)
  }
}
