import { useQuery } from 'react-query'
import {
  useIsFeatureEnabled,
  useWhitelistedUsersList,
} from '@fintastic/web/feature/config'
import { useMemo } from 'react'
import { UserData } from '../../types'
import { isEmailAdmissible } from '@fintastic/web/data-access/environment'
import { getUsersList } from '../../api/users'
import { iamQueryKeys } from '../../utils/query-keys'

export const useUserListQuery = ({
  alwaysShowFintasticUsers,
}: {
  alwaysShowFintasticUsers?: boolean
} = {}) => {
  const usersFilteringFeatureEnabled = useIsFeatureEnabled(
    'hide_fintastic_users',
  )
  const whitelistedUsersList = useWhitelistedUsersList()

  const query = useQuery(iamQueryKeys.userList(), async () => {
    const response = await getUsersList()
    return response?.data?.result || []
  })

  const users = useMemo<UserData[]>(
    () =>
      (query.data || []).filter((u) => {
        if (alwaysShowFintasticUsers) {
          return true
        }
        if (!usersFilteringFeatureEnabled) {
          return true
        }
        if (whitelistedUsersList.includes(u.username.toLocaleLowerCase())) {
          return true
        }
        return isEmailAdmissible(u.username) === false
      }),
    [
      alwaysShowFintasticUsers,
      query.data,
      usersFilteringFeatureEnabled,
      whitelistedUsersList,
    ],
  )

  return {
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
    error: query.error,
    data: users,
  }
}
