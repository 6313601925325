import { AlertTitle } from '@mui/material'
import React, { memo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  StyledChartContent,
  StyledChartDataPanel,
  StyledChartError,
  StyledChartErrorAlert,
  StyledChartPanels,
} from './MetricChartContainer.styled'
import { ChartTable } from '../ChartTable/ChartTable'
import { ChartDebugParams } from '../ChartDebugParams/ChartDebugParams'
import { Chart } from '../ChartComponent/Chart'
import {
  ChartColumnData,
  ChartDisplayMode,
  ChartDisplaySettings,
  ChartRequestParams,
} from '../../types'
import { ErrorAlert } from '@fintastic/shared/ui/components'

type MetricChartPanelsProps = {
  dataError?: Maybe<boolean>
  data: Maybe<ChartColumnData>
  displayMode: ChartDisplayMode
  loading: boolean
  isDesignMode?: boolean
  displaySettings: ChartDisplaySettings
  params: Record<string, unknown>
  request: ChartRequestParams
  sidePanel: React.ReactNode
  tryAgainCallback?: () => void
}

// @todo: get rid of panels after charts stabilized
export const MetricChartPanels: React.FC<MetricChartPanelsProps> = memo(
  ({
    dataError,
    data,
    displayMode,
    loading,
    isDesignMode,
    displaySettings,
    params,
    request,
    sidePanel,
    tryAgainCallback,
  }) => (
    <StyledChartContent data-testid="chart-content">
      <StyledChartPanels>
        {dataError && (
          <StyledChartError>
            <ErrorAlert
              title={'Error'}
              message={'Could not retrieve data. Please try again later.'}
              tryAgainCallback={tryAgainCallback}
            />
          </StyledChartError>
        )}

        {!dataError && !data && (
          <StyledChartError>
            <StyledChartErrorAlert severity="info">
              <AlertTitle>No data</AlertTitle>
              Nothing to display.
            </StyledChartErrorAlert>
          </StyledChartError>
        )}

        {!dataError && (
          <StyledChartDataPanel>
            {displayMode === 'chart' && (
              <Chart
                loading={loading}
                isDesignMode={isDesignMode}
                displaySettings={displaySettings}
                data={data}
              />
            )}

            {displayMode === 'table' && (
              <ChartTable loading={loading} data={data} />
            )}

            {displayMode === 'debug' && (
              <ChartDebugParams
                params={params}
                request={request}
                loading={loading}
                data={data}
              />
            )}
          </StyledChartDataPanel>
        )}

        {sidePanel}
      </StyledChartPanels>
    </StyledChartContent>
  ),
)
