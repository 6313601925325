import {
  hideCompleteBlankRows,
  mergeMetricsToFlatRows,
  VersionMetric,
} from '../../../../utils/metrics'
import { useMemo, useRef } from 'react'
import { MetricGridRow } from '../../types'
import { allCalculated } from '../metric-source/utils'
import { useLoadMultipleVersionsEntities } from '@fintastic/web/data-access/versions'

type AdditionalContext = {
  settingsEditingActive: boolean
  timeDimension?: string
}

export function useTableRows(
  data: VersionMetric[],
  weightedAverageData: VersionMetric[] | undefined,
  { settingsEditingActive, timeDimension }: AdditionalContext,
) {
  const dataRef = useRef(data)
  dataRef.current = data
  const weightedAverageDataRef = useRef(weightedAverageData)
  weightedAverageDataRef.current = weightedAverageData

  const dataManualRef = useRef(data)
  const weightedAverageDataManualRef = useRef(weightedAverageData)

  const versionIds = useMemo(() => data.map((i) => i.versionId), [data])

  const entitiesQuery = useLoadMultipleVersionsEntities(versionIds, true)

  const reParseCounterRef = useRef(0)
  const reParseDataKey = useMemo(() => {
    if (
      dataManualRef.current === data &&
      weightedAverageDataManualRef.current === weightedAverageData
    ) {
      return reParseCounterRef.current
    }

    if (
      dataManualRef.current.length !== data.length ||
      weightedAverageDataManualRef.current?.length !==
        weightedAverageData?.length
    ) {
      dataManualRef.current = data
      weightedAverageDataManualRef.current = weightedAverageData
      reParseCounterRef.current += 1
      return reParseCounterRef.current
    }

    for (let i = 0; i < data.length; i += 1) {
      const el = data[i]
      if (
        // here we need to check only those fields that used for calculation
        dataManualRef.current[i].metricData !== el.metricData ||
        dataManualRef.current[i].metricId !== el.metricId ||
        dataManualRef.current[i].versionId !== el.versionId
      ) {
        dataManualRef.current = data
        weightedAverageDataManualRef.current = weightedAverageData
        reParseCounterRef.current += 1
        return reParseCounterRef.current
      }
    }

    if (weightedAverageData) {
      for (let i = 0; i < weightedAverageData.length; i += 1) {
        const el = weightedAverageData[i]
        if (
          // here we need to check only those fields that used for calculation
          weightedAverageDataManualRef.current?.[i].metricData !==
            el.metricData ||
          weightedAverageDataManualRef.current?.[i].metricId !== el.metricId ||
          weightedAverageDataManualRef.current?.[i].versionId !== el.versionId
        ) {
          dataManualRef.current = data
          weightedAverageDataManualRef.current = weightedAverageData
          reParseCounterRef.current += 1
          return reParseCounterRef.current
        }
      }
    }

    return reParseCounterRef.current
  }, [data, weightedAverageData])

  return useMemo<MetricGridRow[]>(() => {
    if (!entitiesQuery.data?.length) {
      return []
    }

    const data = settingsEditingActive ? dataRef.current : dataManualRef.current
    const weightedAverageData = settingsEditingActive
      ? weightedAverageDataRef.current
      : weightedAverageDataManualRef.current

    const rows = mergeMetricsToFlatRows(
      data,
      entitiesQuery.data,
      timeDimension,
      weightedAverageData,
    )

    if (settingsEditingActive) {
      return rows
    }

    return allCalculated(dataRef.current) ? hideCompleteBlankRows(rows) : rows
    // we need to put a manual dependency key here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reParseDataKey, settingsEditingActive, timeDimension, entitiesQuery.data])
}
