import { Box, BoxProps, styled } from '@mui/material'

export const StyledBoardNameEditContainer = styled(Box)`
  display: flex;
  margin-right: auto;
  align-items: center;
  position: relative;

  h1#reference-text {
    white-space: pre;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    z-index: -1;
    visibility: hidden;
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
    font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    padding: 0;
    margin: 0;
  }

  h1#static-text-name {
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
    font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    white-space: pre;
    padding: 0;
    margin: 0;
  }
`

export const StyledBoardNameEditBox = styled(
  (props: BoxProps & { allowEdit: boolean, designMode: boolean, hasError: boolean }) => {
    const { allowEdit, designMode, hasError, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName) => propName !== 'allowEdit' && propName !== 'designMode' && propName !== 'hasError',
  },
)`
  display: flex;
  cursor: ${({ allowEdit }) => allowEdit ? 'text' : 'pointer'};
  align-items: center;
  position: relative;
  margin: 0;

  &:after {
    display: ${({ designMode }) => designMode ? 'block' : 'none'};
    position: absolute;
    content: '';
    height: 2px;
    bottom: -2px;
    left: 0;
    right: 0;
    opacity: ${({ allowEdit }) => allowEdit ? 1 : .3};
    background: ${({ theme, hasError }) => hasError ? theme.palette.error.main : theme.palette.primary.main};
    transition: all .2s ease;
  }

  .MuiFormControl-root {
    border: none;
    border-radius: 0;

    .MuiInputBase-root {
      border: none;
      border-radius: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
      border-radius: 0;
    }
  }

  .MuiInputBase-input {
    margin-top: -0.5px;
    cursor: ${({ allowEdit }) => allowEdit ? 'pointer' : 'default'};
    background: transparent;
    border-radius: 0;
    border: none;
    position: relative;
    padding: 0;
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
    font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    width: auto;
    display: flex;
    flex-grow: 2;
    min-width: 2em;
  }
`

export const StyledNameErrorTooltip = styled(Box)`
  position: absolute;
  bottom: -22px;
  left: 0;
  color: ${({ theme }) => theme.palette.error.main};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`
