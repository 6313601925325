import React, { memo, useEffect, useState } from 'react'

import { MetricDimensionContent } from '../../../types'
import { useMetricDimensions } from '../../../hooks/useMetricDimensions'
import { MetricChartForm, MetricChartFormProps } from '../MetricChartForm'
import { DimensionsLoadingSkeleton } from './DimensionsLoadingSkeleton'
import { isEmpty } from 'lodash'

export type MetricChartFormWithDSProps = MetricChartFormProps & {
  onLoadingUpdate?: (loading: boolean) => void
  onError?: (error: string) => void
}

const errorMessage =
  'The list of dimensions cannot be resolved. Try to select another metric.'

export const MetricChartFormWithDataSource: React.FC<MetricChartFormWithDSProps> =
  memo((props) => {
    const { metricId, version, onLoadingUpdate, onError } = props
    const dimsKey = metricId + '_' + version

    const { dimensions, loading, error } = useMetricDimensions(metricId, [
      version || '',
    ])

    const [checkedDimsFor, setCheckedDimsFor] = useState('')

    // update parent form with loading and error states
    useEffect(() => {
      onLoadingUpdate?.(loading)
    }, [loading, onLoadingUpdate])

    useEffect(() => {
      onError?.(!loading && error ? errorMessage : '')
    }, [error, loading, onError])

    // loads the dimensions and compares them with the reference (original) dimensions
    useEffect(() => {
      if (loading || isEmpty(dimensions) || checkedDimsFor === dimsKey) {
        return
      }

      setCheckedDimsFor(() => dimsKey)

      const initialDimensions = props.initialSettings?.dimensions || []
      const isInitialDimensionsEmpty = initialDimensions.length === 0

      // check initial === loaded (all IDs are the same)
      const initialValid =
        initialDimensions.length === dimensions.length &&
        initialDimensions.every((initialDim: MetricDimensionContent) =>
          dimensions.find((d) => d.id === initialDim.id),
        )

      if (isInitialDimensionsEmpty || !initialValid) {
        props.onChangeChartSettings({
          dimensions,
        })
      }
    }, [loading, dimensions, props, checkedDimsFor, metricId, version, dimsKey])

    if (loading) {
      return <DimensionsLoadingSkeleton />
    }

    if (error || isEmpty(dimensions)) {
      return null
    }

    return <MetricChartForm {...props} />
  })
