import { useCurrentEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { unstable_usePrompt, useLocation, useParams } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { useStopEditingHandler } from '../exit-editing-mode/useStopEditingHandler'
import { useReportEditorApi } from '@fintastic/web/feature/report-editor'

const REPORT_MESSAGE =
  'If you proceed, you will cancel the Report editing. Unsaved settings will be lost.'
const makeMetricOrListMessage = (type: 'metric' | 'list') =>
  `If you proceed, you will cancel the ${
    type === 'metric' ? 'Metric' : 'List'
  } editing. Unsaved data and settings will be lost.`

export function useVersionPageGlobalRouterBlocker() {
  const currentEditingFlow = useCurrentEditingFlow()
  const reportEditorApi = useReportEditorApi()
  const shouldWatch =
    currentEditingFlow.flow !== null || !!reportEditorApi?.active
  const prevShouldWatch = useRef(shouldWatch)

  const message = reportEditorApi?.active
    ? REPORT_MESSAGE
    : makeMetricOrListMessage(currentEditingFlow.type!)

  unstable_usePrompt({ when: shouldWatch, message })

  const location = useLocation()
  const prevPathname = useRef(location.pathname)
  const params = useParams<{ versionId: string }>()
  const prevParams = useRef(params)

  const stopEditingHandler = useStopEditingHandler(
    prevParams.current.versionId || '',
  )
  const stopReportEditing = reportEditorApi?.active
    ? reportEditorApi.discardOperationApi.mutateAsync
    : null

  useEffect(() => {
    if (!shouldWatch && !prevShouldWatch) {
      return
    }
    if (location.pathname !== prevPathname.current) {
      prevPathname.current = location.pathname
      prevParams.current = params
      prevShouldWatch.current = false
      if (currentEditingFlow.flow) {
        stopEditingHandler()
      }
      if (stopReportEditing) {
        stopReportEditing({})
      }
    }
  }, [
    currentEditingFlow.flow,
    location.pathname,
    params,
    shouldWatch,
    stopEditingHandler,
    stopReportEditing,
  ])
}
