import type { Widget } from '@fintastic/shared/ui/widgets-framework'
import { isEqual } from 'lodash'

export const areWidgetsSettingsUpdated = (
  globalWidgets: Widget[],
  localWidgets: Widget[],
) => {
  if (globalWidgets.length !== localWidgets.length) {
    return true
  }

  return globalWidgets.some((globalWidget) => {
    const localWidget = localWidgets.find((w) => w.id === globalWidget.id)
    if (!localWidget) {
      throw new Error(`Local widget not found: ${globalWidget.id}`)
    }
    return !isEqual(globalWidget.settings, localWidget.settings)
  })
}
