import React, { forwardRef, memo, useRef } from 'react'
import { BoardParams } from '@fintastic/web/feature/boards'
import type {
  Widget,
  WidgetChangeLayoutCallback,
  WidgetChangeSettingsCallback,
  WidgetLayout,
  WidgetRendererComponent,
} from '../../types'
import {
  EditorContextActions,
  StyledWidgetWrapperContainer,
  StyledWidgetWrapperRoot,
} from './WidgetWrapper.styled'
import { Maybe } from '@fintastic/shared/util/types'
import { useWidgetLayoutState } from '../../hooks/useWidgetLayoutState'
import { ErrorBoundary } from '@fintastic/shared/ui/components'
import { toast } from '@fintastic/shared/ui/toast-framework'

export type WidgetWrapperProps = {
  renderer: WidgetRendererComponent
  widget: Widget
  boardParams: BoardParams
  draggableHandleClassName: string
  draggable: boolean
  collapsable: boolean
  onChangeSettings: WidgetChangeSettingsCallback
  onChangeLayout: WidgetChangeLayoutCallback
  layout: WidgetLayout
  gridRowHeightPixels: number
  gridColumnsNumber: number
  handleCollapse?: () => void
  widgetContextMenuBuilder?: (widget: Widget) => React.ReactNode
}

export const WidgetWrapper: React.FC<WidgetWrapperProps> = memo(
  forwardRef<HTMLDivElement, WidgetWrapperProps>((props, ref) => {
    const {
      renderer: Renderer,
      widget,
      boardParams,
      draggableHandleClassName,
      draggable,
      collapsable,
      onChangeSettings,
      onChangeLayout,
      layout,
      gridRowHeightPixels,
      gridColumnsNumber,
      handleCollapse,
      widgetContextMenuBuilder,
      ...rest
    } = props

    const containerRef = useRef<Maybe<HTMLDivElement>>(null)

    const { isEnoughSpace, isCollapsedVert } = useWidgetLayoutState({
      measurableBlockRef: containerRef,
      widget,
      layout,
    })

    let localBoardsParams: BoardParams = boardParams

    if (boardParams.isDesignMode) {
      const versionId = widget.settings?.versionId || boardParams.versions[0]
      if (versionId) {
        localBoardsParams = {
          diffs: [],
          versions: [versionId],
          isDesignMode: true,
        }
      } else {
        toast.error('Widget has no default version')
      }
    }
    const isTextWidget = widget.type === 'textWidget'

    return (
      <StyledWidgetWrapperRoot
        className={'widget-wrapper'}
        ref={ref}
        {...rest}
        data-testid={`board-widget-wrapper-${widget.id}`}
        isCollapsedVert={isCollapsedVert}
      >
        <StyledWidgetWrapperContainer ref={containerRef}>
          <ErrorBoundary>
            <Renderer
              widget={widget}
              boardParams={localBoardsParams}
              draggableHandleClassName={draggableHandleClassName}
              draggable={draggable}
              collapsable={collapsable}
              onChangeSettings={onChangeSettings}
              onChangeLayout={onChangeLayout}
              layout={layout}
              isEnoughSpace={isEnoughSpace}
              isCollapsedVert={isCollapsedVert}
              gridRowHeightPixels={gridRowHeightPixels}
              gridColumnsNumber={gridColumnsNumber}
              handleCollapse={handleCollapse}
            />
            {!!widgetContextMenuBuilder && (
              <EditorContextActions
                className={'board-design-widget-menu'}
                isText={isTextWidget}
              >
                {widgetContextMenuBuilder(widget)}
              </EditorContextActions>
            )}
          </ErrorBoundary>
        </StyledWidgetWrapperContainer>
      </StyledWidgetWrapperRoot>
    )
  }),
)
