import React from 'react'
import { Filter, FilterValue, FilterValuesModel } from '../types'
import type { Maybe } from '@fintastic/shared/util/types'

const stub = (): never => {
  throw new Error('FilterContextValue method is called out of provider')
}

export type FilterContextValue = {
  versionId: string
  filters: Filter[]
  values: FilterValuesModel
  appliedValues: FilterValuesModel
  setAllValues: React.Dispatch<React.SetStateAction<FilterValuesModel>>
  filterIsApplied: boolean
  dirty: boolean
  setFilterValue: (
    filterId: string,
    timeDimensionValueId: Maybe<string>,
    value?: FilterValue,
  ) => void
  apply: () => void
  reset: () => void
  resetFilter: (filterId: string, timeDimensionValueId: Maybe<string>) => void
}

export const FilterContextInitialValue: FilterContextValue = {
  filters: [],
  values: {},
  appliedValues: {},
  versionId: '',
  dirty: false,
  filterIsApplied: false,
  setAllValues: stub,
  setFilterValue: stub,
  apply: stub,
  reset: stub,
  resetFilter: stub,
}

// Initial value is not supposed to be ever used
export const FilterContext = React.createContext<FilterContextValue>(
  FilterContextInitialValue,
)

FilterContext.displayName = 'FilterContext'
