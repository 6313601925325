import { PeriodResolution } from '@fintastic/web/util/period-selector'
import { PeriodsAmountLimit } from '../../components/types'

const MAX_WEEKS_PER_VIEW = 72
const MAX_DAYS_PER_VIEW = 72
const NO_LIMIT = 999999999
const DAYS_PER_YEAR = 365
const DAYS_PER_MONTH = 30.5
const DAYS_PER_WEEK = 7
const WEEKS_PER_YEAR = 52

const limitPeriodsForResolution = (
  unitsInPeriod: number,
  maxUnits: number,
): number => Math.floor(maxUnits / unitsInPeriod)

export const periodAggregationLimitsPerResolution: Record<
  PeriodResolution,
  PeriodsAmountLimit
> = {
  day: {
    year: NO_LIMIT,
    half_year: NO_LIMIT,
    quarter: NO_LIMIT,
    month: NO_LIMIT,
    week: NO_LIMIT,
    day: MAX_DAYS_PER_VIEW,
  },
  week: {
    year: NO_LIMIT,
    half_year: NO_LIMIT,
    quarter: NO_LIMIT,
    month: NO_LIMIT,
    week: MAX_WEEKS_PER_VIEW,
    day: limitPeriodsForResolution(DAYS_PER_WEEK, MAX_DAYS_PER_VIEW),
  },
  month: {
    year: NO_LIMIT,
    half_year: NO_LIMIT,
    quarter: NO_LIMIT,
    month: NO_LIMIT,
    week: limitPeriodsForResolution(WEEKS_PER_YEAR / 12, MAX_WEEKS_PER_VIEW),
    day: limitPeriodsForResolution(DAYS_PER_MONTH, MAX_DAYS_PER_VIEW),
  },
  quarter: {
    year: NO_LIMIT,
    half_year: NO_LIMIT,
    quarter: NO_LIMIT,
    month: NO_LIMIT,
    week: limitPeriodsForResolution(WEEKS_PER_YEAR / 4, MAX_WEEKS_PER_VIEW),
    day: limitPeriodsForResolution(DAYS_PER_YEAR / 4, MAX_DAYS_PER_VIEW),
  },
  half_year: {
    year: NO_LIMIT,
    half_year: NO_LIMIT,
    quarter: NO_LIMIT,
    month: NO_LIMIT,
    week: limitPeriodsForResolution(WEEKS_PER_YEAR / 2, MAX_WEEKS_PER_VIEW),
    day: limitPeriodsForResolution(DAYS_PER_YEAR / 2, MAX_DAYS_PER_VIEW),
  },
  year: {
    year: NO_LIMIT,
    half_year: NO_LIMIT,
    quarter: NO_LIMIT,
    month: NO_LIMIT,
    week: limitPeriodsForResolution(WEEKS_PER_YEAR, MAX_WEEKS_PER_VIEW),
    day: limitPeriodsForResolution(DAYS_PER_YEAR, MAX_DAYS_PER_VIEW),
  },
}
