import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  MetricNumericDataValueType,
  createNumericValueInputMask,
} from '@fintastic/web/util/metrics-and-lists'
import { currencies } from '@fintastic/shared/data-access/currencies'
import { IMaskMixin } from 'react-imask'
import { Box, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import { FilterValue } from '@fintastic/web/util/filters'
import { NumberFilterInputSharedProps } from './types'
import { isNumber } from 'lodash'
import type { InputMask } from 'imask'

const Input = IMaskMixin(({ inputRef, ...props }) => {
  const propsToUse = props as Record<string, unknown>
  return <OutlinedInput size="small" inputRef={inputRef} {...propsToUse} />
})

export const NumberFieldWithDisplayConfig: React.FC<
  NumberFieldWithDisplayConfigProps
> = ({ dataType, displayConfig, filterValue, placeholder, onChange }) => {
  const currency = useMemo(
    () => currencies.find((c) => c.code === displayConfig.currency_name),
    [displayConfig.currency_name],
  )

  const mask = useMemo(
    () =>
      createNumericValueInputMask(
        dataType as MetricNumericDataValueType,
        displayConfig,
        currency,
      ),
    [currency, dataType, displayConfig],
  )

  const handleChangeInput = useCallback(
    (value: string, maskRef: InputMask<typeof mask>) => {
      const nextValue = value === '-' ? '-' : maskRef.unmaskedValue
      if (isValidFilterValue(nextValue)) {
        const nextValueParsed = nextValue ? parseFloat(nextValue) : ''

        onChange({
          value: nextValueParsed,
          operator: filterValue.operator ?? 'equal',
        })
      }
    },
    [filterValue.operator, onChange],
  )

  const handleTryToSubmit = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >((e) => {
    if (e.key !== 'Enter') {
      return
    }

    e.currentTarget.closest('form')?.requestSubmit?.()
  }, [])

  const ref = useRef<{ maskRef: InputMask<typeof mask> }>(null)

  useEffect(() => {
    if (!ref.current?.maskRef?.el) {
      return
    }
    ref.current.maskRef.value = `${filterValue.value ?? ''}`
    ref.current.maskRef.updateValue()
  }, [filterValue.value])

  return (
    <Box display="flex" flexDirection="column">
      <FormControl size="small" margin="dense" fullWidth hiddenLabel>
        <InputLabel size="small" variant="outlined">
          {placeholder}
        </InputLabel>
        <Input
          defaultValue={`${filterValue.value ?? ''}`}
          autoComplete="off"
          placeholder={placeholder}
          onAccept={handleChangeInput}
          onKeyPress={handleTryToSubmit}
          label={placeholder}
          ref={ref}
          {...(mask as unknown as any)}
        />
      </FormControl>
    </Box>
  )
}

const isValidFilterValue = (value: string): boolean =>
  value !== '-' &&
  (value === '' || isNumber(parseFloat(value))) &&
  !`${value}`.endsWith('.')

export type NumberFieldWithDisplayConfigProps = {
  filterValue: Partial<FilterValue<number | ''>>
  onChange: (nextValue: FilterValue<number | ''>) => void
  placeholder: string
} & NumberFilterInputSharedProps
