import React, { useMemo } from 'react'
import {
  PageLayout,
  PageLayoutContentFullHeight,
} from '@fintastic/shared/ui/app-layout-framework'
import { Version } from '../components/version-management/Version'
import { VersionTopBar } from '../components/version-management/VersionTopBar'
import {
  isVersionEditable,
  Version as VersionType,
  VersionUserLockParsed,
} from '@fintastic/web/util/versions'
import { VersionTable } from '../components/version-management/types'
import { usePeriodSelectionComponent } from '@fintastic/web/feature/period-selector'
import { PeriodSelectorContext } from '@fintastic/web/util/period-selector'
import {
  CenteredCircularProgress,
  ErrorAlert,
} from '@fintastic/shared/ui/components'
import {
  useFullVersionRangePeriodSelection,
  useVersionBaseTimeDimension,
} from '@fintastic/web/data-access/versions'
import {
  BaseTimeDimensionStaticWidgetProvider,
  useBaseTimeDimensionStaticWidgetAdapter,
} from '@fintastic/web/data-access/base-time-dimension'
import { ListEditorProvider } from '@fintastic/web/feature/list-editor'
import { useVersionPageGlobalRouterBlocker } from '@fintastic/web/feature/versions'

export const VersionPageContainer: React.FC<{
  version: VersionType
  versionTables: VersionTable[]
  versionUserLock: VersionUserLockParsed
}> = ({ version, versionTables, versionUserLock }) => {
  const selectedVersions = useMemo(() => [version], [version])
  const defaultSelection = useFullVersionRangePeriodSelection(version)
  const baseVersionDimension = useVersionBaseTimeDimension(version.uuid)

  useVersionPageGlobalRouterBlocker()

  const periodSelection = usePeriodSelectionComponent({
    selectedVersions,
    persistingKey: `version_${version.uuid}`,
    defaultSelection,
    baseTimeDimensionId: baseVersionDimension,
    buttonDesign: 'full',
  })
  const baseDimensionContextValue =
    useBaseTimeDimensionStaticWidgetAdapter(baseVersionDimension)

  if (periodSelection.isError) {
    return (
      <ErrorAlert
        title="Loading error"
        message="Version entities or calendar could not be loaded."
      />
    )
  }

  if (periodSelection.isLoading) {
    return (
      <PageLayout>
        <CenteredCircularProgress />
      </PageLayout>
    )
  }

  return (
    <BaseTimeDimensionStaticWidgetProvider value={baseDimensionContextValue}>
      <PeriodSelectorContext.Provider value={periodSelection.contextValue}>
        <PageLayout
          topbar={
            <VersionTopBar
              periodSelector={periodSelection.periodSelectorComponent}
            />
          }
        >
          <PageLayoutContentFullHeight>
            <ListEditorProvider
              versionId={version.uuid}
              isVersionEditable={isVersionEditable(version)}
              isLiveActuals={false}
            >
              <Version
                version={version}
                versionTables={versionTables}
                editable={isVersionEditable(version)}
                versionUserLock={versionUserLock}
              />
            </ListEditorProvider>
          </PageLayoutContentFullHeight>
        </PageLayout>
      </PeriodSelectorContext.Provider>
    </BaseTimeDimensionStaticWidgetProvider>
  )
}
