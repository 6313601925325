import { styled } from '@mui/material'
import { DrawerNotificationSeverity } from '@fintastic/shared/ui/drawer-framework'

export const StyledDrawerNotificationRoot = styled('div', {
  shouldForwardProp: (p) => p !== 'hasChildren',
})<{ hasChildren: boolean }>`
  background-color: #fcfaf8;
  display: flex;
  flex-flow: column nowrap;

  border-bottom: ${({ hasChildren }) => (hasChildren ? '1px' : 0)} solid
    ${({ theme }) => theme.palette.divider};

  min-height: 0;
  max-height: ${({ hasChildren }) => (hasChildren ? '2000px' : 0)};
  overflow: hidden;

  transition: max-height 2s ease;

  align-items: center;
`

export const StyledDrawerNotificationMessage = styled('div', {
  shouldForwardProp: (p) => p !== 'severity' && p !== 'withClose',
})<{ severity: DrawerNotificationSeverity; withClose: boolean }>`
  margin: ${({ theme }) => theme.spacing(1)};
  border-radius: 8px;

  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};

  position: relative;

  padding-right: ${({ withClose, theme }) =>
    withClose ? theme.spacing(5) : theme.spacing(2)};

  color: ${({ theme, severity }) =>
    severity === 'warning'
      ? '#634602'
      : severity === 'info'
      ? '#304c5b'
      : severity === 'error'
      ? '#900225'
      : severity === 'success'
      ? '#025c25'
      : 'inherit'};

  background-color: ${({ theme, severity }) =>
    severity === 'warning'
      ? '#fdf4e6'
      : severity === 'info'
      ? '#C1E4FB'
      : severity === 'error'
      ? '#fbd3d2'
      : severity === 'success'
      ? '#ccfbd3'
      : 'inherit'};
`

export const StyledDrawerNotificationClose = styled('div')`
  display: flex;
  flex-flow: row wrap;
  position: absolute;
  right: ${({ theme }) => theme.spacing(0.5)};
  top: 50%;
  transform: translateY(-50%);

  button {
    &:hover {
      background: transparent;
      border-color: transparent;
    }
  }

  svg {
    width: 14px;
    fill: currentColor;
  }
`
