import { toast } from 'react-hot-toast/headless'
import { Maybe } from '@fintastic/shared/util/types'
import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { switchVersionUserLockers } from '@fintastic/web/data-access/versions'
import {
  DuplicateListLineIntent,
  sendVersionChangeIntent,
  TaskRequestErrorHandler,
  usePusherCalculationOperation,
} from '@fintastic/web/data-access/calc'
import { BaseGridRow } from '@fintastic/shared/ui/grid-framework'
import { AxiosError } from 'axios'

export const useDublicateRowsTaskOperation = (
  versionId: Maybe<string>,
  listId: string,
  rowDimensionId: Maybe<string>,
) => {
  const queryClient = useQueryClient()

  const handleDuplicateLinesRequest = useCallback(
    async (rows: BaseGridRow[]) => {
      if (!versionId) {
        throw new Error(
          'versionId is not defined for useDublicateRowsTaskOperation',
        )
      }
      switchVersionUserLockers(queryClient, [versionId], 'calc')
      return (
        await sendVersionChangeIntent<DuplicateListLineIntent>(versionId, {
          action: 'duplicate',
          payload: [
            {
              list_id: listId,
              row_id: rows.map((row) => row[rowDimensionId || ''] as string),
            },
          ],
        })
      ).data
    },
    [listId, queryClient, rowDimensionId, versionId],
  )

  const handleError = useCallback<TaskRequestErrorHandler>(
    async (errorOrEvent) => {
      toast.error(
        (errorOrEvent as AxiosError)?.message ??
          'Failed to duplicate line(s). Please try again.',
      )
    },
    [],
  )

  return usePusherCalculationOperation<[rows: BaseGridRow[]]>({
    requestFunction: handleDuplicateLinesRequest,
    onError: handleError,
  })
}
