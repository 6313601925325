import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { FilterGroup } from '@fintastic/shared/ui/filters'
import {
  Filter,
  filterUtils,
  useFilterContext,
} from '@fintastic/web/util/filters'
import type { Maybe } from '@fintastic/shared/util/types'

export const InputGroupWrapper: React.FC<
  PropsWithChildren<{ filter: Filter; timeDimensionValueId: Maybe<string> }>
> = ({ filter, timeDimensionValueId, children }) => {
  const filterContext = useFilterContext()

  if (!filterContext) {
    throw new Error('InputGroupWrapper depends on FilterContext')
  }

  const [collapsed, setCollapsed] = useState(false)

  const handleToggleCollapsed = useCallback(() => {
    setCollapsed((prev) => !prev)
  }, [])

  const hasValue = useMemo(
    () =>
      !filterUtils.isFilterValueEmpty(
        filter,
        filterUtils.getValueFromTheModel(
          filterContext.appliedValues,
          filter.id,
          timeDimensionValueId,
        ),
      ),
    [filter, filterContext.appliedValues, timeDimensionValueId],
  )

  return (
    <FilterGroup
      collapsed={collapsed}
      onToggleCollapse={handleToggleCollapsed}
      title={filter.label}
      hasValue={hasValue}
    >
      {children}
    </FilterGroup>
  )
}

InputGroupWrapper.displayName = 'InputGroupWrapper'
