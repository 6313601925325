import { BLANK_VALUE_UI_REPRESENTATION, containsBlankValue } from './blanks'
import {
  MASKED_VALUE_UI_REPRESENTATION,
  containsMaskedValue,
} from './masked-value'
import { BlankOrMaskedValue } from './types'

export function stringifyBlankOrMaskedValueIfNecessary(
  input: BlankOrMaskedValue | number,
): string | number {
  if (containsBlankValue(input)) {
    return BLANK_VALUE_UI_REPRESENTATION
  }

  if (containsMaskedValue(input)) {
    return MASKED_VALUE_UI_REPRESENTATION
  }

  if (typeof input === 'symbol') {
    return String(input)
  }

  if (typeof input === 'number') {
    return input
  }

  if (!input) {
    return ''
  }

  return `${input}`
}

export const stringifyBlankOrMaskedValue = (
  input: BlankOrMaskedValue | string,
): string => {
  if (typeof input === 'string') {
    return input
  }

  return `${stringifyBlankOrMaskedValueIfNecessary(input)}`
}
