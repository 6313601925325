import { Column } from './types'
import { VersionMetric } from '../../../../utils/metrics'
import { createFieldKey } from '@fintastic/web/util/metrics-and-lists'
import { DimensionLabelMap, Maybe } from '@fintastic/shared/util/types'
import { isValueColumnEditable } from './isValueColumnEditable'
import {
  BaseGridColumnId,
  BaseGridColumnType,
} from '@fintastic/shared/ui/grid-framework'
import { LegacyListGridColumnData } from '@fintastic/web/feature/legacy-list-grid'
import { HandleMetricUpdateCallback } from '../../types'
import { getTitleFromVersion } from './getTitleFromVersion'
import { Currency } from '@fintastic/shared/data-access/currencies'
import { ColumnColorsGridProps } from '../../../column-color-selector/types'
import { getPopulateForward } from '../populate-forward/getPopulateForward'
import { cellEditors } from '../../../shared/features/aggregated-time-cell-editing/cell-editors-map'
import { metricAdtCommonPredicates } from '@fintastic/web/data-access/metrics-and-lists'
import { ColumnColorSelector } from '../../../column-color-selector/ColumnColorSelector'
import {
  ColumnColor,
  resolveValueColumnColor,
} from '@fintastic/web/util/metrics-and-lists'
import { VersionUserLockParsed } from '@fintastic/web/util/versions'

export type CreateValueColumnParams = {
  readonly: boolean
  metric: VersionMetric
  useVersionNameInTitle: boolean
  period: Maybe<string>
  restPeriods: Maybe<string[]>
  dataType?: LegacyListGridColumnData
  dimensionId?: string
  dimensions: DimensionLabelMap
  nonTimeDimensions: string[]
  timeDimension?: string
  onUpdate?: HandleMetricUpdateCallback
  enableNewFormatting?: boolean
  versionUserLocks: VersionUserLockParsed[]
  currencies: Currency[] | undefined
  periodExistInMetric?: boolean
  valueColumnAggregationCellEditingAllowed: boolean
} & Partial<ColumnColorsGridProps>

const defaultColumnColors: ColumnColor[] = []

export function createValueColumn({
  readonly,
  metric,
  useVersionNameInTitle,
  period,
  restPeriods,
  dataType,
  dimensionId,
  dimensions,
  nonTimeDimensions,
  timeDimension,
  onUpdate,
  enableNewFormatting,
  versionUserLocks,
  currencies,
  enableColumnColors,
  columnColors = defaultColumnColors,
  handleUpdateColumnColors,
  valueColumnAggregationCellEditingAllowed,
  periodExistInMetric = true,
}: CreateValueColumnParams): Column {
  const metricVersionUserLock = versionUserLocks.find(
    ({ versionId }) => versionId === metric.versionId,
  )

  const isEditableBasic =
    isValueColumnEditable(
      readonly,
      metric.versionEditable,
      metric.metricSource,
    ) &&
    !metricVersionUserLock?.editIsBlocked &&
    periodExistInMetric

  const aggregatedByTime = metricAdtCommonPredicates.aggregatedByTime({
    metadata: metric.metricMetadata,
  })

  const isEditableAggregated =
    !aggregatedByTime || valueColumnAggregationCellEditingAllowed
  const isEditable = isEditableBasic && isEditableAggregated

  return {
    field: createFieldKey(metric.versionId, metric.metricId, period),
    dataType: dataType as BaseGridColumnType | BaseGridColumnId,
    rollUpFunction: metric.metricMetadata.value.category_agg,
    getOverridingCellEditor:
      valueColumnAggregationCellEditingAllowed && aggregatedByTime
        ? (cellEditor: unknown) => {
            const newCellEditor = `${cellEditor}ForAggregatedTime`
            if (!cellEditors[newCellEditor as keyof typeof cellEditors]) {
              return cellEditor
            }
            return newCellEditor
          }
        : undefined,
    isEditable: () => isEditable,
    ...(dataType === 'dimension' && dimensionId
      ? {
          // @todo remove dimensions metadata usage
          options: dimensions[dimensionId].values,
        }
      : {}),
    title: useVersionNameInTitle
      ? getTitleFromVersion(metric)
      : // @todo remove dimensions metadata usage
        dimensions[timeDimension || '']?.values?.[period || ''] || 'Value',
    enableCellChangeFlash: true,
    populateForward: getPopulateForward({
      metric,
      period,
      restPeriods,
      nonTimeDimensions,
      timeDimension,
      onUpdate,
      isEditable,
      allowed: !aggregatedByTime,
    }),
    getCellClearValue: () => null,
    displaySettings: enableNewFormatting ? metric.displaySettings : undefined,
    currency: enableNewFormatting
      ? (currencies || []).find(
          (c) => c.code === metric.displaySettings?.currency_name,
        )
      : undefined,
    suppressPaste: aggregatedByTime,
    headerComponentParams: {
      contentBeforeMenu:
        handleUpdateColumnColors && enableColumnColors ? (
          <ColumnColorSelector
            columnColors={columnColors}
            currentColumnColumn={resolveValueColumnColor(columnColors, {
              versionId: metric.versionId,
              metricId: metric.metricId,
              period,
            })}
            handleUpdateColumnColors={handleUpdateColumnColors}
          />
        ) : null,
    },
  }
}
