import type { Maybe } from '@fintastic/shared/util/types'
import type {
  CellRange,
  ColDef,
  GridApi,
  ProcessDataFromClipboardParams,
} from 'ag-grid-community'
import { flatten, isArray, sum } from 'lodash'
import { useCallback } from 'react'
import type { BaseGridRow } from '@fintastic/shared/ui/grid-framework'
import { toast } from 'react-hot-toast/headless'
import {
  CellIsAggregatedError,
  CellIsNotEditableError,
  ColumnIsUnavailableError,
} from '../utils/custom-error'
import { AgGridSelectboxCellEditorProps } from '@fintastic/shared/ui/ag-grid'
import { parseFormattedNumber } from '../utils/parse-formatted-number'
import { MetricGridRow } from '../components/metric-grid/types'
import { idLooksLikeDimensionValue } from '@fintastic/web/util/dimensions'
import {
  cellDataTypeAllowEmpty,
  cellDataTypeIsNumeric,
  firstValidFormattedDate,
  getCellDataType,
  isBooleanTrue,
  isStringLooksLikeDate,
  isValidBoolean,
  isValidDate,
  isValidNumber,
  supportedDateFormats,
  TargetCell,
} from './clipboard-cell-utils'

type GridType = 'list' | 'metric' | 'calculated-list' | 'calculated-metric'

// generic errors
const pasteErrorsGeneric = {
  ERR_CELL_IS_NOT_EDITABLE:
    'Unable to paste cells. Please check that you have copied the correct data type and are pasting into editable columns/rows',

  ERR_CELL_IS_AGGREGATED:
    'Failed to paste values. Pasting values into an aggregated cell is not allowed',

  ERR_CELL_IS_OF_CALCULATED_LIST:
    'Unable to paste values into a calculated List',

  ERR_CELL_IS_OF_CALCULATED_METRIC:
    'Unable to paste values into a calculated Metric',

  ERR_CELL_IS_CALCULATED: 'Unable to paste values into a calculated column',

  ERR_COLUMN_UNAVAILABLE:
    'Unable to match selection with columns. Please, select corresponding set of cells',
} as const

// specific to datatype errors
const pasteErrorsSpecific = {
  ERR_CELL_PASTING_BAD_FORMAT:
    "One or more values have a data type that doesn't match the target cells' data type. Please correct and try again",

  ERR_CELL_PASTING_BAD_DIMENSION:
    'Unable to paste invalid dimension value into a dimension column. Please correct and try again',

  ERR_CELL_PASTING_EMPTY_DIMENSION:
    'Unable to paste empty value into a dimension column. Please correct and try again',

  ERR_CELL_PASTING_NAN:
    'Unable to paste a non-numerical value into a numerical column. Please correct and try again',

  ERR_CELL_PASTING_EMPTY:
    'Unable to paste an empty value into a dimension column', // to the moment, only Dim columns don't allow empties

  ERR_CELL_INVALID_DATE: `Unable to paste unsupported date format. Fix the date format to ${supportedDateFormats.join(
    ', or ',
  )} and try again`,
} as const

const pasteErrors = { ...pasteErrorsGeneric, ...pasteErrorsSpecific } as const

// related to cell datatype
type PasteSpecificErrorCode = keyof typeof pasteErrorsSpecific

// all errors
type PasteErrorCode = keyof typeof pasteErrors

// specific errors + valid state
type DataTypeMismatchCheckResult = PasteSpecificErrorCode | 'CELL_OK'

const checkPastePossible = <TData extends BaseGridRow = BaseGridRow>(
  nextValue: string,
  target?: TargetCell<TData>,
): DataTypeMismatchCheckResult => {
  if (!target) {
    return 'CELL_OK'
  }
  const dataType = getCellDataType(target)

  if (!nextValue || nextValue === '-') {
    return cellDataTypeAllowEmpty(dataType)
      ? 'CELL_OK'
      : dataType === 'DIMENSION_ID'
      ? 'ERR_CELL_PASTING_EMPTY_DIMENSION'
      : 'ERR_CELL_PASTING_EMPTY'
  }

  if (dataType === 'BOOLEAN') {
    if (!isValidBoolean(nextValue)) {
      return 'ERR_CELL_PASTING_BAD_FORMAT'
    }
  }

  if (cellDataTypeIsNumeric(dataType)) {
    if (isStringLooksLikeDate(nextValue)) {
      return 'ERR_CELL_PASTING_BAD_FORMAT'
    }
    return isValidNumber(nextValue) ? 'CELL_OK' : 'ERR_CELL_PASTING_NAN'
  }

  if (dataType === 'DATE') {
    // try to parse
    return isValidDate(nextValue) ? 'CELL_OK' : 'ERR_CELL_INVALID_DATE'
  }

  if (dataType === 'DIMENSION_ID') {
    const optionFound = !!(
      (target.colDef.cellEditorParams as AgGridSelectboxCellEditorProps)
        ?.options || []
    ).find(({ label }) => label === nextValue)

    return optionFound ? 'CELL_OK' : 'ERR_CELL_PASTING_BAD_DIMENSION'
  }

  return 'CELL_OK' // <- should be fail
}

const normalisePastedValue = <TData extends BaseGridRow = BaseGridRow>(
  nextValue: string,
  target?: Maybe<TargetCell<TData>>,
): Maybe<string | number | boolean> | undefined => {
  const dataType = getCellDataType(target)

  if (dataType === 'BOOLEAN') {
    if (isBooleanTrue(nextValue)) {
      return 1.0
    } else {
      return null
    }
  }

  if (cellDataTypeAllowEmpty(dataType) && (!nextValue || nextValue === '-')) {
    // as well as for '-'
    if (dataType === 'TEXT') {
      return ''
    }
    return null
  }

  if (dataType === 'DATE') {
    // try to parse -> format
    return firstValidFormattedDate(nextValue)
  }
  if (cellDataTypeIsNumeric(dataType)) {
    return parseFormattedNumber(nextValue, dataType)
  }

  if (
    target?.value &&
    idLooksLikeDimensionValue((target?.value || '').toString())
  ) {
    const selectedOption = (
      (target.colDef.cellEditorParams as AgGridSelectboxCellEditorProps)
        ?.options || []
    ).find(({ label }) => label === nextValue)
    return selectedOption?.value || nextValue
  }

  // add rows: target is null yet, empty cell
  if (
    dataType === 'DIMENSION_ID' &&
    !target?.value &&
    target?.colDef?.cellEditorParams?.dataType === 'dimension'
  ) {
    const selectedOption = (
      (target.colDef.cellEditorParams as AgGridSelectboxCellEditorProps)
        ?.options || []
    ).find(({ label }) => label === nextValue)
    return selectedOption?.value || null // !
  }

  return nextValue
}

// Converts range and data to an array of the original values
const buildOriginalTarget = <TData extends BaseGridRow = BaseGridRow>(
  api: GridApi<TData>,
  range: CellRange,
  data: string[][],
): TargetCell<TData>[][] => {
  const startRowIndex = Math.min(
    range.startRow?.rowIndex || 0,
    range.endRow?.rowIndex || 0,
  )

  return data.map((rows, rowIndex) => {
    const rowNode = api.getDisplayedRowAtIndex(startRowIndex + rowIndex)

    if (!rowNode) {
      return []
    }

    return range.columns.map((_, index): TargetCell<TData> => {
      const column = range.columns[index]

      if (!column) {
        throw new ColumnIsUnavailableError('Column is unreachable')
      }

      if (!column.isCellEditable(rowNode)) {
        throw new CellIsNotEditableError('Cell is not editable')
      }

      if (column.getColDef().suppressPaste) {
        throw new CellIsAggregatedError('Cell is aggregated')
      }

      const value = rowNode?.data?.[column?.getColDef()?.field || ''] as Maybe<
        string | number
      >

      return {
        value,
        colDef: column.getColDef() as ColDef<TData>,
      }
    })
  })
}

// @todo: add errors tracking and priorities
// like what value is invalid, why, what to display in a toaster if more then one violation

type PasteCellViolations = Set<PasteSpecificErrorCode>

function selectMostSevereError(
  violations: PasteCellViolations,
): PasteErrorCode {
  if (violations.has('ERR_CELL_PASTING_BAD_FORMAT')) {
    return 'ERR_CELL_PASTING_BAD_FORMAT'
  }
  if (violations.has('ERR_CELL_PASTING_BAD_DIMENSION')) {
    return 'ERR_CELL_PASTING_BAD_DIMENSION'
  }

  if (violations.has('ERR_CELL_PASTING_EMPTY_DIMENSION')) {
    return 'ERR_CELL_PASTING_EMPTY_DIMENSION'
  }

  if (violations.has('ERR_CELL_PASTING_NAN')) {
    return 'ERR_CELL_PASTING_NAN'
  }

  if (violations.has('ERR_CELL_PASTING_EMPTY')) {
    return 'ERR_CELL_PASTING_EMPTY'
  }

  if (violations.has('ERR_CELL_INVALID_DATE')) {
    return 'ERR_CELL_INVALID_DATE'
  }

  // @todo: should be other reason because NOT_EDITABLE and AGGREGATED generated by throwing errors
  return 'ERR_CELL_IS_NOT_EDITABLE'
}

// function with side effect!
// returns boolean as a result and modifies violationList
function checkViolations<TData extends BaseGridRow = BaseGridRow>(
  paste: string,
  current: TargetCell<TData>,
  violationList: PasteCellViolations,
) {
  const checkPaste = checkPastePossible(paste, current)

  if (checkPaste !== 'CELL_OK' && !!pasteErrorsSpecific[checkPaste]) {
    violationList.add(checkPaste)
  }

  return checkPaste === 'CELL_OK'
}

const processClipboardValue = <TData extends BaseGridRow = BaseGridRow>(
  { api, data }: ProcessDataFromClipboardParams<TData>,
  gridType: GridType,
): string[][] | PasteErrorCode => {
  const range = api.getCellRanges()?.[0]

  if (typeof range?.startRow?.rowIndex !== 'number') {
    console.warn('No range selection found')
    return data
  }

  let target: TargetCell<TData>[][] = []

  try {
    target = buildOriginalTarget(api, range, data)
  } catch (ex: unknown) {
    // Exit if any cell is not editable
    if (ex instanceof ColumnIsUnavailableError) {
      return 'ERR_COLUMN_UNAVAILABLE'
    }

    if (ex instanceof CellIsNotEditableError) {
      return 'ERR_CELL_IS_CALCULATED'
      // @todo: this is related to https://fintastic.atlassian.net/browse/FIN-8081
      // @todo: keep this comments for a while
      // @todo: perhaps there should be better detection of such a hybrid mode but i have no idea yet how to do that
      // if (gridType === 'list') {
      //   return 'ERR_CELL_IS_CALCULATED'
      // }
      //
      // if (gridType === 'metric') {
      //   return 'ERR_CELL_IS_CALCULATED'
      // }
      // if (gridType === 'calculated-list') {
      //   return 'ERR_CELL_IS_OF_CALCULATED_LIST'
      // }
      //
      // if (gridType === 'calculated-metric') {
      //   return 'ERR_CELL_IS_OF_CALCULATED_METRIC'
      // }
      //
      // // has almost no sense because list or metric is the only allowed here but ok
      // return 'ERR_CELL_IS_NOT_EDITABLE'
    }

    // Exit if any cell is aggregated
    if (ex instanceof CellIsAggregatedError) {
      return 'ERR_CELL_IS_AGGREGATED'
    }

    throw ex
  }

  const oneItemPaste = sum(target.map(({ length }) => length)) === 1

  const violationState: PasteCellViolations = new Set()

  // Validate one-to-many case
  if (oneItemPaste) {
    const paste = data[0][0]
    const valid = flatten(target).every((current) =>
      checkViolations(paste, current, violationState),
    )

    if (!valid) {
      return selectMostSevereError(violationState)
    }

    return [[normalisePastedValue<TData>(paste, target[0][0]) as string]]
  }

  const pastedRowsCount = data.length
  const pastedColumnsCount = data[0].length

  // Check if any target does not match it's pasted data
  const valid = target.every((row, rowIndex) =>
    row.every((current, cellIndex) => {
      const paste =
        data[rowIndex % pastedRowsCount][cellIndex % pastedColumnsCount]

      return checkViolations(paste, current, violationState)
    }),
  )

  if (!valid) {
    return selectMostSevereError(violationState)
  }

  // Normalise every cell according to it original value
  return target.map((row, rowIndex) =>
    row.map((current, cellIndex) => {
      const paste =
        data[rowIndex % pastedRowsCount][cellIndex % pastedColumnsCount]

      return normalisePastedValue(paste, current) as string
    }),
  )
}

type R = ReturnType<typeof processClipboardValue>

const showToast = (result: R) => {
  if (typeof result === 'string' && !!pasteErrors[result]) {
    toast.error(pasteErrors[result])
  }
}

export function useProcessClipboardPaste<
  TData extends BaseGridRow = MetricGridRow,
>() {
  return useCallback(
    (params: ProcessDataFromClipboardParams<TData>, gridType: GridType) => {
      const r = processClipboardValue(params, gridType)
      showToast(r)

      if (isArray(r)) {
        return r
      }

      return []
    },
    [],
  )
}
