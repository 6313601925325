import React from 'react'
import { ChartSettingsIcon } from '../icons/ChartSettingIcon'
import { WidgetTabs } from '../ChartTabs/WidgetTabs'
import { WidgetTab } from '../ChartTabs/WidgetTab'
import { ChartLocalSettingsPanel } from '../ChartLocalSettingsPanel/ChartLocalSettingsPanel'
import type { ChartSettings } from '@fintastic/web/feature/charts'

export type MetricChartSidePanelProps = {
  isDesignMode?: boolean
  loading: boolean
  widgetId: string
  metricId: string
  versions: string[]
  initialSettings: ChartSettings
  highlightSettings?: boolean
}

export const MetricChartSidePanel: React.FC<MetricChartSidePanelProps> = ({
  isDesignMode,
  widgetId,
  loading,
  metricId,
  versions,
  highlightSettings,
  initialSettings,
}) => (
  <WidgetTabs>
    <WidgetTab
      icon={<ChartSettingsIcon />}
      title={'Chart settings'}
      disabled={isDesignMode}
      highlight={highlightSettings}
      id={'settings'}
    >
      <ChartLocalSettingsPanel
        widgetId={widgetId}
        loading={loading}
        metricId={metricId}
        versions={versions}
        initialSettings={initialSettings}
      />
    </WidgetTab>
  </WidgetTabs>
)
