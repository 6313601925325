import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  DimensionLabelMap,
  Maybe,
  toMaybe,
  Uuid,
} from '@fintastic/shared/util/types'
import { useMutation, useQueryClient } from 'react-query'
import { ListGrid } from './ListGrid'
import {
  BaseGridRow,
  SetGridSizeCallback,
} from '@fintastic/shared/ui/grid-framework'
import {
  AggregatedTimeCellEditorContext,
  ColumnColorsGridProps,
  enrichMetricDimensionWithEntities,
  HandleMetricUpdateCallbackParams,
  listColumnsDataToSetCellValuePayloads,
  notifyMetricUpdateFailed,
  useAggregatedTimeCellEditing,
  useChangeIntentQueue,
  useSetBaseTimeDimensionEffect,
} from '@fintastic/web/feature/metrics-and-lists'
import { useModalState } from '@fintastic/shared/util/modal'
import { LinearProgress } from '@mui/material'
import { Version } from '@fintastic/web/feature/formulas'
import {
  invalidateVersionUserLockerCache,
  switchVersionUserLockers,
  useVersionEntitiesContext,
  useVersionUserLockQuery,
} from '@fintastic/web/data-access/versions'
import {
  ListColumnWrapper,
  ListWrapper,
  MetricDataWrapper,
  useEditingList,
  useLoadList,
} from '@fintastic/web/data-access/metrics-and-lists'
import {
  ListWithOptionalData,
  ParsedColumnId,
} from '@fintastic/web/util/metrics-and-lists'
import { SettingsPanelContextsWrapper } from './features/settings/SettingsPanelContextsWrapper'
import { ListGridApi, ListGridConnectorSettingsPanelProp } from './types'
import { useSettingsRouterLocation } from './features/settings/useSettingsRouterLocation'
import { useColumnsPanelContext } from '../../features/columns-visibility'
import { compact, isEqual } from 'lodash'
import { enrichListDimensionsWithEntities } from './utils/enrich-list-dimensions-with-entities'
import { useListColumns } from './features/columns-data-loading/useListColumns'
import { extractDimensionsFromMetrics } from './utils/extractDimensionsFromMetrics'
import uniq from 'lodash/uniq'
import { getListGridError } from './features/errors/getListGridError'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'
import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'
import { sendEditListColumnsDataIntent } from '@fintastic/web/data-access/metric-data-editing'
import { changesToIntents } from './features/data-editing/changesToIntents'
import { useSetBaseTimeDimensionFromErrorEffect } from '@fintastic/web/data-access/base-time-dimension'
import { useAddNewRowsTaskOperation } from './features/add-new-rows/useAddNewRowsTaskOperation'
import { useDublicateRowsTaskOperation } from './features/dublicate-rows/useDublicateRowsTaskOperation'
import { useDeleteRowsOperation } from './features/delete-rows/useDeleteRowsOperation'
import type { VersionsApi } from '../list-connector/features/versions-api/useVersionsApi'
import type { ListsApi } from '../list-connector/features/lists-api/useListsApi'
import { ListGridPropsLocalVersion } from '../list-connector/features/versions-selector/types'
import { SelectedCellAggregationProvider } from '@fintastic/web/util/selected-cell-aggregation'
import { LinesDeleteConfirmationModal } from './features/modals/LinesDeleteConfirmationModal'
import { useListDimensionsPermissionsApi } from '../../features/dimensions-permissions/useListDimensionsPermissionsApi'
import { useCellLevelUpdate } from '../../features/data-editing/cell-level-update'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'

export type ListGridConnectorProps = {
  listId: Uuid
  title?: string
  setGridSizeCallback?: SetGridSizeCallback
  settingsPanel?: ListGridConnectorSettingsPanelProp
  isVersionPage?: boolean
  periodSelectorComponent: React.ReactNode
  versionsApi: VersionsApi
  listsApi: ListsApi
  versionSelectorProps: ListGridPropsLocalVersion
  visibleColumnIds: string[]
  requestEntityDeletion?: (entityId: string) => void
} & Partial<ColumnColorsGridProps>

export const ListGridConnector: React.FC<ListGridConnectorProps> = ({
  listId,
  setGridSizeCallback,
  title,
  settingsPanel,
  isVersionPage,
  columnColors,
  enableColumnColors,
  handleUpdateColumnColors,
  periodSelectorComponent,
  listsApi,
  versionsApi,
  versionSelectorProps,
  visibleColumnIds,
  requestEntityDeletion,
}) => {
  const queryClient = useQueryClient()
  const periodSelection = usePeriodSelectorContext()
  const entitiesContextValue = useVersionEntitiesContext()

  const { versionId, versionsMetadata, versionEditable, versionLabel } =
    versionsApi
  const { list, listOriginallyEmpty } = listsApi
  const entities = toMaybe(entitiesContextValue.entities[versionId || ''])

  const listGridApi = useRef<Maybe<ListGridApi>>(null)

  const isSettingsEditingActive = false

  const aggregatedCellEditing = useAggregatedTimeCellEditing()

  const inEditingMode = false

  const editingList = useEditingList()

  const listQuery = useLoadList(
    versionId,
    listId,
    periodSelection,
    false, // we need only status, so never request
  )
  const dimensionsPermissions = useListDimensionsPermissionsApi(
    versionId || '',
    listId,
  )
  const allowColumnsDeletion = useIsFeatureEnabled(
    'enable_existing_list_columns_deletion',
  )

  const existingListWithoutData = list

  const source = toMaybe(existingListWithoutData?.source)
  const listFormula = toMaybe(existingListWithoutData?.metadata.formula)
  const rowDimensionId = toMaybe(existingListWithoutData?.row_dim)

  const cellLevelUpdateApi = useCellLevelUpdate(versionId || '', listId, {
    updateCellDataValue: useCallback((payload) => {
      listGridApi.current?.updateCellDataValue(payload)
    }, []),
    timeDimension: list ? new ListWrapper(list).baseTimeDimension() : null,
    rowDimension: rowDimensionId || '',
    periodSelection,
    columnsMetadata: useMemo(
      () => existingListWithoutData?.metrics || [],
      [existingListWithoutData?.metrics],
    ),
  })

  const existingColumns = useListColumns(
    versionId,
    listId,
    useReferenceMemo(visibleColumnIds, isEqual),
    periodSelection,
    true,
  )

  const dimsList = useMemo(
    () => entities?.unwrap().dimensions || [],
    [entities],
  )
  const columnsOrig = useMemo(() => {
    const result = existingColumns.columns

    Object.values(result).forEach((c) => {
      // @todo remove dimensions metadata usage
      c.metadata.dimensions = enrichMetricDimensionWithEntities(
        c,
        dimsList,
      ).metadata.dimensions
    })

    return result
  }, [dimsList, existingColumns.columns])
  const columns = useReferenceMemo(columnsOrig, isEqual)

  const settingsRouterLocation = useSettingsRouterLocation(false)

  const isExistingCalculatedList = false

  const listForSettingsPanel = toMaybe(list)

  const listForGrid = useMemo<Maybe<ListWithOptionalData>>(() => {
    if (dimsList.length === 0) {
      return null
    }
    if (!inEditingMode || isExistingCalculatedList) {
      if (!existingListWithoutData) {
        return null
      }

      return {
        ...existingListWithoutData,
        metrics: compact(
          existingListWithoutData.metrics.map((metric) => {
            if (metric.data === null) {
              return existingColumns.columns[metric.id] || metric
            }

            return metric
          }),
        ).map((metric) =>
          metric.data
            ? // @todo remove dimensions metadata usage
              enrichMetricDimensionWithEntities(metric, dimsList)
            : metric,
        ),
      }
    }

    if (!editingList) {
      return null
    }

    // @todo remove dimensions metadata usage
    return enrichListDimensionsWithEntities(editingList, dimsList)
  }, [
    inEditingMode,
    isExistingCalculatedList,
    editingList,
    dimsList,
    existingListWithoutData,
    existingColumns.columns,
  ])

  const error = useMemo(
    () =>
      getListGridError(
        versionId,
        {
          listItselfError: listsApi.error,
          versionsApiError: versionsApi.error,
          columnsError: existingColumns.error,
        },
        Object.values(existingColumns.columns),
        {
          listItselfOriginallyEmpty: listOriginallyEmpty,
        },
      ),
    [
      existingColumns.columns,
      existingColumns.error,
      listOriginallyEmpty,
      listsApi.error,
      versionId,
      versionsApi.error,
    ],
  )

  useSetBaseTimeDimensionEffect(
    useMemo(
      () => compact([list?.metadata.base_time_dimension_id]),
      [list?.metadata.base_time_dimension_id],
    ),
  )

  useSetBaseTimeDimensionFromErrorEffect(error)

  const versionUserLockerQuery = useVersionUserLockQuery(versionId)

  const isLoading = useMemo(() => {
    if (error) {
      return false
    }

    if (entitiesContextValue.isLoading) {
      return true
    }

    return (
      listQuery.isLoading ||
      listsApi.isLoading ||
      (inEditingMode ? false : !existingColumns.allColumnsLoaded) ||
      !listForGrid ||
      rowDimensionId === null ||
      !!dimensionsPermissions.loading
    )
  }, [
    error,
    entitiesContextValue.isLoading,
    listQuery.isLoading,
    listsApi.isLoading,
    inEditingMode,
    existingColumns.allColumnsLoaded,
    listForGrid,
    rowDimensionId,
    dimensionsPermissions.loading,
  ])

  const columnsPanelContextValue = useColumnsPanelContext()

  const atListOneColumnHidden =
    columnsPanelContextValue.hiddenColumns.length > 0

  const {
    isOpen: isDeleteModalOpen,
    open: openDeleteModal,
    close: closeDeleteModalPopup,
  } = useModalState()

  const dimensionsOrig = useMemo<DimensionLabelMap>(() => {
    if (dimsList.length === 0) {
      return {}
    }
    // @todo remove dimensions metadata usage
    return extractDimensionsFromMetrics(
      Object.values(columns),
      dimsList,
      rowDimensionId || undefined,
    )
  }, [columns, dimsList, rowDimensionId])
  const dimensions = useReferenceMemo(dimensionsOrig, isEqual)

  const handleUpdate = useMutation(
    ['sendListUpdateIntent'],
    async (changeIntents: HandleMetricUpdateCallbackParams[]) => {
      if (!versionId) {
        return
      }

      const intentsPerVersion = changesToIntents(changeIntents)
      const intentsPerVersionsEntities = Object.entries(intentsPerVersion)
      for (let i = 0; i < intentsPerVersionsEntities.length; i++) {
        const [versionId, intent] = intentsPerVersionsEntities[i]
        const entities = entitiesContextValue.entities[versionId]

        const compressedIntent = intent.toCompressed()
        const intentToSend = compressedIntent || intent.unwrap()

        const changeWithTimeDim = !entities
          ? null
          : intent.getFirstColumnWidthTimeDimension(entities.dimensions)

        if (changeWithTimeDim) {
          const columnId = new ParsedColumnId(
            listId,
            changeWithTimeDim.column_name,
          ).toString()
          const wrappedColumn = !columns[columnId]
            ? null
            : new ListColumnWrapper(columns[columnId])
          if (wrappedColumn?.aggregatedByTime()) {
            intent.changeDataFillStrategy(aggregatedCellEditing.action)
          }
        }

        const result = await sendEditListColumnsDataIntent(
          versionId,
          listId,
          intentToSend,
        )

        if ('list_data' in result.data) {
          if (!listGridApi.current || rowDimensionId === null) {
            return
          }
          const allTimeDims =
            entities?.dimensions
              .filter((d) => d.dimension.type === 'Time')
              .map(({ id }) => id) || []
          listColumnsDataToSetCellValuePayloads(
            versionId,
            result.data.list_data.metrics.map((column) => ({
              partialCoordinates: {
                rowDimension: rowDimensionId,
                metricId: column.id,
                timeDimension:
                  new MetricDataWrapper(column.data).getTimeDimensionId(
                    allTimeDims,
                  ) || undefined,
              },
              data: column.data,
            })),
          ).forEach(listGridApi.current.updateCellDataValue)
        }

        cellLevelUpdateApi.awaitEventId(result.data.stream_event_id)
      }
    },
    {
      onError: async (error) => {
        notifyMetricUpdateFailed(error as Error, 'list')
        if (versionId) {
          await invalidateVersionUserLockerCache(queryClient, versionId)
        }
      },
    },
  )

  const [rowsToDelete, setRowsToDelete] = useState<BaseGridRow[]>(() => [])

  const deleteRowsOperation = useDeleteRowsOperation(
    versionId,
    listId,
    rowDimensionId,
    listGridApi,
  )
  const addNewLinesOperation = useAddNewRowsTaskOperation(versionId, listId)
  const handleDuplicateOperation = useDublicateRowsTaskOperation(
    versionId,
    listId,
    rowDimensionId,
  )

  const onAskToDelete = useCallback(
    (rows: BaseGridRow[]) => {
      setRowsToDelete(rows)
      openDeleteModal()
    },
    [openDeleteModal],
  )

  const closeDeleteModal = useCallback(() => {
    setRowsToDelete([])
    closeDeleteModalPopup()
  }, [closeDeleteModalPopup])

  const confirmDelete = useCallback(() => {
    deleteRowsOperation.handleOperation(rowsToDelete)
    closeDeleteModalPopup()
  }, [deleteRowsOperation, rowsToDelete, closeDeleteModalPopup])

  const handleUpdateQueued = useChangeIntentQueue(handleUpdate)

  const updateHandler = useCallback(
    (intents: HandleMetricUpdateCallbackParams[]) => {
      switchVersionUserLockers(
        queryClient,
        uniq(intents.map((i) => i.versionId)),
        'calc',
      )

      return handleUpdateQueued(intents)
    },
    [handleUpdateQueued, queryClient],
  )

  const listConnectedTable = useMemo<Version['connectedTable']>(
    () => ({
      id: listId,
    }),
    [listId],
  )

  useEffect(() => {
    if (inEditingMode ? null : error) {
      setGridSizeCallback?.({
        rows: 0,
        rowHeight: 0,
        headerHeight: 0,
      })
    }
  }, [error, inEditingMode, setGridSizeCallback])

  const backgroundOperationWorking =
    addNewLinesOperation.processing ||
    handleDuplicateOperation.processing ||
    deleteRowsOperation.processing

  const readonly =
    !versionEditable ||
    versionsApi.isLoading ||
    (dimensionsPermissions.noRestrictions
      ? false
      : !dimensionsPermissions.ready) ||
    listQuery.isFetching ||
    listsApi.isFetching ||
    !existingColumns.allColumnsLoaded ||
    handleDuplicateOperation.processing ||
    backgroundOperationWorking ||
    isSettingsEditingActive ||
    source === 'calculated' ||
    source === 'raw' ||
    versionUserLockerQuery.lock.editIsBlocked

  return (
    <SelectedCellAggregationProvider entityIdForPersistentSettings={listId}>
      <AggregatedTimeCellEditorContext.Provider value={aggregatedCellEditing}>
        <SettingsPanelContextsWrapper
          list={listForSettingsPanel}
          editingAllowed={false}
          version={versionsMetadata[versionId || ''] || null}
          location={settingsRouterLocation}
          allowExistingColumnsDeletion={
            !!settingsPanel?.editingAllowed && allowColumnsDeletion
          }
          requestEntityDeletion={requestEntityDeletion || console.log}
        >
          {backgroundOperationWorking ? (
            <LinearProgress
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 2,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
              }}
            />
          ) : (
            <div /> // Maintain DOM-elements number and order
          )}
          <ListGrid
            ref={listGridApi}
            isLoading={isLoading}
            readonly={readonly}
            error={inEditingMode ? null : error}
            columnIds={visibleColumnIds}
            columns={columns}
            dimensions={dimensions}
            onUpdate={updateHandler}
            onDelete={onAskToDelete}
            onAddNewRows={addNewLinesOperation.handleOperation}
            onDuplicate={handleDuplicateOperation.handleOperation}
            onReset={existingColumns.reloadListCache}
            setGridSizeCallback={setGridSizeCallback}
            title={title}
            versionId={versionId || ''}
            versionEditable={versionEditable}
            versionLabel={versionLabel}
            isLiveActuals={versionsApi.isLiveActuals}
            listFormula={listFormula}
            listConnectedTable={listConnectedTable}
            {...versionSelectorProps}
            source={source || null}
            isVersionPage={isVersionPage}
            clientOnlyMapping={null}
            atListOneColumnHidden={atListOneColumnHidden}
            rowDimensionId={rowDimensionId}
            columnColors={columnColors}
            enableColumnColors={enableColumnColors}
            handleUpdateColumnColors={handleUpdateColumnColors}
            listId={listId}
            periodSelectorComponent={periodSelectorComponent}
            versionUserLock={versionUserLockerQuery.lock}
            dimensionPermissions={dimensionsPermissions}
            listOriginallyEmpty={listOriginallyEmpty}
          />
          <LinesDeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            onConfirm={confirmDelete}
            count={rowsToDelete.length}
          />
        </SettingsPanelContextsWrapper>
      </AggregatedTimeCellEditorContext.Provider>
    </SelectedCellAggregationProvider>
  )
}
