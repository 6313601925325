import React from 'react'
import {
  ActionType,
  ControlButtons,
  DateRange,
  LocalFiltersAndOrderingParamsState,
  OrderByCreatedAt,
} from '../filters-and-ordering'
import { Box } from '@mui/material'
import { iso8601 } from '@fintastic/shared/util/date'

export const FiltersAndOrdering: React.FC<{
  localParamsStateApi: LocalFiltersAndOrderingParamsState
  resetToDefaults: () => void
  apply: () => void
  isBusy: boolean
}> = ({ localParamsStateApi, resetToDefaults, apply, isBusy }) => {
  const {
    localParams,
    localDiffersFromCurrent,
    currentDiffersFromDefault,
    changeDateFrom,
    changeDateTo,
    changeOrdering,
    resetToCurrent,
    changeActionType,
  } = localParamsStateApi

  return (
    <Box sx={{ mt: '8px' }}>
      <Box
        sx={{
          mb: '12px',
          px: '18px',
        }}
      >
        <DateRange
          disabled={isBusy}
          fromDate={iso8601.toDate(localParams.filter.min_date!)}
          onChangeFromDate={changeDateFrom}
          toDate={iso8601.toDate(localParams.filter.max_date!)}
          onChangeToDate={changeDateTo}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: '8px',
          px: '18px',
        }}
      >
        <Box sx={{ width: '183px' }}>
          <ActionType
            disabled={isBusy}
            actionTypes={localParams.filter.action}
            onChangeActionType={changeActionType}
          />
        </Box>
        <Box sx={{ width: '183px' }}>
          <OrderByCreatedAt
            disabled={isBusy}
            desc={localParams.desc}
            onChangeOrdering={changeOrdering}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: '8px',
          px: '18px',
        }}
      >
        <ControlButtons
          disabled={isBusy}
          isChanged={localDiffersFromCurrent}
          isDefaultChanged={currentDiffersFromDefault}
          onCancel={resetToCurrent}
          onClear={resetToDefaults}
          onApply={apply}
        />
      </Box>
    </Box>
  )
}
