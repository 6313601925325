import React, { useEffect, useMemo, useState } from 'react'
import { StyledBoardsSidebarMenuTree } from './BoardsSidebarMenu.styled'
import { FoldersTree } from './FoldersTree'
import { BoardsSidebarNoData } from './BoardsSidebarNoData'
import {
  filterTreeByName,
  findRootFolderIdByBoardId,
  treeBoardCount,
} from './boards-sidebar-utils'
import { ContextMenuItem } from '@fintastic/shared/ui/context-menu-framework'
import { useBoardFolders } from '../../ducks/boards-ui/hooks'
import {
  BoardContextMenuBuilderForSidebarBoardParams,
  BoardFoldersTree,
} from '../../types'
import {
  StyledSearchInputContainer,
  StyledSearchPanel,
} from './BoardsSidebarSearch.styled'
import { Divider } from '@mui/material'
import { BoardsSidebarNewBoard } from './BoardsSidebarNewBoard'
import { QuickSearchDebounced } from '@fintastic/shared/ui/components'

export type BoardsSidebarTreeParams = {
  tree: BoardFoldersTree
  activeBoard?: string
  boardContextMenuBuilder: (
    callbackParams: BoardContextMenuBuilderForSidebarBoardParams,
  ) => ContextMenuItem[]
  folderContextMenuBuilder: (folderId: string) => ContextMenuItem[]
  onNewBoard: () => void
}

export const BoardsSidebarTreeWithSearch: React.FC<BoardsSidebarTreeParams> = ({
  tree,
  activeBoard,
  boardContextMenuBuilder,
  folderContextMenuBuilder,
  onNewBoard,
}) => {
  const [filterString, setFilterString] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [visibleCount, setVisibleCount] = useState(0)
  const { expandOnly, expandAll } = useBoardFolders()

  const filteredTree = useMemo<BoardFoldersTree>(() => {
    const trimmedSearch = filterString.trim()

    if (!trimmedSearch) {
      const cnt = treeBoardCount(tree)
      setTotalCount(() => cnt)
      setVisibleCount(() => cnt)
      return tree
    }

    const result = filterTreeByName(tree, trimmedSearch)
    setTotalCount(() => treeBoardCount(tree))
    setVisibleCount(() => treeBoardCount(result))

    return result
  }, [tree, filterString])

  const activeFolderId = useMemo(
    () => findRootFolderIdByBoardId(tree, activeBoard),
    [activeBoard, tree],
  )

  useEffect(() => {
    const trimmedSearch = filterString.trim()

    if (!trimmedSearch) {
      if (activeFolderId) {
        expandOnly(activeFolderId)
      }
    } else {
      expandAll(filteredTree.rootNodes.map((node) => node.data.id))
    }
  }, [filterString, filteredTree, expandAll, expandOnly, activeFolderId])

  return (
    <StyledBoardsSidebarMenuTree data-testid={'boards-sidebar-menu-tree'}>
      <StyledSearchPanel>
        <StyledSearchInputContainer>
          <QuickSearchDebounced
            onFilter={setFilterString}
            totalCount={totalCount}
            visibleCount={visibleCount}
            autoFocus={true}
            data-testid="board-sidebar-quick-search"
          />
        </StyledSearchInputContainer>
        <BoardsSidebarNewBoard onNewBoard={onNewBoard} />
      </StyledSearchPanel>
      <Divider />
      {filteredTree.rootNodes.length > 0 ? (
        <FoldersTree
          boardContextMenuBuilder={boardContextMenuBuilder}
          folderContextMenuBuilder={folderContextMenuBuilder}
          tree={filteredTree}
          activeBoard={activeBoard || null}
        />
      ) : (
        <BoardsSidebarNoData />
      )}
    </StyledBoardsSidebarMenuTree>
  )
}
