import React, { memo, useEffect, useRef } from 'react'
import { ECharts, EChartsOption, init } from 'echarts'
import useResizeObserver from 'use-resize-observer'
import { ChartTheme } from '../../types'

export type EChartContainerProps = {
  option: EChartsOption
  theme?: ChartTheme
}

// original idea:
// https://dev.to/manufac/using-apache-echarts-with-react-and-typescript-353k
export const EChartContainer: React.FC<EChartContainerProps> = memo(
  ({ option, theme }) => {
    const chartRef = useRef<HTMLDivElement>(null)
    const instanceRef = useRef<ECharts | undefined>()

    useEffect(() => {
      if (chartRef.current !== null) {
        try {
          const height = chartRef.current.clientHeight
          const width = chartRef.current.clientWidth
          if (height > 0 && width > 0) {
            instanceRef.current = init(chartRef.current, theme)
            instanceRef.current?.setOption(option as any)
          }
        } catch {
          // invalid container
        }
      }
      return () => {
        instanceRef.current?.dispose()
        instanceRef.current = undefined
      }
    }, [theme])

    const { width, height } = useResizeObserver({
      ref: chartRef,
      box: 'border-box',
    })

    useEffect(() => {
      if (chartRef.current !== null) {
        const height = chartRef.current.clientHeight
        const width = chartRef.current.clientWidth

        if (height > 0 && width > 0 && !instanceRef.current) {
          instanceRef.current = init(chartRef.current, theme)
          instanceRef.current?.setOption(option as any)
        }

        instanceRef.current?.resize()
      }
    }, [width, height])

    useEffect(() => {
      instanceRef.current?.clear()
      instanceRef.current?.setOption(option as any)
    }, [option])

    return <div className={'chart-container'} ref={chartRef} />
  },
)
