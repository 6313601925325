import React, { memo, useMemo } from 'react'
import {
  StyledChart,
  StyledEmptyChart,
  StyledLoadingChart,
} from './Chart.styled'
import { EChartContainer } from './EChartContainer'
import { ChartColumnData, ChartDisplaySettings } from '../../types'
import { LinearProgress } from '@mui/material'
import { ChartTypeBarsIcon } from '../icons/ChartTypeBarsIcon'
import { Maybe } from '@fintastic/shared/util/types'
import { encodeForBars, encodeForLines } from './chart-utils'
import { defaultChartTheme } from '@fintastic/web/feature/charts'

type ChartProps = {
  loading: boolean
  isDesignMode?: boolean
  displaySettings: ChartDisplaySettings
  data: Maybe<ChartColumnData>
}

export const Chart: React.FC<ChartProps> = memo(
  ({ loading, displaySettings, isDesignMode, data }) => {
    // series: [{ type: 'bar' },],
    const option = useMemo(() => {
      if (displaySettings.type === 'lines') {
        return encodeForLines(data, !isDesignMode)
      }

      if (displaySettings.type === 'bars') {
        return encodeForBars(data, !isDesignMode)
      }
      return null
    }, [displaySettings.type, data, isDesignMode])

    if (loading) {
      return (
        <StyledLoadingChart>
          <LinearProgress
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 2,
            }}
          />
        </StyledLoadingChart>
      )
    }

    if (!option) {
      return (
        <StyledEmptyChart>
          <ChartTypeBarsIcon />
          No data to display
        </StyledEmptyChart>
      )
    }

    return (
      <StyledChart>
        <EChartContainer
          option={option}
          theme={displaySettings.theme || defaultChartTheme}
        />
      </StyledChart>
    )
  },
)
