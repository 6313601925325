import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ContextMenu, ContextMenuIcon, useContextMenuState } from '@fintastic/shared/ui/context-menu-framework'
import { Maybe } from '@fintastic/shared/util/types'
import { BoardContextMenuBuilderForSidebarBoard, BoardShortened } from '../../types'
import {
  StyledBoardButton,
  StyledBoardContextMenuButton,
  StyledBoardContextMenuButtonCont,
  StyledBoardIcon,
  StyledBoardRoot,
  StyledBoardTitle,
} from './Board.styled'
import { Link } from 'react-router-dom'
import { BoardIcon } from './BoardIcon'
import { boardRoutes } from '../../routes'
import { PopoverOrigin, Tooltip } from '@mui/material'
import { useLayoutStateIsSidebarOpened } from '@fintastic/shared/ui/app-layout-framework'
import { PartialDeeplinkKeeperConfig, useKeepPartialDeeplinkUrl } from '@fintastic/web/util/deeplink'
import { useBoardState } from '../../ducks/boards-ui/hooks'

export type BoardProps = {
  board: BoardShortened
  level: number
  isActive: boolean
  isArchive?: boolean
  contextMenuBuilder: BoardContextMenuBuilderForSidebarBoard
}

export const Board: React.FC<BoardProps> = (props) => {
  const { board, level, isActive, isArchive, contextMenuBuilder } = props
  const { isDesignMode } = useBoardState()
  const isContextMenuEnabled = !board.is_hardcoded && !isDesignMode

  const mainButtonRef = useRef<Maybe<HTMLButtonElement>>(null)
  const titleRef = useRef<Maybe<HTMLElement>>(null)
  const [, setIsSidebarOpen] = useLayoutStateIsSidebarOpened()
  const handleCloseSidebar = useCallback(
    () => setIsSidebarOpen(false),
    [setIsSidebarOpen],
  )
  const [isTitleTooltipEnabled, setIsTitleTooltipEnabled] = useState(false)
  useEffect(() => {
    if (!titleRef.current) {
      return
    }
    setIsTitleTooltipEnabled(
      titleRef.current.offsetWidth < titleRef.current.scrollWidth,
    )
  }, [board.name])
  const contextMenuState = useContextMenuState()
  const contextMenu = useMemo(
    () =>
      contextMenuBuilder({
        board,
      }),
    [board, contextMenuBuilder],
  )
  const contextMenuOrigin = useMemo(() => {
    const anchorOrigin: PopoverOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    }
    const transformOrigin: PopoverOrigin = {
      vertical: 'top',
      horizontal: 32,
    }
    return {
      anchorOrigin,
      transformOrigin,
    }
  }, [])
  const handleOpenContextMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      if (mainButtonRef.current === null) {
        return
      }
      contextMenuState.handleClickOnAnchor({ target: mainButtonRef.current })
    },
    [contextMenuState],
  )
  const toUrl = useKeepPartialDeeplinkUrl(
    boardRoutes.board(board.id),
    BOARD_PRESERVED_DEEPLINK_KEYS,
  )

  return (
    <StyledBoardRoot
      data-testid={`boards-sidebar-board-root-${board.id}`}
      data-board-name={board.name}
      data-context-menu-open={contextMenuState.isOpen ? true : undefined}
      isHighlight={contextMenuState.isOpen}
    >
      <StyledBoardButton
        level={level}
        onClick={board.is_deleted ? undefined : handleCloseSidebar}
        LinkComponent={board.is_deleted ? undefined : Link}
        ref={mainButtonRef}
        {...(board.is_deleted ? {} : { to: toUrl })}
        sx={
          board.is_deleted
            ? {
              cursor: 'default',
            }
            : {}
        }
        data-testid={`boards-sidebar-board-button-${board.id}`}
      >
        <StyledBoardIcon active={isActive} archive={isArchive}>
          <BoardIcon />
        </StyledBoardIcon>
        {isTitleTooltipEnabled ? (
          <Tooltip title={board.name} placement="top" arrow>
            <StyledBoardTitle ref={titleRef} active={isActive} archive={isArchive}>{board.name}</StyledBoardTitle>
          </Tooltip>
        ) : (
          <StyledBoardTitle ref={titleRef} active={isActive} archive={isArchive}>
            {board.name}
          </StyledBoardTitle>
        )}
      </StyledBoardButton>
      {isContextMenuEnabled && (
        <>
          <StyledBoardContextMenuButtonCont>
            <StyledBoardContextMenuButton
              isVisible={contextMenuState.isOpen}
              onClick={handleOpenContextMenu}
              disableTouchRipple={true}
              className="boards-context-menu-button"
              data-testid={`boards-sidebar-board-context-menu-button-${board.id}`}
            >
              <ContextMenuIcon />
            </StyledBoardContextMenuButton>
          </StyledBoardContextMenuButtonCont>
          {contextMenuState.isOpen && (
            <ContextMenu
              menuItems={contextMenu}
              open={true}
              anchorEl={contextMenuState.anchorElement}
              onClose={contextMenuState.handleClose}
              data-testid={`boards-sidebar-board-context-menu-${board.id}`}
              {...contextMenuOrigin}
            />
          )}
        </>
      )}
    </StyledBoardRoot>
  )
}

const BOARD_PRESERVED_DEEPLINK_KEYS: PartialDeeplinkKeeperConfig = [
  { key: 'v' },
  { key: 'diff' },
  { key: 'segmentation' },
  {
    key: 'wboard_date',
    shape: ['aggregationDimensionId', 'dimensionId', 'range', 'periods'],
  },
]
