import React, { useMemo, useState } from 'react'
import { PeriodSelectorContainer } from '../period-selector-container/PeriodSelectorContainer'
import { Version } from '@fintastic/web/util/versions'
import {
  getDefaultPeriodSelection,
  isEmptyPeriodSelection,
  PeriodSelection,
  PeriodSelectionContextValue,
  PeriodSelectorContextInitialValue,
  usePeriodSelectorCalendarAndEntitiesIds,
  usePeriodSelectorContext,
  useCalendarConfigForSelectedEntities,
  validatePeriodSelection,
} from '@fintastic/web/util/period-selector'
import { useCalendarDatePickerQuery } from '@fintastic/web/data-access/calendar'
import { useLoadMultipleVersionsEntities } from '@fintastic/web/data-access/versions'
import { usePersistedPeriodSelectionValue } from './usePersistedPeriodSelectionValue'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { TimeDimensionId } from '@fintastic/web/util/dimensions'
import ErrorIcon from '@mui/icons-material/Error'
import { PeriodSelectorContainerSkeleton } from '../period-selector-container/PeriodSelectorContainerSkeleton'
import { Maybe } from '@fintastic/shared/util/types'

/**
 * Period Selection Context value factory
 * Return ready to use selection component
 * (!) Do not forget to handle `isLoading` and `isError` status outside the component
 */
export const usePeriodSelectionComponent = ({
  selectedVersions,
  persistingKey,
  buttonDesign = 'none',
  baseTimeDimensionId = null,
  defaultSelection = null,
}: {
  selectedVersions: Version[]
  persistingKey?: string
  buttonDesign?: 'full' | 'compact' | 'none'
  baseTimeDimensionId?: Maybe<TimeDimensionId>
  defaultSelection?: Maybe<PeriodSelection>
}): UsePeriodSelectionComponentValue => {
  const parentSelection = usePeriodSelectorContext()

  const { calendarId, entitiesVersionIds } =
    usePeriodSelectorCalendarAndEntitiesIds(selectedVersions)

  const calendarDatePickerQuery = useCalendarDatePickerQuery(calendarId)

  const versionEntitiesQuery =
    useLoadMultipleVersionsEntities(entitiesVersionIds)

  const isLoading =
    calendarDatePickerQuery.isLoading || versionEntitiesQuery.isLoading

  const isError =
    calendarDatePickerQuery.isError || versionEntitiesQuery.isError

  const error = calendarDatePickerQuery.error || versionEntitiesQuery.error

  const datePickerConfigForSelectedEntities =
    useCalendarConfigForSelectedEntities({
      entitiesPerVersion: versionEntitiesQuery.data,
      calendarDatePickerConfig: calendarDatePickerQuery.data ?? null,
      baseTimeDimensionId,
    })

  const activeDefaultSelection = useMemo<PeriodSelection>(() => {
    if (
      isLoading ||
      !datePickerConfigForSelectedEntities ||
      !versionEntitiesQuery.data?.length
    ) {
      return periodSelectionFallback
    }

    if (!isEmptyPeriodSelection(parentSelection)) {
      return parentSelection
    }

    if (!isEmptyPeriodSelection(defaultSelection)) {
      return defaultSelection
    }

    return getDefaultPeriodSelection(datePickerConfigForSelectedEntities)
  }, [
    datePickerConfigForSelectedEntities,
    defaultSelection,
    isLoading,
    parentSelection,
    versionEntitiesQuery.data,
  ])

  const [
    periodSelectionPersistedValue,
    setPeriodSelectionPersistedValue,
    hasPersistedOverride,
  ] = usePersistedPeriodSelectionValue(
    persistingKey,
    activeDefaultSelection,
    datePickerConfigForSelectedEntities,
    baseTimeDimensionId,
  )

  const [periodSelectionLocalValue, setPeriodSelectionLocalValue] =
    useState(defaultSelection)

  const periodSelection = persistingKey
    ? periodSelectionPersistedValue
    : periodSelectionLocalValue

  const setPeriodSelection = persistingKey
    ? setPeriodSelectionPersistedValue
    : setPeriodSelectionLocalValue

  const hasOverride = persistingKey ? hasPersistedOverride : false
  const parentSelectionUsed = useMemo(
    () => (hasOverride ? false : isEmptyPeriodSelection(periodSelection)),
    [hasOverride, periodSelection],
  )

  return useMemo<UsePeriodSelectionComponentValue>(() => {
    if (isError) {
      return {
        isError,
        isLoading: false,
        error,
        contextValue: PeriodSelectorContextInitialValue,
        periodSelectorComponent: <ErrorIcon />,
      }
    }

    if (isLoading) {
      return {
        isLoading,
        isError: false,
        error: null,
        contextValue: PeriodSelectorContextInitialValue,
        periodSelectorComponent:
          buttonDesign === 'none' ? null : (
            <PeriodSelectorContainerSkeleton
              liteButton={buttonDesign === 'compact'}
            />
          ),
      }
    }

    if (
      !entitiesVersionIds ||
      entitiesVersionIds.length === 0 ||
      !datePickerConfigForSelectedEntities
    ) {
      return {
        isLoading,
        isError: false,
        error: null,
        contextValue: PeriodSelectorContextInitialValue,
        periodSelectorComponent:
          buttonDesign === 'none' ? null : (
            <PeriodSelectorContainerSkeleton
              liteButton={buttonDesign === 'compact'}
            />
          ),
      }
    }

    const activePeriodSelection =
      periodSelection === null ||
      !validatePeriodSelection(
        periodSelection,
        datePickerConfigForSelectedEntities,
      )
        ? activeDefaultSelection
        : periodSelection

    const cache: PeriodSelectionContextValue['cache'] = {
      datePickerConfigForSelectedEntities:
        datePickerConfigForSelectedEntities ?? [],
      versionEntities: versionEntitiesQuery.data ?? [],
      fullDatePickerConfig: calendarDatePickerQuery.data ?? [],
    }

    return {
      periodSelectorComponent:
        buttonDesign === 'none' ? null : (
          <FintasticThemeProvider applyLegacyTheme={false}>
            <PeriodSelectorContainer
              periodSelection={activePeriodSelection}
              onPeriodSelected={setPeriodSelection}
              defaultSelection={activeDefaultSelection}
              enableReset={
                !parentSelectionUsed && !isEmptyPeriodSelection(parentSelection)
              }
              liteButton={buttonDesign === 'compact'}
              parentSelectionUsed={parentSelectionUsed}
              versionsEntities={cache.versionEntities}
              calendarConfig={cache.datePickerConfigForSelectedEntities}
            />
          </FintasticThemeProvider>
        ),
      contextValue: {
        periodSelection: activePeriodSelection,
        parentSelectionUsed,
        cache,
      },
      isLoading,
      isError: false,
      error: null,
    }
  }, [
    isError,
    isLoading,
    entitiesVersionIds,
    periodSelection,
    activeDefaultSelection,
    datePickerConfigForSelectedEntities,
    versionEntitiesQuery.data,
    calendarDatePickerQuery.data,
    buttonDesign,
    setPeriodSelection,
    parentSelectionUsed,
    parentSelection,
    error,
  ])
}

const periodSelectionFallback: PeriodSelection = {
  aggregationDimensionId: '',
  dimensionId: '',
  periods: [],
}

export type UsePeriodSelectionComponentValue = {
  contextValue: PeriodSelectionContextValue
  periodSelectorComponent: React.ReactNode
} & (
  | UsePeriodSelectionComponentReadyValue
  | { isLoading: true; isError: false; error: null }
  | { isLoading: false; isError: true; error: unknown }
)

export type UsePeriodSelectionComponentReadyValue = {
  isLoading: false
  isError: false
  error: null
  contextValue: PeriodSelectionContextValue
  periodSelectorComponent: React.ReactNode
}
