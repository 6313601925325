import { IconButton, IconButtonProps, styled } from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'

export const FloatingPanelButton: React.FC<IconButtonProps> = (props) => (
  <FloatingPanelButtonStyled {...props}>
    <MenuIcon fontSize="small" />
  </FloatingPanelButtonStyled>
)

const FloatingPanelButtonStyled = styled(IconButton)`
  opacity: 0;
  transition: opacity .1s ease-in;

  .ag-header-cell:hover & {
    opacity: 1;
  }
`