import { Box, styled } from '@mui/material'

export const StyledChart = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .chart-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`

export const StyledLoadingChart = styled(Box)`
  color: #bbb;
  display: flex;
  flex-flow: column wrap;
  place-content: center;
  place-items: center;
  width: 100%;
`

export const StyledEmptyChart = styled(Box)`
  color: #bbb;
  display: flex;
  flex-flow: column wrap;
  place-content: center;
  place-items: center;
  width: 100%;
  font-size: 14px;

  svg {
    width: 64px;
    height: 64px;
    color: #bbb;
    fill: #bbb;
    margin-bottom: 16px;
  }
`
