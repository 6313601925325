import { useMemo } from 'react'
import type { PeriodSelection } from '@fintastic/web/util/period-selector'
import {
  ChartColumnData,
  ChartRequestParams,
  MetricChartDimensions,
} from '../types'
import { useLoadMetricChartData } from '@fintastic/web/data-access/metrics-and-lists'
import { iterateOverCompact } from '@fintastic/web/util/metrics-and-lists'
import { VersionDimension } from '@fintastic/web/util/dimensions'
import { Version } from '@fintastic/web/util/versions'
import {
  extractDimensionEssential,
  makeProcessCallback,
} from './chart-data-utils'

export const useChartData = (
  versions: string[],
  metricId: string,
  periodSelection: PeriodSelection,
  dimensions: MetricChartDimensions,
  versionDimensions: VersionDimension[],
  versionsMetadata: Record<string, Version>,
) => {
  const {
    data: metricRawData,
    isLoading,
    isFetching,
    isError,
  } = useLoadMetricChartData(versions, metricId, periodSelection, dimensions)

  const metricData = useMemo(() => {
    if (
      isFetching ||
      isLoading ||
      isError ||
      !metricRawData ||
      versionDimensions.length === 0 ||
      Object.keys(versionsMetadata).length === 0
    ) {
      return []
    }

    const localColumns: ChartColumnData = []
    const rows: { versionId: string; rows: Record<string, any> }[] = []

    metricRawData.forEach((versionedRecord) => {
      if (!versionedRecord.metric?.data) {
        return
      }

      const versionChartRecords: Record<string, any> = []

      const processCallback = makeProcessCallback(
        versionedRecord.versionId,
        versionsMetadata,
        versionDimensions,
        versionChartRecords,
        (versionedRecord.metric?.metadata.value.type || '').includes('percent'),
      )

      iterateOverCompact(
        {
          indexes: versionedRecord.metric?.data.indexes || [],
          dimensions: versionedRecord.metric?.data.dimensions || [],
          values: versionedRecord.metric?.data.values || [],
          format: 'compact',
        },
        processCallback,
      )
      //
      rows.push({
        versionId: versionedRecord.versionId,
        rows: versionChartRecords,
      })
    })

    const firstVersionData =
      rows.find((r) => r.versionId === versions[0])?.rows || {}

    const headerRow = firstVersionData[Object.keys(firstVersionData)[0]]

    if (headerRow) {
      const firstRowColumns = (Object.keys(headerRow) || []).filter(
        (name) => name !== '__label',
      )

      const headerLine = ['Label', ...firstRowColumns]

      localColumns.push(headerLine)

      const otherVersions = versions.toSpliced(0, 1)

      for (const [key, value] of Object.entries(firstVersionData)) {
        const dataRow = [
          value.__label,
          ...firstRowColumns.map((timeKey) => value[timeKey]),
        ]

        localColumns.push(dataRow)

        otherVersions.forEach((vId) => {
          const dataSource = rows.find((r) => r.versionId === vId)?.rows

          if (!dataSource) {
            console.log(`Version ${vId} not found in Chart Datasets`)
            return
          }
          const targetRow = dataSource[key]
          if (!targetRow) {
            return
          }
          const versionDataRow = [
            targetRow.__label,
            ...firstRowColumns.map((timeKey) => targetRow[timeKey]),
          ]
          localColumns.push(versionDataRow)
        })
      }
    }

    return localColumns
  }, [
    isError,
    isFetching,
    isLoading,
    metricRawData,
    versionDimensions,
    versions,
    versionsMetadata,
  ])

  return useMemo(() => {
    const request: ChartRequestParams = {
      versions: versions,
      metricId: metricId,
      dimensions: extractDimensionEssential(dimensions),
      periodSelection,
    }

    return {
      isLoading,
      isError,
      data: metricData,
      request,
    }
  }, [
    metricId,
    versions,
    dimensions,
    periodSelection,
    isLoading,
    isError,
    metricData,
  ])
}
