import React, { useCallback, useRef, useState } from 'react'
import {
  FilterActions,
  FilterNoResults,
  FilterPanel,
} from '@fintastic/shared/ui/filters'
import { QuickSearch } from '@fintastic/shared/ui/components'
import { filterUtils, useFilterContext } from '@fintastic/web/util/filters'
import { FilterSpreadByTimeDimension } from './FilterSpreadByTimeDimension'
import { FilterToolPanelNoResultsIfEmpty } from './FilterToolPanel.styled'
import { IToolPanelParams } from 'ag-grid-community'

export const FilterToolPanel: React.FC<IToolPanelParams> = ({ api }) => {
  const filterContext = useFilterContext()
  const apiRef = useRef(api)
  apiRef.current = api

  if (!filterContext) {
    throw new Error('FilterToolPanel depends on FilterContext')
  }

  const { filters } = filterContext

  const [search, setSearch] = useState<string>('')

  const handleApply = useCallback(() => {
    filterContext.apply()
  }, [filterContext])
  const handleCancel = useCallback(() => {
    apiRef.current.closeToolPanel()
  }, [])
  const handleReset = useCallback(() => {
    filterContext.reset()
  }, [filterContext])

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault()
      filterContext.apply()
    },
    [filterContext],
  )

  return (
    <form onSubmit={handleSubmit} style={{ height: '100%' }}>
      <FilterPanel
        searchSlot={<QuickSearch value={search} onChange={setSearch} />}
        footerSlot={
          <FilterActions
            onApply={handleApply}
            onCancel={handleCancel}
            onClearAll={
              filterUtils.isFilterEmpty(filters, filterContext.appliedValues) &&
              filterUtils.isFilterEmpty(filters, filterContext.values)
                ? undefined
                : handleReset
            }
            applyDisabled={!filterContext.dirty}
          />
        }
        data-test-id="api-based-filter-tool-panel"
      >
        <FilterToolPanelNoResultsIfEmpty>
          {filters.map((filter) => (
            <FilterSpreadByTimeDimension
              key={filter.id}
              filter={filter}
              topLevelSearch={search}
            />
          ))}
        </FilterToolPanelNoResultsIfEmpty>
        <FilterNoResults data-no-resutls={1} />
      </FilterPanel>
    </form>
  )
}

FilterToolPanel.displayName = 'FilterToolPanel'
