import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { CalendarDatePickerConfig } from '@fintastic/web/util/period-selector'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  PeriodOption,
  PeriodTypeOption,
  PeriodSelectionPickerConfig,
} from '../components/types'
import { Box } from '@mui/system'
import { CircularProgress, Popover } from '@mui/material'
import { PeriodSelectionStatusPanel } from '../components/PeriodSelectionStatusPanel'
import { PeriodSelectionPicker } from '../components/PeriodSelectionPicker'
import { cleanDimensionLabel, getAggregationTypes } from './utils'
import { VersionEntityWithVersionId } from '@fintastic/web/data-access/versions'
import { periodAggregationLimitsPerResolution } from './utils/period-aggregation-limits'
import { newPeriodsRangeGetterFabric } from './utils/new-period-range-getter'
import { getNewPeriodList } from './utils/getNewPeriodList'
import { partial } from 'lodash'
import { PeriodSelectionLiteButton } from '../components/PeriodSelectionLiteButton'
import { DimensionId } from '@fintastic/web/util/dimensions'

export type PeriodSelectorContainerProps = {
  periodSelection: PeriodSelection
  onPeriodSelected: (selection: PeriodSelection) => void
  defaultSelection: PeriodSelection
  enableReset: boolean
  liteButton?: boolean
  parentSelectionUsed: boolean
  calendarConfig: CalendarDatePickerConfig
  versionsEntities: VersionEntityWithVersionId[]
}

const mapCalendarToConfig = (
  datePickerConfig: CalendarDatePickerConfig,
): PeriodSelectionPickerConfig => {
  const isPeriodOptionDisabled = (dimensionId: DimensionId): boolean =>
    datePickerConfig.find((i) => i.dimension_id === dimensionId) === undefined

  const aggregationTypes = getAggregationTypes(datePickerConfig).map((i) => ({
    ...i,
    label: cleanDimensionLabel(i.label),
    disabled: isPeriodOptionDisabled(i.dimension_id),
  }))
  const aggregationTypesPlain = aggregationTypes.map((i) => i.dimension_id)

  const periodTypes = datePickerConfig.map<PeriodTypeOption>((configItem) => {
    const periods = configItem.values.map<PeriodOption>((configValueItem) => ({
      id: configValueItem.dimension_value_id,
      label: configValueItem.label,
    }))

    return {
      label: cleanDimensionLabel(configItem.label),
      resolution: configItem.resolution,
      dimension_id: configItem.dimension_id,
      periods,
      periodsAmountLimitPerAggregation:
        periodAggregationLimitsPerResolution[configItem.resolution],
    }
  })

  const newPeriodsRangeGetter = newPeriodsRangeGetterFabric(
    datePickerConfig,
    aggregationTypesPlain,
  )

  return {
    periodTypes,
    aggregationTypes,
    recentSelections: [],
    newPeriodsListGetter: partial(
      getNewPeriodList,
      datePickerConfig,
      newPeriodsRangeGetter,
    ),
    newPeriodsRangeGetter,
  }
}

export const PeriodSelectorContainer: React.FC<
  PeriodSelectorContainerProps
> = ({
  onPeriodSelected,
  periodSelection,
  defaultSelection,
  enableReset,
  calendarConfig,
  liteButton = false,
  parentSelectionUsed,
}) => {
  const rootNodeRef = useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const config = useMemo<PeriodSelectionPickerConfig>(
    () => mapCalendarToConfig(calendarConfig),
    [calendarConfig],
  )

  const handleSetNewValue = useCallback(
    (value: PeriodSelection) => {
      onPeriodSelected(value)
      handleClose()
    },
    [handleClose, onPeriodSelected],
  )

  const handleReset = useCallback(() => {
    onPeriodSelected(defaultSelection)
    handleClose()
  }, [defaultSelection, handleClose, onPeriodSelected])

  if (!config) {
    return <CircularProgress size={16} />
  }

  return (
    <Box width={liteButton ? undefined : 240} ref={rootNodeRef}>
      {!liteButton && (
        <PeriodSelectionStatusPanel
          config={config}
          value={periodSelection}
          onChange={handleSetNewValue}
          onRequestPickerOpen={handleOpen}
        />
      )}
      {liteButton && (
        <PeriodSelectionLiteButton
          onClick={handleOpen}
          active={!parentSelectionUsed}
        />
      )}
      <Popover
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        anchorEl={rootNodeRef.current}
      >
        <Box width="500px">
          <PeriodSelectionPicker
            config={config}
            value={periodSelection}
            onChange={handleSetNewValue}
            onCancel={handleClose}
            onRequestReset={enableReset ? handleReset : undefined}
          />
        </Box>
      </Popover>
    </Box>
  )
}
