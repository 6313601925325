/* eslint-disable no-param-reassign */
import type { Maybe } from '@fintastic/shared/util/types'
import type { ListMetricData, MergedDimensions, VersionMetricData } from './expand-metric-data'
import type { RowsMap } from './types'
import { createRowKey, type FieldKeyGetter } from './field-key'
import type { VersionEntities } from '@fintastic/web/util/versions'
import { compact, uniq } from 'lodash'
import { fillNonExistDimsWithOther, getNotExistDimensions } from './utils'

export const populateNullRowsMapForDimensions = (
  versionMetrics: Array<VersionMetricData | ListMetricData>,
  periods: Maybe<string[]>,
  fieldKeyGetter: FieldKeyGetter,
  mutableRowsMap: RowsMap,
  entities: Array<{
    entities?: VersionEntities
    versionId: string
  }>,
  periodDim = '',
  allMetricsDimensions: MergedDimensions = {},
): void => {
  const versionMetric = versionMetrics[0]
  const indexes = (versionMetric.metricData?.indexes || []).filter(
    (indexName) => indexName !== periodDim,
  )

  const entitiesForSelectedVersions = compact(
    versionMetrics.map(({ versionId }) =>
      entities.find((entity) => entity.versionId === versionId),
    ),
  )

  const dimensionsPerIndex = compact(
    indexes.map((indexName) =>
      uniq(
        compact(
          entitiesForSelectedVersions.flatMap((entity) => {
            const d = entity?.entities?.dimensions?.find(
              (dim) => dim.id === indexName,
            )
            return d && 'values' in d ? Object.keys(d.values) : []
          }),
        ),
      ),
    ),
  )

  const nullValueKeys = versionMetrics.flatMap((versionMetricItem) =>
    periods?.length
      ? periods.map((period) =>
          fieldKeyGetter(
            versionMetricItem.versionId,
            versionMetricItem.metricId,
            period,
          ),
        )
      : fieldKeyGetter(
          versionMetricItem.versionId,
          versionMetricItem.metricId,
          null,
        ),
  )

  const nullValues = Object.fromEntries(nullValueKeys.map((key) => [key, null]))

  const addCombinationToRowsMap = (combination: string[]): void => {
    const currentDimensionSet = Object.fromEntries(
      indexes.map((indexName, indexPosition) => [
        indexName,
        combination[indexPosition],
      ]),
    )
    const notExistDims = getNotExistDimensions(
      allMetricsDimensions,
      currentDimensionSet,
      periodDim,
    )

    if (notExistDims.length > 0) {
      fillNonExistDimsWithOther(currentDimensionSet, notExistDims, allMetricsDimensions)
    }

    const key = createRowKey(currentDimensionSet)

    if (!(key in mutableRowsMap)) {
      mutableRowsMap[key] = {
        ...currentDimensionSet,
      }
    }

    Object.assign(mutableRowsMap[key], nullValues)
  }

  const combinations = allCombinations(dimensionsPerIndex)
  combinations.forEach(addCombinationToRowsMap)
}

const allCombinations = (dimensions: Array<string[]>): string[][] => {
  const numberOfCombinations = dimensions.reduce(
    (res, array) => res * array.length,
    1,
  )

  const result: string[][] = Array(numberOfCombinations)
    .fill(0)
    .map(() => [])

  let repeatEachElement

  for (let i = 0; i < dimensions.length; i++) {
    const array = dimensions[i]

    repeatEachElement = repeatEachElement
      ? repeatEachElement / array.length
      : numberOfCombinations / array.length

    const everyElementRepeatedLength = repeatEachElement * array.length

    for (let j = 0; j < numberOfCombinations; j++) {
      const index = Math.floor(
        (j % everyElementRepeatedLength) / repeatEachElement,
      )
      result[j][i] = array[index]
    }
  }

  return result
}
