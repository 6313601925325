import { useCallback, useMemo, useRef, useState } from 'react'
import type { Maybe } from '@fintastic/shared/util/types'
import { Filter, FilterValue, FilterValuesModel } from '../types'
import { filterUtils } from '../utils'
import { FilterContextValue } from './FilterContext'
import { useLocalStorage } from 'usehooks-ts'

export const useFilterContextValue = ({
  filters,
  versionId,
  localStorageKey,
  onBeforeFilterApply,
}: UseFilterContextValueParams): FilterContextValue => {
  const [appliedFilterValues, setAppliedFilterValues] =
    useLocalStorage<FilterValuesModel>(
      localStorageKey,
      DEFAULT_FILTERS_VALUE_MODEL,
    )

  const [filterValues, setFilterValues] = useState<FilterValuesModel>(
    () => appliedFilterValues,
  )

  const setFilterValue = useCallback(
    (
      filterId: string,
      timeDimensionValueId: Maybe<string>,
      value?: FilterValue,
    ) => {
      const f = filters.find(({ id }) => id === filterId)

      if (!f) {
        return
      }

      setFilterValues((prev) =>
        filterUtils.addValueToModel(prev, f, timeDimensionValueId, value),
      )
    },
    [filters],
  )

  const resetFilter = useCallback(
    (filterId: string, timeDimensionValueId: Maybe<string>) => {
      const f = filters.find(({ id }) => id === filterId)

      if (!f) {
        return
      }

      const currentAppliedValue = filterUtils.getValueFromTheModel(
        appliedFilterValues,
        filterId,
        timeDimensionValueId,
      )
      setFilterValues((prev) =>
        filterUtils.addValueToModel(
          prev,
          f,
          timeDimensionValueId,
          currentAppliedValue,
        ),
      )
    },
    [appliedFilterValues, filters],
  )

  const filterValuesRef = useRef(filterValues)
  filterValuesRef.current = filterValues

  const apply = useCallback(() => {
    onBeforeFilterApply(filterValuesRef.current)
    setAppliedFilterValues(filterValuesRef.current)
  }, [onBeforeFilterApply, setAppliedFilterValues])

  const reset = useCallback(() => {
    setAppliedFilterValues({})
    setFilterValues({})
  }, [setAppliedFilterValues])

  return useMemo<FilterContextValue>(() => {
    const dirty = !filterUtils.areFilterModelsEqual(
      filters,
      filterValues,
      appliedFilterValues,
    )

    return {
      versionId,
      filters,
      appliedValues: appliedFilterValues,
      values: filterValues,
      setAllValues: setFilterValues,
      setFilterValue,
      resetFilter,
      apply,
      reset,
      dirty,
      filterIsApplied: !filterUtils.isFilterEmpty(filters, appliedFilterValues),
    }
  }, [
    filters,
    filterValues,
    appliedFilterValues,
    versionId,
    setFilterValue,
    resetFilter,
    apply,
    reset,
  ])
}

export type UseFilterContextValueParams = {
  versionId: string
  localStorageKey: string
  filters: Filter[]
  onBeforeFilterApply: (nextValuesModel: FilterValuesModel) => void
}

const DEFAULT_FILTERS_VALUE_MODEL = {}
