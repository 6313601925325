import React, { useEffect, useMemo, useRef } from 'react'
import {
  PageLayout,
  PageLayoutContentError,
  PageLayoutContentLoader,
  useLayoutStateIsDiscussionsOpened,
} from '@fintastic/shared/ui/app-layout-framework'
import { BoardsTopbar } from '../components/BoardsTopbar'
import { BoardDisplayView } from '../components/BoardView'
import { useBoardPageParams } from '../hooks/useBoardPageParams'
import { useBoardQuery } from '../hooks/useBoardQuery'
import { ErrorBoundary } from '@fintastic/shared/ui/components'
import { useBoardSelectedVersions } from '../hooks/useBoardSelectedVersions'
import { usePeriodSelectionComponent } from '@fintastic/web/feature/period-selector'
import {
  BaseTimeDimensionDynamicFilteredProvider,
  useBaseTimeDimensionDynamicFilteredAdapter,
} from '@fintastic/web/data-access/base-time-dimension'
import { PeriodSelectorContext } from '@fintastic/web/util/period-selector'
import { useBoardState } from '@fintastic/web/feature/boards'
import { BoardDesignView } from '../components/BoardDesigner/BoardDesignView'
import { useArchivedBoardGuard } from '../hooks/useArchivedBoardGuard'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { useAutoHideGlobalVersionLockWarning } from '@fintastic/web/data-access/versions'

type BoardContainerProps = {
  defaultVersions: string[]
}

export const BoardContainer: React.FC<BoardContainerProps> = ({
  defaultVersions,
}) => {
  const { id: boardId } = useBoardPageParams()

  const { data, isLoading, error, isError, refetch } = useBoardQuery({
    boardId,
  })

  const { versions: selectedVersions, deeplinkInvalidated } =
    useBoardSelectedVersions({ defaultVersions })

  const invalidationMessageDisplayedRef = useRef(false)

  const baseTimeDimensionContextValue =
    useBaseTimeDimensionDynamicFilteredAdapter()

  const periodSelectionContainer = usePeriodSelectionComponent({
    selectedVersions,
    persistingKey: 'board',
    baseTimeDimensionId: baseTimeDimensionContextValue.baseTimeDimensionId,
    buttonDesign: 'full',
  })

  useEffect(() => {
    if (!deeplinkInvalidated || invalidationMessageDisplayedRef.current) {
      return
    }
    invalidationMessageDisplayedRef.current = true
    toast.error(
      'One or more of the selected version(s) have been archived or unshared. Available versions are shown.',
    )
  }, [deeplinkInvalidated])

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  useEffect(() => {
    if (periodSelectionContainer.error) {
      console.error(periodSelectionContainer.error)
    }
  }, [periodSelectionContainer.error])

  const { isDesignMode, setDesignMode, setDisplayMode } = useBoardState()

  // to redirect if board is archived
  useArchivedBoardGuard(data)

  useEffect(
    () => () => {
      if (boardId) {
        setDisplayMode() // to reset design mode on navigate
      }
    },
    [boardId],
  )

  const [isOpen, setIsOpen] = useLayoutStateIsDiscussionsOpened()
  useEffect(() => {
    if (isOpen && (!selectedVersions || selectedVersions.length === 0)) {
      setIsOpen(false)
    }
  }, [selectedVersions])

  const versionIds = useMemo(
    () => selectedVersions.map((v) => v.uuid),
    [selectedVersions],
  )
  useAutoHideGlobalVersionLockWarning(versionIds)

  if (periodSelectionContainer.isError) {
    return (
      <PageLayoutContentError
        title="Can't load the board"
        message="Version entities or calendar could not be loaded."
      />
    )
  }

  if (isError) {
    return (
      <PageLayoutContentError
        title="Can't load the board"
        message="The Board you are trying to access is not shared with you or has been archived or deleted. Please try again or contact your administrator for assistance."
        tryAgainCallback={refetch}
      />
    )
  }

  if (isLoading) {
    return (
      <PageLayout
        disableScrollX={true}
        topbar={
          <BoardsTopbar
            defaultVersions={defaultVersions}
            periodSelectionComponent={
              periodSelectionContainer.periodSelectorComponent
            }
          />
        }
      >
        <PageLayoutContentLoader />
      </PageLayout>
    )
  }

  if (!selectedVersions || selectedVersions.length === 0) {
    return (
      <PageLayout
        disableScrollX={true}
        topbar={
          <BoardsTopbar
            defaultVersions={defaultVersions}
            noComments={true}
            periodSelectionComponent={null}
          />
        }
      >
        <PageLayoutContentError
          title="The version is not available"
          message="The version you are trying to access is not shared with you anymore, or has been archived or deleted. Please select another version or contact your administrator for assistance."
        />
      </PageLayout>
    )
  }
  return (
    <PeriodSelectorContext.Provider
      value={periodSelectionContainer.contextValue}
    >
      <BaseTimeDimensionDynamicFilteredProvider
        value={baseTimeDimensionContextValue}
      >
        <PageLayout
          disableScrollX={true}
          topbar={
            <BoardsTopbar
              defaultVersions={defaultVersions}
              periodSelectionComponent={
                periodSelectionContainer.periodSelectorComponent
              }
              isDesignMode={isDesignMode}
            />
          }
        >
          {data && (
            <ErrorBoundary key={data.id}>
              {isDesignMode ? (
                <BoardDesignView
                  board={data}
                  defaultVersions={defaultVersions}
                  setDisplayMode={setDisplayMode}
                />
              ) : (
                <BoardDisplayView
                  board={data}
                  defaultVersions={defaultVersions}
                  setDesignMode={setDesignMode}
                />
              )}
            </ErrorBoundary>
          )}
        </PageLayout>
      </BaseTimeDimensionDynamicFilteredProvider>
    </PeriodSelectorContext.Provider>
  )
}
