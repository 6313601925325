import { PeriodResolution } from '@fintastic/web/util/period-selector'
import { PeriodSelectionPickerConfig } from '../types'
import { DimensionId } from '@fintastic/web/util/dimensions'

export const getMaximumAllowedPeriodsLength = (
  c: PeriodSelectionPickerConfig,
  periodsType: DimensionId,
  resolution: PeriodResolution,
): number =>
  c.periodTypes.find((pt) => pt.dimension_id === periodsType)
    ?.periodsAmountLimitPerAggregation[resolution] || 2
