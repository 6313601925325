import {
  createValueFormatter,
  getDataTypeGroup,
  Metric,
  MetricDataValue,
} from '@fintastic/web/util/metrics-and-lists'
import { ColDef, ValueFormatterFunc } from 'ag-grid-community'
import React, { useMemo } from 'react'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import { compact } from 'lodash'
import { makeXAxisCoordinateKey } from './coordinates'
import { ColGroupDef } from 'ag-grid-community/dist/lib/entities/colDef'
import {
  CategoryDimensionWrapper,
  DimensionId,
  TimeDimensionId,
  TimeDimensionValueId,
  TimeDimensionWrapper,
  WrappedDimension,
} from '@fintastic/web/util/dimensions'
import {
  BLANK_VALUE_UI_REPRESENTATION,
  containsBlankValue,
  containsMaskedValue,
  isRawBlankValue,
  MASKED_VALUE_UI_REPRESENTATION,
} from '@fintastic/web/util/blanks-and-masked'
import { currencies } from '@fintastic/shared/data-access/currencies'
import dayjs from 'dayjs'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'
import { ColumnFormulaButton } from '../features/formulas'

type Context = {
  twoLevelsHeader: boolean
  versionId: string
  timeDimensionId: Maybe<TimeDimensionId>
  isCalculatedList: boolean
  isLiveActuals: boolean
}

type PostProcessors = {
  column: (colDef: ColDef, context: { columnId: string }) => ColDef
  periodColumnGroup: (
    colGroup: ColGroupDef,
    context: { columnId: string },
  ) => ColGroupDef
  periodSubColumn: (
    colDef: ColDef,
    context: { columnId: string; period: TimeDimensionValueId },
  ) => ColDef
}

export const useColDefs = (
  versionId: string,
  columns: Metric[],
  context: {
    isCalculatedList: boolean
    isLiveActuals: boolean
  },
  deps: {
    versionDimensions: WrappedDimension[]
  },
  postProcessors?: Partial<PostProcessors>,
) =>
  useMemo(
    () => columnsToColDefs(versionId, columns, context, deps, postProcessors),
    [columns, deps, versionId, context, postProcessors],
  )

const columnsToColDefs = (
  versionId: string,
  columns: Metric[],
  context: {
    isCalculatedList: boolean
    isLiveActuals: boolean
  },
  deps: {
    versionDimensions: WrappedDimension[]
  },
  postProcessors?: Partial<PostProcessors>,
): (ColDef | ColGroupDef)[] => {
  const timeDimensionId = toMaybe(
    columns.find((c) => c.metadata.time_dimension_id)?.metadata
      .time_dimension_id,
  )

  const dimensionsMap = Object.fromEntries(
    deps.versionDimensions.map((d) => [d.id, d]),
  )

  return compact(
    columns.map((c) =>
      columnToColDef(
        c,
        {
          ...context,
          versionId,
          twoLevelsHeader: timeDimensionId !== null,
          timeDimensionId,
        },
        {
          versionDimensions: dimensionsMap,
        },
        postProcessors,
      ),
    ),
  )
}

type Dependencies = {
  versionDimensions: Record<DimensionId, WrappedDimension>
}

const columnToColDef = (
  column: Metric,
  context: Context,
  deps: Dependencies,
  postProcessors?: Partial<PostProcessors>,
): Maybe<ColDef | ColGroupDef> => {
  const wrapper = new ListColumnWrapper(column)

  if (wrapper.breakdownByTimeAllowed()) {
    const colGroupDef = timeColumnToColDef(
      wrapper,
      context,
      deps,
      postProcessors?.periodSubColumn,
    )

    return (
      postProcessors?.periodColumnGroup?.(colGroupDef, {
        columnId: wrapper.id(),
      }) || colGroupDef
    )
  }

  let colDef: ColDef

  switch (wrapper.type()) {
    case 'calculated': {
      if (wrapper.valuesDimensionId() !== null) {
        colDef = {
          ...dimensionColumnToColDef(wrapper, context, deps),
          headerComponentParams: {
            contentAfterText: (
              <ColumnFormulaButton
                metricId={wrapper.id()}
                formulaIcon={context.isLiveActuals ? 'ax' : 'fx'}
                disabled={context.isCalculatedList}
              />
            ),
          },
        }
      } else {
        colDef = nonTimeColumnToColDef(wrapper, context)
      }
      break
    }
    case 'input': {
      colDef = nonTimeColumnToColDef(wrapper, context)
      break
    }
    case 'dimension': {
      colDef = dimensionColumnToColDef(wrapper, context, deps)
      break
    }
  }

  return postProcessors?.column?.(colDef, { columnId: wrapper.id() }) || colDef
}

const makeBaseColDef = (): Partial<ColDef> => ({
  cellClass: () => ['readonly'],
  headerClass: () => ['readonly'],
  suppressMenu: true,
  valueGetter: (params) => params.data[params.colDef.field || ''],
  cellRenderer: 'defaultCellRenderer',
  headerComponent: 'defaultColumnHeader',
  resizable: true,
})

const dimensionColumnToColDef = (
  column: ListColumnWrapper,
  { versionId }: Context,
  deps: Dependencies,
): ColDef => {
  const dimension = toMaybe(
    deps.versionDimensions[column.valuesDimensionId() || ''],
  )

  return {
    ...makeBaseColDef(),
    field: makeXAxisCoordinateKey({
      versionId,
      metricId: column.id(),
      period: null,
    }),
    headerName: column.label(),
    headerTooltip: column.label(),

    valueFormatter: ({ value }) => {
      if (typeof value !== 'string') {
        if (containsMaskedValue(value)) {
          return MASKED_VALUE_UI_REPRESENTATION
        }
      }
      if (!dimension || !(dimension instanceof CategoryDimensionWrapper)) {
        return value
      }
      return dimension.resolveValueLabel(value) || value
    },
  }
}

const nonTimeColumnToColDef = (
  column: ListColumnWrapper,
  { versionId, isCalculatedList, isLiveActuals }: Context,
): ColDef => {
  const isCalculated = column.isCalculated()

  return {
    ...makeBaseColDef(),
    field: makeXAxisCoordinateKey({
      versionId,
      metricId: column.id(),
      period: null,
    }),
    headerName: column.label(),
    headerTooltip: column.label(),
    headerComponentParams: {
      contentAfterText: isCalculated ? (
        <ColumnFormulaButton
          metricId={column.id()}
          formulaIcon={isLiveActuals ? 'ax' : 'fx'}
          disabled={isCalculatedList}
        />
      ) : undefined,
    },
    valueFormatter: makeValueColumnValueFormatter(column),
    type:
      getDataTypeGroup(column.dataType()) === 'numeric'
        ? 'numericColumn'
        : undefined,
  }
}

const timeColumnToColDef = (
  column: ListColumnWrapper,
  { versionId, isCalculatedList, isLiveActuals }: Context,
  deps: Dependencies,
  subColumnPostProcessor?: (
    colDef: ColDef,
    context: { columnId: string; period: TimeDimensionValueId },
  ) => ColDef,
): ColGroupDef => {
  const timeDimensionId = column.timeDimension() as string
  const timeDimension = toMaybe(deps.versionDimensions[timeDimensionId])
  if (!timeDimension || !(timeDimension instanceof TimeDimensionWrapper)) {
    throw new Error(`Can't resolve time dimension "${timeDimensionId}"`)
  }
  const allPeriods = timeDimension.orderedValuesIds
  const existingPeriods = [
    ...(column.data().findDimension((id) => id === timeDimensionId)?.valueIds ||
      []),
  ]
  existingPeriods.sort((a, b) => allPeriods.indexOf(a) - allPeriods.indexOf(b))

  return {
    headerName: column.label(),
    headerTooltip: column.label(),
    headerGroupComponent: 'defaultColumnHeaderGroup',
    ...(column.isCalculated()
      ? {
          headerGroupComponentParams: {
            contentAfterText: (
              <ColumnFormulaButton
                metricId={column.id()}
                formulaIcon={isLiveActuals ? 'ax' : 'fx'}
                disabled={isCalculatedList}
              />
            ),
          },
        }
      : {}),
    children: existingPeriods.map<ColDef>((period) => {
      const colDef: ColDef = {
        ...makeBaseColDef(),
        field: makeXAxisCoordinateKey({
          versionId,
          metricId: column.id(),
          period,
        }),
        headerName: timeDimension.resolveValueLabel(period) || period,
        headerTooltip: column.label(),
        valueFormatter: makeValueColumnValueFormatter(column),
        type:
          getDataTypeGroup(column.dataType()) === 'numeric'
            ? 'numericColumn'
            : undefined,
      }

      return (
        subColumnPostProcessor?.(colDef, {
          columnId: column.id(),
          period,
        }) || colDef
      )
    }),
  }
}

export const makeValueColumnValueFormatter = (
  column: ListColumnWrapper,
): ValueFormatterFunc<MetricDataValue> => {
  const valueFormatter = makeValueFormatter(column)

  return (params) => {
    if (params.value === '' || params.value === undefined) {
      return ''
    }

    if (isRawBlankValue(params.value) || containsBlankValue(params.value)) {
      return BLANK_VALUE_UI_REPRESENTATION
    }

    if (containsMaskedValue(params.value)) {
      return MASKED_VALUE_UI_REPRESENTATION
    }

    return valueFormatter ? valueFormatter(params) : params.value
  }
}

const makeValueFormatter = (column: ListColumnWrapper) => {
  const dataType = column.dataType()

  const numericValueFormatter = createValueFormatter(
    dataType,
    column.displaySettings().unwrap(),
    dataType === 'currency'
      ? currencies.find((c) => c.code === column.displaySettings().currency())
      : undefined,
  )

  if (numericValueFormatter) {
    return numericValueFormatter
  }

  if (dataType === 'datetime') {
    return dateTimeValueFormatter
  }

  return null
}

const dateTimeValueFormatter: ValueFormatterFunc<MetricDataValue> = ({
  value,
}) => dayjs(value).format(DEFAULT_DAYJS_DATE_FORMAT)
