import React, { useCallback, useMemo, useRef } from 'react'
import { Board, BoardConfig } from '../../types'
import {
  StyledBoardViewContent,
  StyledBoardViewRoot,
} from './BoardDisplayView.styled'
import { useBoardsParams } from '../../hooks/boards-params/hooks'
import { useModalState } from '@fintastic/shared/util/modal'
import { useLocalBoardLayout } from '../../hooks/useLocalBoardLayout'
import { CurrentBoardArchiveDialog } from '../BoardDesigner/components/modals/CurrentBoardArchiveDialog'
import { BoardTitleMenu } from '../BoardDesigner/components/BoardTitle/BoardTitleMenu'
import { BoardDisplayName } from '../BoardDesigner/components/BoardName/BoardDisplayName'
import { StyledBoardTitleBox } from '../BoardDesigner/components/BoardTitle/BoardTitle.styled'
import { CircularProgress } from '@mui/material'
import { BoardArchived } from '../BoardArchived/BoardArchived'
import { areWidgetsSettingsUpdated } from './board-display-utils'
import { useBoardUpdateMutation } from '@fintastic/web/feature/boards'
import { BoardDebug } from '../BoardDebug'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'
import { isEqual } from 'lodash'
import { widgetsRenderers } from '../widgets'
import { WidgetsArea } from '@fintastic/shared/ui/widgets-framework'

export type BoardViewProps = {
  board: Board
  defaultVersions: string[]
  setDesignMode: () => void
}

export const BoardDisplayView: React.FC<BoardViewProps> = ({
  board,
  defaultVersions,
  setDesignMode,
}) => {
  const archiveBoardDialog = useModalState()

  const { mutateAsync: mutateBoardConfig } = useBoardUpdateMutation({
    boardId: board.id,
    refetchItem: true,
    refetchList: true,
    updateItemCache: true,
    updateListCache: true,
  })

  const [localBoardLayout, setLocalBoardLayout] = useLocalBoardLayout(board)

  const boardConfigSource = useMemo<BoardConfig>(() => {
    if (localBoardLayout?.length) {
      return {
        widgets: board.config.widgets || [],
        layout: localBoardLayout,
      }
    }

    return {
      widgets: board.config.widgets || [],
      layout: board.config.layout || [],
    }
  }, [board.config, localBoardLayout])

  const boardConfig = useReferenceMemo(boardConfigSource, isEqual)
  const boardsParamsSource = useBoardsParams(defaultVersions)
  const boardsParams = useReferenceMemo(boardsParamsSource, isEqual)
  const originalBoardConfigRef = useRef(board.config)
  originalBoardConfigRef.current = board.config

  const handleUpdateLocalDesign = useCallback(
    async (config: BoardConfig, manualChange?: boolean) => {
      // apply changes + save layout to localStorage
      if (manualChange) {
        setLocalBoardLayout(config.layout ?? [])
      }

      // if settings data changed - save as well but to the database
      const hasContentChanges = areWidgetsSettingsUpdated(
        originalBoardConfigRef.current?.widgets || [],
        config.widgets || [],
      )

      if (hasContentChanges) {
        try {
          await mutateBoardConfig({
            config: {
              widgets: config.widgets,
              layout: originalBoardConfigRef.current.layout,
            },
          })
        } catch (e) {
          console.log(e)
          toast.error('Error on save board changes!')
        }
      }
    },
    [setLocalBoardLayout, mutateBoardConfig],
  )

  if (board.is_deleted) {
    return <BoardArchived />
  }

  return (
    <>
      <StyledBoardViewRoot data-testid="board-view">
        <StyledBoardTitleBox>
          <BoardDisplayName name={board.name} />

          <BoardTitleMenu
            onArchiveBoard={archiveBoardDialog.open}
            setDesignMode={setDesignMode}
          />
        </StyledBoardTitleBox>

        <StyledBoardViewContent className="view-mode">
          {!boardConfig && <CircularProgress className="board-loading" />}
          {boardConfig && (
            <WidgetsArea
              boardConfig={boardConfig}
              onChangeBoardConfig={handleUpdateLocalDesign}
              boardParams={boardsParams}
              widgetsRenderers={widgetsRenderers}
            />
          )}
        </StyledBoardViewContent>
      </StyledBoardViewRoot>

      <BoardDebug board={board} />

      <CurrentBoardArchiveDialog
        state={archiveBoardDialog}
        boardId={board.id}
        boardName={board.name}
      />
    </>
  )
}
