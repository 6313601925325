import { useLoadMetric } from '@fintastic/web/data-access/metrics-and-lists'
import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'
import { useMemo } from 'react'
import { toMaybe } from '@fintastic/shared/util/types'
import { useVersionEntitiesContextValue } from '@fintastic/web/data-access/versions'
import { MetricChartDimensions } from '../types'

// @todo: replace with new API to get dimensions
export const useMetricDimensions = (metricId: string, versions: string[]) => {
  const periodSelection = usePeriodSelectorContext()
  const metric = useLoadMetric(versions[0], metricId, periodSelection, true)
  const entitiesContextValue = useVersionEntitiesContextValue(versions)

  const loading = metric.isLoading || entitiesContextValue.isLoading
  // source: dimensionsLabelMapFromMetricDataList,
  // frontend/main-client/libs/web/feature/metrics-and-lists/src/lib/components/metric-grid/features/dimensions/dimensionsLabelMapFromMetricDataList.ts

  return useMemo(() => {
    const dimensions: MetricChartDimensions = []

    const metricData = metric.data?.data
    const metricDimensions = metric.data?.metadata.dimensions

    const versionEntities = toMaybe(
      entitiesContextValue.entities?.[versions[0]],
    )

    if (metricData?.indexes) {
      dimensions.push({
        id: 'Version',
        label: 'Version',
        type: 'Version',
      })
    }

    metricData?.indexes.forEach((dimId) => {
      // @todo remove dimensions metadata usage
      const localDimension = metricDimensions?.find(
        (d) => d.id === dimId && d.type === 'Range' && d.values !== undefined,
      )
      const versionDimension = versionEntities
        ?.findDimensionById(dimId)
        ?.unwrap()

      const dimType =
        localDimension?.type || versionDimension?.type || 'Category'

      dimensions.push({
        id: dimId,
        type: dimType,
        label: versionDimension?.label || dimId,
        ...(dimType === 'Time'
          ? { time_dimension: true }
          : { aggregate: false }),
      })
    })

    return {
      dimensions,
      loading,
      error: metric.error,
    }
  }, [
    metric.data?.data,
    metric.data?.metadata.dimensions,
    metric.error,
    entitiesContextValue.entities,
    versions,
    loading,
  ])
}
