import { Box, Skeleton } from '@mui/material'
import React from 'react'
import {
  StyledLoadingDimensionRows,
  StyledLoadingDimensions,
} from './MetricChartFormWithDataSource.styled'

export const DimensionsLoadingRow: React.FC = () => (
  <Box flexDirection={'row'} display={'flex'} width="100%" sx={{ height: 32 }}>
    <Skeleton height={36} width={30} />
    <Skeleton height={36} width={'100%'} sx={{ marginLeft: 2 }} />
    <Skeleton height={36} width={50} sx={{ marginLeft: 2 }} />
  </Box>
)

export const DimensionsLoadingSkeleton: React.FC = () => (
  <StyledLoadingDimensions>
    <StyledLoadingDimensionRows>
      <DimensionsLoadingRow />
      <DimensionsLoadingRow />
      <DimensionsLoadingRow />
    </StyledLoadingDimensionRows>
  </StyledLoadingDimensions>
)
