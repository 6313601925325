import React, { useCallback, useMemo } from 'react'
import { FilterDateRange, FilterValue } from '@fintastic/web/util/filters'
import { Box } from '@mui/material'
import { isArray } from 'lodash'
import type { Maybe } from '@fintastic/shared/util/types'
import dayjs, { Dayjs } from 'dayjs'
import { formatDate } from './format-date'
import { DatePicker } from '@mui/x-date-pickers'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'
import { CalendarIcon } from '@fintastic/shared/ui/icons'

export const RangedDateField: React.FC<RangedDateFieldProps> = ({
  filterValue,
  onChange,
}) => {
  const [from, to] = isValidDateRange(filterValue.value)
    ? filterValue.value
    : []

  const [parsedFrom, parsedTo] = useMemo(
    () =>
      [
        from ? dayjs(from, DEFAULT_DAYJS_DATE_FORMAT) : null,
        to ? dayjs(to, DEFAULT_DAYJS_DATE_FORMAT) : null,
      ] as const,
    [from, to],
  )

  const handleChangeFrom = useCallback(
    (value: Maybe<Dayjs>) => {
      onChange({
        value: [formatDate(value), to ?? ''],
        operator: filterValue.operator ?? 'range',
      })
    },
    [filterValue.operator, onChange, to],
  )

  const handleChangeTo = useCallback(
    (value: Maybe<Dayjs>) => {
      onChange({
        value: [from ?? '', formatDate(value)],
        operator: filterValue.operator ?? 'range',
      })
    },
    [filterValue.operator, from, onChange],
  )

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <DatePicker<Dayjs>
        value={parsedFrom}
        onChange={handleChangeFrom}
        slotProps={{ textField: { size: 'small' } }}
        slots={{
          openPickerIcon: CalendarIcon,
        }}
        format={DEFAULT_DAYJS_DATE_FORMAT}
      />
      <DatePicker<Dayjs>
        value={parsedTo}
        onChange={handleChangeTo}
        slotProps={{ textField: { size: 'small' } }}
        slots={{
          openPickerIcon: CalendarIcon,
        }}
        format={DEFAULT_DAYJS_DATE_FORMAT}
      />
    </Box>
  )
}

export type RangedDateFieldProps = {
  filterValue: Partial<FilterValue<FilterDateRange>>
  onChange: (nextValue: FilterValue<FilterDateRange>) => void
}

const isValidDateRange = (range: unknown): range is FilterDateRange =>
  isArray(range) && range.length === 2
