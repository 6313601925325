import { IHeaderParams } from 'ag-grid-community'
import React, { useCallback } from 'react'
import { useFilterContext } from '@fintastic/web/util/filters'
import { FilterGroupContainer } from '../tool-panel/FilterGroupContainer'
import { Box, Button, Divider } from '@mui/material'
import { useColumnFilter } from './hooks/useColumnFilter'

export const FloatingPanelFilters: React.FC<IHeaderParams> = ({ column }) => {
  const filterContext = useFilterContext()
  if (!filterContext) {
    throw new Error('FloatingPanelFilters depends on FilterContext')
  }

  const { currentFilter, period, metricId, dirty } = useColumnFilter(
    column.getColId(),
  )

  const handleApply = useCallback(() => {
    filterContext.apply()
  }, [filterContext])

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault()
      filterContext.apply()
    },
    [filterContext],
  )

  const handleClear = useCallback(() => {
    filterContext.setFilterValue(metricId, period ?? null, undefined)
  }, [filterContext, metricId, period])

  if (!currentFilter) {
    return null
  }

  return (
    <Box>
      <Box px={1} maxHeight="300px" sx={{ overflowY: 'auto' }}>
        <form onSubmit={handleSubmit}>
          <FilterGroupContainer
            filter={currentFilter}
            timeDimensionValueId={period ?? null}
            disableClear
          />
        </form>
      </Box>
      <Divider />
      <Box display="flex" p={1} pt={1}>
        <Box alignSelf="flex-end" display="flex" gap={1} ml="auto">
          <Button onClick={handleClear}>Clear</Button>
          <Button variant="contained" onClick={handleApply} disabled={!dirty}>
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
