import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Widget,
  WidgetChangeSettingsCallback,
  WidgetLayout,
  WidgetRendererDefinition,
  WidgetRenderersMap,
} from '../../types'
import type { BoardConfig, BoardParams } from '@fintastic/web/feature/boards'
import { default as GridLayout, WidthProvider } from 'react-grid-layout'
import type { Maybe } from '@fintastic/shared/util/types'
import produce from 'immer'
import { WidgetWrapper } from '../WidgetWrapper'
import {
  StyledWidgetsAreaContainer,
  StyledWidgetsAreaRoot,
  StyledWidgetsAreaSingleRoot,
  StyledWidgetsAreaWrapper,
} from './WidgetsArea.styled'
import {
  WIDGETS_GRID_DEFAULT_COLUMNS_NUMBER,
  WIDGETS_GRID_DEFAULT_ROW_HEIGHT_IN_PIXELS,
} from '../../const'
import { WidgetResizeHandler } from '../WidgetResizeHandler'
import { DRAGGABLE_HANDLE_CLASS_NAME } from '@fintastic/shared/ui/panel-framework'

const ResponsiveGridLayout = WidthProvider(GridLayout)

export type WidgetsDesignAreaProps = {
  boardConfig: BoardConfig
  onChangeBoardConfig: (config: BoardConfig, manualChange?: boolean) => void
  boardParams: BoardParams
  widgetContextMenuBuilder?: (widget: Widget) => React.ReactNode
  widgetsRenderers: WidgetRenderersMap
}

export const WidgetsArea: React.FC<WidgetsDesignAreaProps> = ({
  widgetsRenderers,
  boardConfig,
  onChangeBoardConfig,
  boardParams,
  widgetContextMenuBuilder,
}) => {
  const [isEnabledWidgetTransition, setIsEnabledWidgetTransition] =
    useState(false)

  useEffect(() => {
    const tid = setTimeout(() => {
      setIsEnabledWidgetTransition(true)
    }, 100)
    return () => clearTimeout(tid)
  }, [])

  const rglWrapperDomNodeRef = useRef<Maybe<HTMLDivElement>>(null)

  const handleDisableUserSelection = useCallback(() => {
    rglWrapperDomNodeRef.current?.classList.add('disable-user-selection')
  }, [])

  const hasUserInteractedRef = useRef(false)
  const handleEnableUserSelection = useCallback(() => {
    hasUserInteractedRef.current = true
    rglWrapperDomNodeRef.current?.classList.remove('disable-user-selection')
  }, [])

  const handleCollapse = useCallback(() => {
    hasUserInteractedRef.current = true
  }, [])

  const boardConfigRef = useRef(boardConfig)
  boardConfigRef.current = boardConfig

  const handleChangeLayout = useCallback(
    (layouts: WidgetLayout[]) => {
      onChangeBoardConfig(
        produce(boardConfigRef.current, (config) => {
          // eslint-disable-next-line no-param-reassign
          config.layout = layouts
        }),
        hasUserInteractedRef.current,
      )
    },
    [onChangeBoardConfig],
  )

  const handleChangeWidgetLayout = useCallback(
    (layout: WidgetLayout) => {
      handleChangeLayout(
        produce(boardConfigRef.current.layout || [], (mutableLayouts) => {
          const widgetLayoutIndex = mutableLayouts.findIndex(
            (existingLayout) => existingLayout.i === layout.i,
          )
          if (widgetLayoutIndex === -1) {
            return
          }
          // eslint-disable-next-line no-param-reassign
          mutableLayouts[widgetLayoutIndex] = layout
        }),
      )
    },
    [handleChangeLayout],
  )

  const handleChangeWidgetSettings = useCallback<WidgetChangeSettingsCallback>(
    (id, newSettings) => {
      onChangeBoardConfig(
        produce(boardConfigRef.current, (config) => {
          const widgetIndex = (config.widgets || []).findIndex(
            (widget) => widget.id === id,
          )
          if (widgetIndex === -1) {
            return
          }

          if (config.widgets === undefined) {
            // eslint-disable-next-line no-param-reassign
            config.widgets = []
          }

          // eslint-disable-next-line no-param-reassign
          config.widgets[widgetIndex].settings = {
            ...config.widgets[widgetIndex].settings,
            ...newSettings,
          }
        }),
        true,
      )
    },
    [onChangeBoardConfig],
  )

  const visibleWidgetsDefs: WidgetRendererDefinition[] = useMemo(() => {
    const layoutsMap = Object.fromEntries(
      (boardConfig.layout || []).map((el) => [el.i, el]),
    )

    return (boardConfig.widgets || [])
      .filter(
        (widget) => !!layoutsMap[widget.id] && !!widgetsRenderers[widget.type],
      )
      .map((widget) => ({
        widget,
        renderer: widgetsRenderers[widget.type],
        draggable: layoutsMap[widget.id].isDraggable !== false,
        layout: layoutsMap[widget.id],
      }))
  }, [boardConfig.layout, boardConfig.widgets, widgetsRenderers])

  const isShowOnlyOneWidget = visibleWidgetsDefs.length === 1

  const renderedWidgets = useMemo(
    () =>
      visibleWidgetsDefs.map((widgetDef) => {
        const widget = (
          <React.Suspense key={widgetDef.widget.id}>
            <WidgetWrapper
              widget={widgetDef.widget}
              renderer={widgetDef.renderer}
              boardParams={boardParams}
              draggableHandleClassName={DRAGGABLE_HANDLE_CLASS_NAME}
              draggable={isShowOnlyOneWidget ? false : widgetDef.draggable}
              collapsable={!isShowOnlyOneWidget}
              onChangeSettings={handleChangeWidgetSettings}
              onChangeLayout={handleChangeWidgetLayout}
              layout={widgetDef.layout}
              gridRowHeightPixels={WIDGETS_GRID_DEFAULT_ROW_HEIGHT_IN_PIXELS}
              gridColumnsNumber={WIDGETS_GRID_DEFAULT_COLUMNS_NUMBER}
              handleCollapse={handleCollapse}
              widgetContextMenuBuilder={widgetContextMenuBuilder}
            />
          </React.Suspense>
        )

        if (isShowOnlyOneWidget) {
          return widget
        }

        return <div key={widgetDef.widget.id}>{widget}</div>
      }),
    [
      visibleWidgetsDefs,
      boardParams,
      isShowOnlyOneWidget,
      handleChangeWidgetSettings,
      handleChangeWidgetLayout,
      handleCollapse,
      widgetContextMenuBuilder,
    ],
  )

  const marginSizePixelsX = 16
  const marginSizePixelsY = 16

  const gridLayoutMargins: [number, number] = useMemo(
    () => [marginSizePixelsX, marginSizePixelsX], // yes, [40, 40]
    [marginSizePixelsX],
  )

  const isDesignMode = !!widgetContextMenuBuilder

  if (isShowOnlyOneWidget) {
    return (
      <StyledWidgetsAreaSingleRoot
        className={isDesignMode ? 'design-mode' : ''}
      >
        {renderedWidgets}
      </StyledWidgetsAreaSingleRoot>
    )
  }

  return (
    <StyledWidgetsAreaRoot className={isDesignMode ? 'design-mode' : ''}>
      <StyledWidgetsAreaWrapper
        sx={{
          mx: `-${marginSizePixelsX}px`,
          my: `-${marginSizePixelsY}px`,
        }}
      >
        <StyledWidgetsAreaContainer
          ref={rglWrapperDomNodeRef}
          sx={{
            position: 'relative',
            ...(isEnabledWidgetTransition
              ? {}
              : {
                  '.react-grid-item': {
                    transition: 'none',
                  },
                }),
          }}
        >
          <ResponsiveGridLayout
            layout={boardConfig.layout}
            draggableHandle={`.${DRAGGABLE_HANDLE_CLASS_NAME}`}
            resizeHandle={<WidgetResizeHandler />}
            margin={gridLayoutMargins}
            rowHeight={WIDGETS_GRID_DEFAULT_ROW_HEIGHT_IN_PIXELS}
            cols={WIDGETS_GRID_DEFAULT_COLUMNS_NUMBER}
            onResizeStart={handleDisableUserSelection}
            onResizeStop={handleEnableUserSelection}
            onDragStart={handleDisableUserSelection}
            onDragStop={handleEnableUserSelection}
            onLayoutChange={handleChangeLayout}
            measureBeforeMount={true}
            autoSize={true}
          >
            {renderedWidgets}
          </ResponsiveGridLayout>
        </StyledWidgetsAreaContainer>
      </StyledWidgetsAreaWrapper>
    </StyledWidgetsAreaRoot>
  )
}
