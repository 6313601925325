import React, { ChangeEvent, useCallback, useMemo } from 'react'
import {
  FilterByString,
  FilterListAPIOperatorContains,
  filterUtils,
  FilterValue,
} from '@fintastic/web/util/filters'
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'

export const TextFilterInput: React.FC<TextFilterInputProps> = ({
  filter,
  filterValue,
  onChange,
  disableClear,
}) => {
  const handleChangeOperator = useCallback(
    (e: SelectChangeEvent<StringFilterOperator>) => {
      onChange({
        operator: e.target.value as StringFilterOperator,
        value: filterValue.value ?? '',
      })
    },
    [filterValue, onChange],
  )

  const handleValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange({
        operator: filterValue.operator ?? 'contains',
        value: e.target.value ?? '',
      })
    },
    [filterValue, onChange],
  )

  const handleClearFilter = useCallback(() => {
    onChange({
      operator: 'contains',
      value: '',
    })
  }, [onChange])

  const isEmpty = useMemo<boolean>(
    () => filterUtils.isFilterValueEmpty(filter, filterValue),
    [filter, filterValue],
  )

  const handleTryToSubmit = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >((e) => {
    if (e.key !== 'Enter') {
      return
    }

    e.currentTarget.closest('form')?.requestSubmit?.()
  }, [])

  return (
    <Box>
      <Box py={1}>
        <Box mb={1}>
          <Select<StringFilterOperator>
            onChange={handleChangeOperator}
            value={(filterValue.operator as StringFilterOperator) ?? 'contains'}
            size="small"
            fullWidth
          >
            {stringOperatorsEntries.map(([k, label]) => (
              <MenuItem key={k} value={k} dense>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField
          placeholder="Value"
          fullWidth
          size="small"
          autoComplete="off"
          value={filterValue.value ?? ''}
          onChange={handleValueChange}
          onKeyPress={handleTryToSubmit}
        />
      </Box>
      {disableClear ? null : (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            disabled={isEmpty}
            onClick={handleClearFilter}
          >
            Clear
          </Button>
        </Box>
      )}
    </Box>
  )
}

export type TextFilterInputProps = {
  filter: FilterByString
  filterValue: Partial<FilterValue<string>>
  onChange: (nextValue: FilterValue<string>) => void
  disableClear?: boolean
}

const stringOperators: Record<
  FilterListAPIOperatorContains['operator'],
  string
> = {
  contains: 'Contains',
}

type StringFilterOperator = keyof typeof stringOperators

const stringOperatorsEntries = Object.entries(stringOperators)
